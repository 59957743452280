import React, { useContext, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { getDisplayProfile } from '../../../utilities/Profiles';

// Styles
import './Comments.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Comment from './comment/Comment';

// Managers
import DataManager from '../../../managers/DataManager';
import CommentsAdd from './add/CommentsAdd';
import { EngagementType } from '../../../utilities/Enums';

const dataManager = new DataManager();

const Comments = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        commentLikes,
        commentDislikes,
        comments,
        currentItem,
        profile,
    } = useContext(Global);

    // References
    const inputRef = useRef(null);

    const [newComment, setNewComment] = useState('');
    const [addButtonsVisible, setAddButtonsVisible] = useState(false);
    const [showReplies, setShowReplies] = useState({});
    const [replyingTo, setReplyingTo] = useState(null);
    const [expandedReplies, setExpandedReplies] = useState({});

    const handleAdd = async (event, parentComment = null) => {
        event.stopPropagation();
        if (newComment.trim() === "") return;

        const key = generateKey();
        const now = Timestamp.now();

        const originalKey = parentComment ? parentComment.originalKey || parentComment.key : key;

        const data = {
            key,
            profileKey: profile.key,
            profile: getDisplayProfile(profile),
            itemKey: currentItem.key,
            itemSummary: currentItem,
            parentKey: parentComment?.key || null,
            originalKey,
            targetKey: currentItem.profileKey, // Creator of the post
            text: newComment,
            type: EngagementType.POST_COMMENT,
            dateCreated: now
        };

        await dataManager.add(collections.engagements, profile.key, key, data);

        // If the comment is a reply,  add another engagement for the creator of the parent comment
        
        if (parentComment) {
            const parentKey = generateKey();
            const parentData = {
                ...data,
                key: parentKey,
                targetKey: parentComment.profileKey, // Creator of the parent comment
                type: EngagementType.COMMENT_REPLY,
            }
            await dataManager.add(collections.engagements, profile.key, parentKey, parentData);
        }
        

        setNewComment('');
        setAddButtonsVisible(false);
        setReplyingTo(null);
    };

    const handleReplyClicked = (event, comment) => {
        event.stopPropagation();
        setReplyingTo(comment);
        setAddButtonsVisible(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleLikeClicked = async (comment) => {
        const liked = commentLikes.some(
            commentLike =>
                commentLike.commentKey === comment.key &&
                commentLike.profileKey === profile.key
        );

        if (liked) {
            const likeKey = commentLikes.find(
                commentLike =>
                    commentLike.commentKey === comment.key &&
                    commentLike.profileKey === profile.key
            ).key;

            await dataManager.delete(
                collections.engagements,
                profile.key,
                likeKey
            );
        } else {
            const key = generateKey();

            const now = Timestamp.now();

            const data = {
                key: key,
                profileKey: profile.key,
                profile: getDisplayProfile(profile),
                type: EngagementType.COMMENT_LIKE,
                itemKey: currentItem.key,
                commentKey: comment.key,
                targetKey: currentItem.profileKey,
                dateCreated: now
            };
            await dataManager.add(collections.engagements, profile.key, key, data);
        }
    };

    const handleDislikeClicked = async (comment) => {
        const disliked = commentDislikes.some(
            commentDislike =>
                commentDislike.commentKey === comment.key &&
                commentDislike.profileKey === profile.key
        );

        if (disliked) {
            const dislikeKey = commentDislikes.find(
                commentDislike =>
                    commentDislike.commentKey === comment.key &&
                    commentDislike.profileKey === profile.key
            ).key;

            await dataManager.delete(
                collections.engagements,
                profile.key,
                dislikeKey
            );
        } else {
            const key = generateKey();

            const now = Timestamp.now();

            const data = {
                key: key,
                profileKey: profile.key,
                profile: getDisplayProfile(profile),
                type: EngagementType.COMMENT_DISLIKE,
                itemKey: currentItem.key,
                targetKey: currentItem.profileKey, // Creator of the post
                commentKey: comment.key,
                dateCreated: now
            };
            await dataManager.add(collections.engagements, profile.key, key, data);
        }
    };

    const handleToggleReplies = (commentKey) => {
        setShowReplies(prev => ({ ...prev, [commentKey]: !prev[commentKey] }));
    };

    const handleToggleMoreReplies = (commentKey) => {
        setExpandedReplies(prev => ({ ...prev, [commentKey]: !prev[commentKey] }));
    };

    const getRepliesCount = (originalKey) => {
        return comments.filter(c => c.originalKey === originalKey && c.parentKey !== null).length;
    };

    const renderComments = () => {
        const topLevelComments = comments.filter(comment => comment.parentKey === null);

        const scoredComments = topLevelComments.map(comment => {
            const likesCount = commentLikes.filter(like => like.commentKey === comment.key).length;
            const repliesCount = getRepliesCount(comment.key);
            const engagementScore = likesCount + (repliesCount * 0.5);

            const now = new Date();
            const commentTime = comment.dateCreated.toDate();
            const hoursSincePosted = (now - commentTime) / (1000 * 60 * 60);
            const recencyScore = Math.max(0, 24 - hoursSincePosted) / 24;

            const creatorBoost = (comment.profileKey === currentItem.profileKey) ? 0.2 : 0;

            const totalScore = (0.6 * engagementScore) + (0.3 * recencyScore) + creatorBoost;

            return { comment, score: totalScore };
        });

        const sortedComments = scoredComments.sort((a, b) => b.score - a.score);

        return sortedComments.map(({ comment }) => {
            const repliesCount = getRepliesCount(comment.key);
            const replies = comments
                .filter(c => c.originalKey === comment.key && c.parentKey !== null)
                .sort((a, b) => a.dateCreated.toDate() - b.dateCreated.toDate());
            const displayedReplies = expandedReplies[comment.key] ? replies : replies.slice(0, 3);
            const hasMoreReplies = replies.length > displayedReplies.length;

            return (
                <Comment
                    key={comment.key}
                    comment={comment}
                    comments={comments}
                    replies={replies}
                    repliesCount={repliesCount}
                    hasMoreReplies={hasMoreReplies}
                    showReplies={showReplies}
                    displayedReplies={displayedReplies}
                    onToggleReplies={handleToggleReplies}
                    onToggleMoreReplies={handleToggleMoreReplies}
                    onReplyClicked={handleReplyClicked}
                    onLikeClicked={handleLikeClicked}
                    onDislikeClicked={handleDislikeClicked}
                />
            );
        });
    };

    return (
        <div className="comments-container">
            <div className="comments-list">
                {renderComments()}
            </div>
            <div
                className="comments-add-wrapper"
                style={{ 
                    backgroundColor: theme.backgroundColor 
                }}
            >
                <CommentsAdd
                    newComment={newComment}
                    replyingTo={replyingTo}
                    addButtonsVisible={addButtonsVisible}
                    onChange={setNewComment}
                    onFocus={() => setAddButtonsVisible(true)}
                    onAddClick={(e) => handleAdd(e, replyingTo)}
                    inputRef={inputRef}
                />
            </div>
        </div>
    );
};

export default Comments;