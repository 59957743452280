import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ItemViewer.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CommentsPopup from '../../../common/sections/home/comments/popup/CommentsPopup';
import Modal from '../../components/modal/Modal';
import PostFeedItem from '../../../common/items/post/views/feed/PostFeedItem';
import ItemButtons from '../../../common/sections/home/item/buttons/ItemButtons';

/**
 * ItemViewer Component
 * 
 * This component allows the user to view a single item's feed.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ItemViewer = ({ item, isOpen, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        comments,
        setCurrentItem,
    } = useContext(Global);

    // Local State
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [creatorMenuOpen, setCreatorMenuOpen] = useState(false);

    const handleCommentButtonClick = () => {
        setCurrentItem(item);
        setCommentsVisible(!commentsVisible);
    };

    const handleMenuButtonClick = () => {
        setCreatorMenuOpen(!creatorMenuOpen);
    };
    
    const handleDelete = () => {
        setOpen(false); // Close the viewer
    };

    if (!item) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title=""
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? "100%" : "520px"}
                height={isMobile ? "100%" : "90vh"}
                showHeader={false}
                transparent={true}
                padding="0px">

                <div key={item.key} className="item-viewer-container">

                    <div key={item.key} className="item-viewer-item"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                            width: isMobile ? '100%' : '460px',
                        }}>
                        <PostFeedItem
                            key={item.key}
                            item={item}
                            creatorMenuOpen={creatorMenuOpen}
                            setCreatorMenuOpen={setCreatorMenuOpen}
                            onDelete={handleDelete}
                        />
                    </div>

                    <div className="item-viewer-buttons">

                        <ItemButtons
                            item={item}
                            onCommentButtonClick={handleCommentButtonClick}
                            onMenuButtonClick={handleMenuButtonClick}
                        />

                    </div>

                </div>

                {/* COMMENTS POPUP */}
                <CommentsPopup
                    isOpen={commentsVisible}
                    comments={comments}
                    onClose={() => setCommentsVisible(false)}
                    setOpen={setCommentsVisible}
                />

            </Modal>
        </>
    );
};

export default ItemViewer;
