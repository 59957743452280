import React from 'react';

// Styles
import './CommentBottomToggle.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

/**
 * CommentBottomToggle component
 * 
 * Renders a comment replies toggle button for a comment.
 * 
 */
const CommentBottomToggle = ({ 
    comment,
    hasMoreReplies,
    showReplies,
    replies,
    displayedReplies,
    onExpand, 
    onHide 
}) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="comment-bottom-toggle-container">

            {/* BAR */}
            {(hasMoreReplies || showReplies[comment.key]) && (
                <div className="comment-bottom-toggle-bar"
                    style={{ backgroundColor: theme.foregroundColorFaded }}
                ></div>
            )}

            {/* EXPAND */}
            {hasMoreReplies && (
                <div className="comment-bottom-toggle-replies"
                    style={{ color: theme.foregroundColorFaded, cursor: 'pointer' }}
                    onClick={onExpand}>
                    View {replies.length - displayedReplies.length} more
                </div>
            )}

            {/* HIDE */}
            {showReplies[comment.key] && (
                <div
                    className="comment-bottom-toggle-replies"
                    style={{ color: theme.foregroundColorFaded, cursor: 'pointer', marginLeft: hasMoreReplies ? '10px' : '0' }}
                    onClick={onHide}>
                    Hide
                </div>
            )}
        </div>

    );
};

export default CommentBottomToggle;
