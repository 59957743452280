import React from 'react';

const ReplyArrowIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M360-331.46v-297.08q0-14.69 9.74-23.5 9.75-8.81 22.74-8.81 4.06 0 8.5 1.12 4.44 1.11 8.48 3.34l233.69 149.31q7.47 5.23 11.2 11.93 3.73 6.69 3.73 15.15t-3.73 15.15q-3.73 6.7-11.2 11.93L409.46-303.61q-4.06 2.23-8.53 3.34-4.47 1.12-8.54 1.12-13.01 0-22.7-8.81t-9.69-23.5Z" />
  </svg>

);

export default ReplyArrowIcon;
