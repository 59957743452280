import React from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { hexToRgba } from '../../../../../common/utilities/Colors';

// Styles
import './ItemButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * ItemButton
 * 
 * @returns {JSX.Element} ItemButtons component.
 */
const ItemButton = ({ icon: IconComponent, label, color, size = 36, enabled = true, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="item-button-container"
            style={{
                opacity: enabled ? 1 : 0.2
            }}
            onClick={enabled ? onClick : null}>

            <div className="item-button-circle"
                style={{
                    backgroundColor: hexToRgba(isMobile ? "#000000" : theme.backgroundColorFaded, 0.5)
                }}>

                <IconComponent
                    color={color ? color : theme.foregroundColor}
                    width={size}
                    height={size}
                />

            </div>

            {label &&
                <div className="item-button-count"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {label}
                </div>
            }

        </div>

    );
};

export default ItemButton;
