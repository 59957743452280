import React from 'react';

// Styles
import './Comment.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import CommentBottomToggle from './bottomtoggle/CommentBottomToggle';
import CommentFooter from './footer/CommentFooter';
import CommentTopToggle from './toptoggle/CommentTopToggle';
import ProfileDot from '../../../../../common/components/profiledot/ProfileDot';
import CommentReply from './reply/CommentReply';

/**
 * Comment component
 * 
 * Renders a comment.
 * 
 */
const Comment = ({
    comment,
    comments,
    replies,
    repliesCount,
    hasMoreReplies,
    showReplies,
    displayedReplies,
    onToggleReplies,
    onToggleMoreReplies,
    onReplyClicked,
    onLikeClicked,
    onDislikeClicked
}) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div key={comment.key} className="comment-container">

            {/* PROFILE DOT */}
            <div className="comment-profile-dot-wrapper">
                <ProfileDot item={comment.profile} size={30} />
            </div>

            <div className="comment-right">

                {/* COMMENT HEADER */}
                <div className="comment-name" style={{ color: theme.foregroundColorFaded }}>
                    {comment.profile.username}
                </div>

                {/* COMMENT */}
                <div className="comment-text" style={{ color: theme.foregroundColor }}>
                    {comment.text}
                </div>

                {/* COMMENT FOOTER */}
                <div className="comment-footer">
                    <CommentFooter
                        comment={comment}
                        onReplyClicked={onReplyClicked}
                        onLikeClicked={onLikeClicked}
                        onDislikeClicked={onDislikeClicked}
                    />
                </div>

                {/* TOP-LEVEL TOGGLE */}
                {repliesCount > 0 && !showReplies[comment.key] && (
                    <CommentTopToggle
                        repliesCount={repliesCount}
                        onClick={() => onToggleReplies(comment.key)}
                    />
                )}

                {showReplies[comment.key] && (

                    <div className="comment-replies">

                        {displayedReplies.map(reply => (

                            <CommentReply
                                key={reply.key}
                                reply={reply}
                                comment={comment}
                                comments={comments}
                                onReplyClicked={onReplyClicked}
                                onLikeClicked={onLikeClicked}
                                onDislikeClicked={onDislikeClicked}
                            />

                        ))}

                        {/* REPLIES TOGGLE */}
                        <CommentBottomToggle
                            comment={comment}
                            hasMoreReplies={hasMoreReplies}
                            showReplies={showReplies}
                            replies={replies}
                            displayedReplies={displayedReplies}
                            onExpand={() => onToggleMoreReplies(comment.key)}
                            onHide={() => onToggleReplies(comment.key)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comment;
