import React from 'react';

// Framer Motion
import { AnimatePresence } from 'framer-motion';

// Styles
import './Wizard.css';

const Wizard = ({ step, direction, children }) => {
  return (
    <div className="wizard-container">
      <AnimatePresence initial={false} custom={direction}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { currentStep: step, direction })
        )}
      </AnimatePresence>
    </div>
  );
};

export default Wizard;