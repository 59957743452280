import React, { useEffect, useState } from 'react';

// Styles
import './ProfileDot.css';

// Themes
import { useTheme } from '../../../ThemeContext';

/**
 * ProfileDot Component
 * 
 * This component renders an icon or letter representing a profile.
 * 
 * @param {object} item - The profile to represent.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileDot = ({ item, size = 40, onClick }) => {

    // Theme
    const { 
        theme 
    } = useTheme();

    // Local State
    const [font, setFont] = useState('');

    // Sets the font family for the profile letter.
    useEffect(() => {
        if (item.fontFamily) {
            setFont(item.fontFamily);
        }
    }, [item, setFont]);

    return (
        <>
            {/* CIRCLE */}
            <div className="profile-dot-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                    height: `${size}px`,
                    width: `${size}px`,
                }}
                onClick={onClick && onClick}>

                {/* PROFILE PHOTO */}
                {item.photo ? (
                    <img
                        className="profile-dot-image"
                        src={item.photo}
                        style={{
                            width: `${size}px`
                        }}
                        alt={item.username}
                    />
                ) : (
                    <div className="profile-dot-letters"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                            color: theme.foregroundColor,
                            '--dot-size': size + 'px',
                            '--dot-font-size': size * 0.4 + 'px',  // 40% of size
                            height: size + 'px',
                            width: size + 'px',
                            fontFamily: font || 'sans-serif'
                        }}>
                        {item.username.charAt(0)}
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfileDot;
