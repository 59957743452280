import React, { useEffect, useState } from 'react';

// Utilities
import { hexToRgba } from '../../../../../../common/utilities/Colors';
import { ThemeMode } from '../../../../../../common/utilities/Enums';

// Styles
import './ContributeButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import PlusIcon from '../../../../../../common/svg/PlusIcon';
import UsersIcon from '../../../../../../common/svg/UsersIcon';

const ContributeButton = ({ onClick }) => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Local State
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Sliding Words
  const words = ['Contribute Photos', 'Upload Video', 'Livestream', 'Share Notes'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change every 2 seconds
    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <div
      className="contribute-button-container"
      onClick={onClick}
      style={{
        backgroundColor: hexToRgba(
          currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF',
          0.4
        ),
      }}
    >
      <div
        className="contribute-button-top"
        style={{
          color: 'gray',
        }}
      >
        <UsersIcon 
          color={theme.highlightBackgroundColor}
          width={13} 
          height={13} 
          />
        Crowdsource Ready
      </div>
      <div
        className="contribute-button-bottom"
        style={{
          color: 'white',
        }}
      >
        {/**
        <PlusIcon
          color={theme.highlightBackgroundColor}
          width={9}
          height={9}
        />
         */}
        <span
          className="sliding-word"
          key={currentWordIndex} // This forces the span to remount, restarting the animation
        >
          {words[currentWordIndex]}
        </span>
      </div>
    </div>
  );
};

export default ContributeButton;