import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppDelete.css';

// Components
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalMessage from '../../components/modal/message/ModalMessage';

/**
 * AppDelete Component
 * 
 * This component confirms a delete prior to deleting an app.
 * 
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppDelete = ({ isOpen, setOpen }) => {

    // Global
    const {
        profile,
    } = useContext(Global);

    /** 
     * Deletes the app.
     */
    const handleDelete = async () => {


    };

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Profile"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={400}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="Your profile and all of its data will be permanently deleted. Are you sure you would like to continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Yes, I'm sure"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default AppDelete;
