import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';

// Components
import Login from '../common/login/Login.js';

// Custom Hooks
import useConfirmLoginStatus from './hooks/ConfirmLoginStatus.js';

import usePreloadProfileItems from './hooks/PreloadProfileItems.js';
import usePreloadProfilePins from './hooks/PreloadProfilePins.js';
import usePreloadProfileFollowing from './hooks/PreloadProfileFollowing.js';
import usePreloadProfileFollowers from './hooks/PreloadProfileFollowers.js';

import usePreloadProfileModels from './hooks/PreloadProfileModels.js';
import usePreloadProfileFields from './hooks/PreloadProfileFields.js';
import usePreloadProfileEngagements from './hooks/PreloadProfileEngagements.js';
import usePreloadIncomingEngagements from './hooks/PreloadIncomingEngagements.js';

import useLoadCityState from './hooks/LoadCityState';

// Styles
import './Root.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Desktop from '../desktop/Desktop';
import Mobile from '../mobile/Mobile';
import Progress from '../common/components/progress/Progress';

// Dynamic Styles
import { RootStyles } from './RootStyles';

const Root = () => {

  // Theme
  const {theme} = useTheme();

  // Global
  const {
    loginOpen,
    setLoginOpen,
    profile,
  } = useContext(Global);

  // HOOKS

  /**
   * useConfirmLoginStatus
   * 
   * Detects the user's login status and sets the profile state variable
   * accordingly. If the user is logged in, the profile is fetched from
   * the database and set. If the user is not logged in, the profile is
   * set to null.
   */
  useConfirmLoginStatus();

  /**
   * These hooks preload the folders and files for the current profile.
   */
  usePreloadProfileModels();
  usePreloadProfileFields();

  usePreloadProfileItems();
  usePreloadProfilePins();
  usePreloadProfileFollowing();
  usePreloadProfileFollowers();

  usePreloadProfileEngagements();
  usePreloadIncomingEngagements();

  useLoadCityState();

  return (
    <>

      <RootStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        placeholderColor={theme.foregroundColorFaded}
        scrollbarColor={theme.foregroundColorFaded}
      />
      
      {/* LOGIN/SIGNUP */}
      <Login
        isOpen={loginOpen}
        setOpen={setLoginOpen}
      />

      {/* RUN */}
      {isMobile ? (
        <>
          <Mobile />
        </>
      ) : (
        <>
          <Desktop />
        </>
      )}


      {/* PROGRESS PANEL */}
      <Progress />

    </>
  );
};

export default Root;

