import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { HomeToolbarKey, ItemSize } from '../common/utilities/Enums';

// Styles
import './Public.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Item from '../common/items/Item';
import PublicToolbar from './toolbar/PublicToolbar';
import Section from '../common/components/section/Section';

// Preload Hooks
import usePreloadPublicItems from './hooks/PreloadPublicItems.js';
import { isMobile } from 'react-device-detect';

const Public = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    publicItems,
    selectedHomeToolbarKey,
    setPublicItems,
  } = useContext(Global);

  // Local State
  const [displayItems, setDisplayItems] = useState([]);

  // Hooks
  usePreloadPublicItems(setPublicItems);

  // Hook to separate out past items from future items
  useEffect(() => {
    const now = new Date();
  
    switch (selectedHomeToolbarKey) {
      case HomeToolbarKey.UPCOMING:
        // First get only event items, then filter by date
        const upcomingItems = publicItems
          .filter(item => item.type === "EVENT") // Only events
          .filter(item => {
            const endDate = item.endDate.toDate();
            return endDate >= now;
          });
        setDisplayItems(upcomingItems);
        break;
  
      case HomeToolbarKey.RECENT:
        // First get only event items, then filter by date
        const recentItems = publicItems
          .filter(item => item.type === "EVENT") // Only events
          .filter(item => {
            const endDate = item.endDate.toDate();
            return endDate < now;
          });
        setDisplayItems(recentItems);
        break;
  
      case HomeToolbarKey.FORYOU:
        // Show upcoming events and non-event items
        const forYouItems = publicItems.filter(item => {
          if (item.type !== "EVENT") {
            return true; // Include all non-event items
          }
          // For events, only include upcoming ones
          const endDate = item.endDate.toDate();
          return endDate >= now;
        });
        setDisplayItems(forYouItems);
        break;
  
      default:
        // Fallback to FORYOU logic
        const defaultItems = publicItems.filter(item => {
          if (item.type !== "EVENT") {
            return true;
          }
          const endDate = item.endDate.toDate();
          return endDate >= now;
        });
        setDisplayItems(defaultItems);
    }
  }, [publicItems, selectedHomeToolbarKey]);

  return (

    <Section>

      {/* CONTAINER */}
      <div className="public-container">

        <div className="public-items">

          {/* TOOLBAR */}
          <PublicToolbar />

          {/* SCROLLING CONTENT */}
          <div className="public-scroll-container">

            {displayItems.map((item) => (

              <div key={item.key} className="public-item"
                style={{
                  width: profile ? '520px' : isMobile ? '100%' : '460px',
                }}>

                <div key={item.key} className="public-item-wrapper"
                  style={{
                    backgroundColor: theme.backgroundColorFaded,
                    width: isMobile ? '100%' : '460px'
                  }}>
                  <Item
                    key={item.key}
                    item={item}
                    size={ItemSize.LARGE}
                  />
                </div>

                <div className="public-item-buttons">

                </div>

              </div>

            ))}

          </div>

        </div>

      </div>

    </Section>

  );
};

export default Public;
