import React from 'react';

// Styles
import './InboxDialog.css';

// Components
import Modal from '../../../common/components/modal/Modal';
import NotificationList from '../../sections/inbox/list/NotificationList';

const InboxDialog = ({ isOpen, setOpen }) => {

    return (
        <Modal
            title="Inbox"
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            width="100%"
            height="100%">

            <NotificationList />

        </Modal>
    );
};

export default InboxDialog;
