import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { EngagementType } from '../../../../common/utilities/Enums';

// Styles
import './NotificationList.css';

// Components
import Divider from '../../../../common/components/divider/Divider';
import Notification from '../notification/Notification';

const NotificationList = () => {

  // Global
  const { 
    profileIncomingEngagements 
  } = useContext(Global);

  // Process engagements
  const processNotifications = () => {

    const grouped = {};
    const individualNotifications = [];

    profileIncomingEngagements.forEach((engagement, index) => {
      const { type, profile, text, itemSummary } = engagement;
      const username = profile.username;

      // Handle individual listing types
      if ([EngagementType.POST_COMMENT, EngagementType.COMMENT_REPLY].includes(type)) {
        individualNotifications.push({
          type,
          usernames: [username],  // Single username array
          count: 1,
          text,
          firstProfile: profile,
          firstItemSummary: itemSummary
        });
      } else {
        // Group other types
        if (!grouped[type]) {
          grouped[type] = {
            usernames: new Set(),
            text: text || '',
            profiles: [],
            firstItemSummary: null
          };
        }

        grouped[type].usernames.add(username);
        if (!grouped[type].profiles.some(p => p.username === username)) {
          grouped[type].profiles.push(profile);
        }
        if (!grouped[type].firstItemSummary && itemSummary) {
          grouped[type].firstItemSummary = itemSummary;
        }
      }
    });

    // Convert grouped engagements to notification objects
    const groupedNotifications = Object.entries(grouped).map(([type, data]) => ({
      type,
      usernames: Array.from(data.usernames),
      count: data.usernames.size,
      text: data.text,
      firstProfile: data.profiles[0],
      firstItemSummary: data.firstItemSummary
    }));

    // Combine grouped and individual notifications
    return [...individualNotifications, ...groupedNotifications];
  };

  const notifications = processNotifications();

  return (

    <div className="notification-list-container">

      {notifications.map((notification, index) => (

        <div key={index} className="notification-wrapper">

          {/* NOTIFICATION */}
          <Notification
            notification={notification}
          />

          {/* DIVIDER */}
          <Divider />

        </div>

      ))}

    </div>

  );
};

export default NotificationList;