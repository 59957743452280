import React from 'react';

// Styles
import './CommentsAddButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import UpArrowIcon from '../../../../../svg/UpArrowIcon';

/**
 * CommentsAddButton component
 * 
 * Renders a button for adding comments.
 * 
 * @param {Object} onClick - The function to call when the button is clicked.
 */
const CommentsAddButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="comments-add-button-container"
            onClick={onClick}
            style={{
                backgroundColor: theme.highlightBackgroundColor,
            }}>
            <UpArrowIcon
                color={theme.highlightForegroundColor}
                width="24"
                height="24"
            />
        </div>

    );
};

export default CommentsAddButton;
