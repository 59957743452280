import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global.js';

// Utilities
import { getMutualFriends } from '../../../common/utilities/Profiles.js';

// Styles
import './Friends.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Components
import FriendsToolbar from './toolbar/FriendsToolbar';
import ProfileItem from '../../../common/components/profileitem/ProfileItem.js';
import ProfileModal from '../../../common/components/profilemodal/ProfileModal.js';
import Section from '../../../common/components/section/Section';

const Friends = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileFollowing,
    profileFollowers,
  } = useContext(Global);

  // Local State
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);

  // Get mutual friends list
  const mutualFriends = getMutualFriends(profileFollowing, profileFollowers);

  const handleProfileClick = (friend) => {
    setSelectedFriend(friend);
    setProfileModalOpen(true);
  };

  return (

    <Section>

      {/* TOOLBAR */}
      <FriendsToolbar />

      <div className="friends-wrapper">

        {/* LIST */}
        <div className="friends-list">

          {mutualFriends.map(friend => (

            <div className="friends-item" key={friend.key}>

              {/* INFO */}
              <div className="friends-item-name">
                <ProfileItem
                  item={friend}
                  onClick={() => handleProfileClick(friend)}
                />
              </div>

            </div>

          ))}

          {/* NO FRIENDS MESSAGE */}
          {mutualFriends.length === 0 && (
            <div
              style={{
                color: theme.foregroundColorFaded,
                padding: '10px'
              }}>
              No friends found
            </div>
          )}

        </div>

      </div>

      {/* PROFILE */}
      <ProfileModal
        profile={selectedFriend}
        isOpen={profileModalOpen}
        setOpen={setProfileModalOpen}
      />

    </Section>

  );
};

export default Friends;
