import React from 'react';

// Styles
import './CommentsAdd.css';

// Components
import CommentsAddButton from './addbutton/CommentsAddButton';
import CommentsAddInput from './addinput/CommentsAddInput';

/**
 * CommentsAdd component
 * 
 * Renders an add panel for comments.
 * 
 */
const CommentsAdd = ({
    newComment,
    replyingTo,
    addButtonsVisible,
    onChange,
    onFocus,
    onAddClick,
    inputRef
}) => {

    return (
        <div className="comments-add-container">

            {/* ADD INPUT */}
            <CommentsAddInput
                newComment={newComment}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={replyingTo ? `Replying to ${replyingTo.profile.username}` : "Add a comment..."}
                ref={inputRef}
            />

            {/* ADD BUTTON */}
            <div className="comments-add-buttons-wrapper" style={{ display: addButtonsVisible ? 'flex' : 'none' }}>
                <CommentsAddButton 
                    onClick={onAddClick} 
                />
            </div>

        </div>
    );
};

export default CommentsAdd;
