import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../utilities/Defaults.js';
import { ThemeMode } from '../../common/utilities/Enums';

// Firebase
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Styles
import './AppSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppDelete from './appdelete/AppDelete.js';
import AppSettingsFont from './AppSettingsFont';
import AppSettingsSaveButton from './AppSettingsSaveButton.js';
import AppSettingsTheme from './AppSettingsTheme';
import AppSettingsWallpaper from './AppSettingsWallpaper';

// Managers
import { activity } from '../../common/managers/ActivityManager';
import DataManager from '../../common/managers/DataManager';

const dataManager = new DataManager();

/**
 * AppSettings Component
 * 
 * This component allows the user to edit the settings of an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettings = () => {

    // Theme
    const { defaultThemes } = useTheme();

    // Global
    const {
        profile,
        selectedSetting,
        setProfile,
    } = useContext(Global);

    // Local State

    // Delete
    const [deleteVisible, setDeleteVisible] = useState('');

    // Wallpaper
    const [selectedDarkWallpaper, setSelectedDarkWallpaper] = useState(null);
    const [selectedLightWallpaper, setSelectedLightWallpaper] = useState(null);
    const [clearedDarkWallpaper, setClearedDarkWallpaper] = useState(false);
    const [clearedLightWallpaper, setClearedLightWallpaper] = useState(false);

    // Themes
    const [themes, setThemes] = useState(defaultThemes);
    const [fontFamily, setFontFamily] = useState('');

    // Define state for required fields and displayKey
    const [requiredFields, setRequiredFields] = useState({
        fullname: false,
        displayname: false,
        handle: false,
        birthdate: false,
    });
    const [displayKey, setDisplayKey] = useState('username'); // Default to 'username'

    /** 
     * Updates the state when the selected app changes.
     */
    useEffect(() => {
        if (profile) {
            setThemes(profile.themes || defaultThemes);
            setFontFamily(profile.fontFamily || defaultFont);
            setRequiredFields(profile.requiredFields || {
                fullname: false,
                displayname: false,
                handle: false,
                birthdate: false,
            });
            setDisplayKey(profile.displayKey || 'username');
        }
    }, [profile, defaultThemes]);

    /**
     * Method to handle a theme update.
     * 
     * @param {object} updatedThemes - JSON object containing the updated themes.
     */
    const handleUpdateThemes = (updatedThemes) => {
        setThemes(updatedThemes);
    };

    /**
     * Method to handle a font family change.
     * 
     * @param {string} updatedFontFamily - Updated font family.
     */
    const handleFontChange = (updatedFontFamily) => {
        setFontFamily(updatedFontFamily);
    };

    /**
     * Method to handle saving of the app settings.
     */
    const handleSave = async () => {

        // Upload resources if selected
        if (selectedDarkWallpaper) await uploadWallpaper(ThemeMode.DARK);
        if (selectedLightWallpaper) await uploadWallpaper(ThemeMode.LIGHT);

        // Current timestamp
        const now = Timestamp.now();

        // Prepare data for update
        const data = {
            themes,
            fontFamily,
            requiredFields,
            displayKey,
            dateModified: now,
        };

        // Conditionally include wallpaper attributes
        if (clearedDarkWallpaper) data.wallpaperDark = null;
        if (clearedLightWallpaper) data.wallpaperLight = null;

        // Update the database
        const result = await dataManager.update(
            collections.profiles,
            profile.key,
            profile.key,
            data
        );

        if (result.success) {
            // Update the profile state
            setProfile((prevApp) => {
                const updatedProfile = {
                    ...prevApp,
                    ...data,
                };
                if (clearedDarkWallpaper) updatedProfile.wallpaperDark = null;
                if (clearedLightWallpaper) updatedProfile.wallpaperLight = null;
                return updatedProfile;
            });
        } else {
            alert('Failed to save app settings. Please try again.');
        }
    };

    /**
     * Method to upload a wallpaper to Firebase Storage.
     *
     * @param {string} mode - "DARK" or "LIGHT".
     */
    const uploadWallpaper = async (mode) => {
        const selectedFile = mode === ThemeMode.DARK ? selectedDarkWallpaper : selectedLightWallpaper;
        const fileExtension = selectedFile.name.split('.').pop();

        try {
            const logoFolderRef = ref(storage, `profiles/${profile.key}/wallpapers/${mode.toLowerCase()}/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(logoFolderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(profile.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `profiles/${profile.key}/wallpapers/${mode.toLowerCase()}/${profile.key}.${fileExtension}`);
            await uploadBytes(storageRef, selectedFile); // Upload the original file
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            // Update the selected app object with the new wallpaper
            const updatedProfile = {
                ...profile,
                [mode === ThemeMode.DARK ? 'wallpaperDark' : 'wallpaperLight']: fileUrl,
            };

            setProfile(updatedProfile); // Update app state

            // Update Firestore document
            await updateDoc(doc(db, collections.profiles, profile.key), updatedProfile);

            activity.log(profile.key, 'writes', 1);
        } catch (error) {
            console.error("Error handling app wallpaper upload:", error);
        }
    };

    /**
     * Method to handle a dark wallpaper selection.
     * 
     * @param {file} file - The selected file url.
     */
    const handleDarkWallpaperSelect = (file) => {
        setSelectedDarkWallpaper(file);
    };

    /**
     * Method to handle a light wallpaper selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleLightWallpaperSelect = (file) => {
        setSelectedLightWallpaper(file);
    };

    /**
     * Method to handle clearing of the dark wallpaper.
     **/
    const handleDarkClear = async () => {
        setClearedDarkWallpaper(true);
    };

    /**
     * Method to handle clearing of the dark wallpaper.
     **/
    const handleLightClear = async () => {
        setClearedLightWallpaper(true);
    };

    if (selectedSetting === null) return null;

    return (
        <>
            {/* CONTAINER */}
            <div className="app-settings-container">

                {/* CONTENT */}
                <div className="app-settings-content">

                    {/* WALLPAPER */}
                    {selectedSetting.key === 'WALLPAPER' && (
                        <AppSettingsWallpaper
                            onDarkSelect={handleDarkWallpaperSelect}
                            onLightSelect={handleLightWallpaperSelect}
                            onDarkClear={handleDarkClear}
                            onLightClear={handleLightClear}
                        />
                    )}

                    {/* THEME */}
                    {selectedSetting.key === 'THEME' && (
                        <AppSettingsTheme themes={themes} onUpdate={handleUpdateThemes} />
                    )}

                    {/* FONT */}
                    {selectedSetting.key === 'FONT' && (
                        <AppSettingsFont fontFamily={fontFamily} onChange={handleFontChange} />
                    )}
                </div>

                {/* SAVE BUTTON */}
                <div className="app-settings-save-button-wrapper"
                    style={{
                        bottom: isMobile ? '70px' : '20px',
                    }}>
                    <AppSettingsSaveButton
                        onClick={handleSave}
                    />
                </div>

            </div>

            {/* DELETE */}
            <AppDelete
                isOpen={deleteVisible}
                setOpen={setDeleteVisible}
            />

        </>
    );
};

export default AppSettings;
