import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { CalendarType } from '../../utilities/Enums';

// Styles
import './Calendar.css';

// Components
import CalendarAll from './CalendarAll';
import CalendarCollection from './views/collection/CalendarCollection';
import CalendarDay from './views/day/CalendarDay';
import CalendarHeader from './header/CalendarHeader';
import CalendarMonth from './views/month/CalendarMonth';
import CalendarSchedule from './views/schedule/CalendarSchedule';
import CalendarStoryline from './views/storyline/CalendarStoryline';
import CalendarWeek from './views/week/CalendarWeek';
import EventWizard from '../../../common/items/event/wizard/EventWizard';
import Section from '../../../common/components/section/Section';

const Calendar = () => {

    // Global
    const {
        selectedCalendarType,
    } = useContext(Global);

    const [eventOpen, setEventOpen] = useState(false);

    return (
        
        <Section>

            {/* HEADER */}
            <CalendarHeader />

            {/* SCHEDULE */}
            {selectedCalendarType === CalendarType.SCHEDULE && (
                <CalendarSchedule />
            )}

            {/* NOTELINE */}
            {selectedCalendarType === CalendarType.NOTELINE && (
                <>
                    <CalendarStoryline />
                </>
            )}

            {/* COLLECTION */}
            {selectedCalendarType === CalendarType.COLLECTION && (
                <CalendarCollection />
            )}

            {/* ALL */}
            {selectedCalendarType === CalendarType.ALL && (
                <CalendarAll />
            )}

            {/* MONTH */}
            {selectedCalendarType === CalendarType.MONTH && (
                <CalendarMonth />
            )}

            {/* WEEK */}
            {selectedCalendarType === CalendarType.WEEK && (
                <CalendarWeek />
            )}

            {/* DAY */}
            {selectedCalendarType === CalendarType.DAY && (
                <CalendarDay />
            )}

            {/* FORM */}
            <EventWizard
                isOpen={eventOpen}
                setOpen={setEventOpen}
            />

        </Section>

    );
};

export default Calendar;
