// CommentsEmojiSelector.js
import React from 'react';
import './CommentsEmojiSelector.css';
import { useTheme } from '../../../../../../../ThemeContext';

const CommentsEmojiSelector = ({ onEmojiClick }) => {
    const { theme } = useTheme();

    // Common emojis (you can expand this list)
    const emojis = [
        '😊', '😂', '❤️', '👍', '😍', '😢', '😡', '😮', '🙌', '🔥',
        '✨', '👏', '🤓', '😎', '🥳', '🎉', '🌟', '💪', '🤗', '🙏'
    ];

    return (
        <div
            className="emoji-selector-container">
            {emojis.map((emoji, index) => (
                <button
                    key={index}
                    className="emoji-button"
                    onClick={() => onEmojiClick(emoji)}
                    style={{ color: theme.foregroundColor }}
                >
                    {emoji}
                </button>
            ))}
        </div>
    );
};

export default CommentsEmojiSelector;