import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../Global.js';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { collections, storage } from '../../../../../firebaseConfig.js';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Utilities
import { hexToRgba } from '../../../../utilities/Colors.js';
import { ItemType, ThemeMode } from '../../../../utilities/Enums.js';
import { generateKey } from '../../../../utilities/Keys.js';
import { getDisplayProfile } from '../../../../utilities/Profiles.js';

// Styles
import './PostFeedItem.css';

// Theme
import { useStyle, useTheme } from '../../../../../ThemeContext.js';

// Images
import AddVideoIcon from '../../../../../common/svg/AddVideoIcon.jsx';
import AddNoteIcon from '../../../../../common/svg/AddNoteIcon.jsx';
import PencilIcon from '../../../../../common/svg/PencilIcon.jsx';
import DeleteIcon from '../../../../../common/svg/DeleteIcon.jsx';
import StreamFilledIcon from '../../../../../common/svg/StreamFilledIcon.jsx';

// Components
import ContributorBadge from './contributorbadge/ContributorBadge.js';
import Divider from '../../../../../common/components/divider/Divider.js';
import ItemMenu from '../../../../../common/items/itemmenu/ItemMenu.js';
import ItemMenuButton from '../../../../../common/items/itemmenu/itemmenubutton/ItemMenuButton.js';
import NoteFeedItem from '../../../note/views/feed/NoteFeedItem.js';
import NoteForm from '../../../../../common/note/NoteForm.js';
import PostFeedItemFooter from './footer/PostFeedItemFooter.js';
import PostStream from '../../stream/PostStream';
import PostWizard from '../../../post/wizard/PostWizard.js';
import ProfileModal from '../../../../components/profilemodal/ProfileModal.js';
import Uploader from '../../../../components/uploader/Uploader';

// Managers
import DataManager from '../../../../managers/DataManager.js';
import Confirm from '../../../../components/confirm/Confirm.js';

const dataManager = new DataManager();

// Define MIME type constants for photo and video detection
const PHOTO_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
const VIDEO_MIME_TYPES = ['video/mp4', 'video/quicktime', 'video/avi', 'video/x-ms-wmv', 'video/webm'];
const ALL_MIME_TYPES = [...PHOTO_MIME_TYPES, ...VIDEO_MIME_TYPES];

// Styled Components
const InlineStyles = useStyle`
  .swiper-pagination {
    top: ${(props) => props.dotsTop}  !important;
  }
`;

const PostFeedItem = ({ item, creatorMenuOpen, setCreatorMenuOpen, onDelete }) => {
  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    hideProgress,
    muted,
    overlayVisible,
    profile,
    profilePosts,
    setCurrentItem,
    setOverlayVisible,
    showProgress,
  } = useContext(Global);

  // Local State
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [alreadyCopied, setAlreadyCopied] = useState(false);
  const [contributionMenuOpen, setContributionMenuOpen] = useState(false);
  const [displayItems, setDisplayItems] = useState([]);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [songUrl, setSongUrl] = useState(null);
  const [songTitle, setSongTitle] = useState('');
  const [songArtist, setSongArtist] = useState('');
  const [streaming, setStreaming] = useState(false);
  const [uploadReady, setUploadReady] = useState(false);
  const [uploadFileTypes, setUploadFileTypes] = useState(ALL_MIME_TYPES.join(','));
  const [editPostOpen, setEditPostOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  // Streaming
  const [stream, setStream] = useState(null);

  // References
  const containerRef = useRef(null);
  const audioRef = useRef(null);
  const swiperRef = useRef(null);
  const videoRefs = useRef({});
  const uploaderRef = useRef(null);

  // Filter display items
  useEffect(() => {
    if (item.items) {
      const filteredItems = item.items.filter(contribution =>
        contribution.type === ItemType.STREAM ||
        contribution.type === ItemType.PHOTO ||
        contribution.type === ItemType.VIDEO ||
        contribution.type === ItemType.NOTE
      );
      setDisplayItems(filteredItems);
    }
  }, [item]);

  // Update song details when items change
  useEffect(() => {
    if (item?.items) {
      const songs = item.items.filter(i => i.type === 'SONG');
      if (songs.length > 0) {
        setSongUrl(songs[0].url);
        setSongTitle(songs[0].title);
        setSongArtist(songs[0].artist);
      } else {
        setSongUrl(null);
        setSongTitle('');
        setSongArtist('');
      }
    }
  }, [item.items]);

  // Manage audio playback based on songUrl
  useEffect(() => {
    if (!audioRef.current) return;

    if (songUrl) {
      audioRef.current.src = songUrl;
      if (!muted) {
        audioRef.current.play().catch(error => {
          console.error('Song playback failed:', error);
        });
      }
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.src = '';
    }
  }, [songUrl, muted]);

  // Intersection Observer for visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentItem(item);
            if (audioRef.current && songUrl && !swiperRef.current?.slides[swiperRef.current?.activeIndex]?.querySelector('video')) {
              audioRef.current.play().catch((error) => {
                console.error('Auto-play failed:', error);
              });
            }
          } else {
            if (audioRef.current) {
              audioRef.current.pause();
            }
            Object.values(videoRefs.current).forEach(video => {
              if (video) video.pause();
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    // Capture the current value of containerRef.current
    const currentContainer = containerRef.current;
    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      // Use the captured value in cleanup
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, [songUrl, item, setCurrentItem]);

  // Handle mute/unmute
  useEffect(() => {
    if (!audioRef.current || !songUrl) return;
    if (muted) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.error('Song playback failed:', error);
      });
    }
  }, [muted, songUrl]);

  // Check if event was copied
  useEffect(() => {
    const copied = profilePosts.find(event => event.referencePostKey === item.key);
    if (copied) {
      setAlreadyCopied(true);
    }
  }, [item, profilePosts]);

  const handleProfileClick = () => setProfileModalOpen(true);

  const handleContributeClick = async () => {
    setContributionMenuOpen(true);
  };

  const handleAddClick = async () => {
    const displayProfile = {
      key: profile.key,
      username: profile.username,
      photo: profile.photo,
      followerCount: profile.followerCount,
      followingCount: profile.followingCount,
    };
    const key = generateKey();
    const copy = {
      ...item,
      key: key,
      referencePostKey: item.key,
      profileKey: profile.key,
      profile: displayProfile,
      shared: false,
    };
    await dataManager.add(collections.items, profile.key, key, copy);
  };

  const handleRemoveClick = async () => {
    const copied = profilePosts.find(event => event.referencePostKey === item.key);
    if (copied) {
      await dataManager.delete(collections.items, profile.key, copied.key);
      setAlreadyCopied(false);
    }
  };

  const handleDetailsClick = () => {
    setOverlayVisible(!overlayVisible);
  };

  const handleSlideChange = (swiper) => {
    Object.values(videoRefs.current).forEach(video => {
      if (video) {
        video.pause();
        video.muted = true;
      }
    });

    const activeSlide = swiper.slides[swiper.activeIndex];
    if (activeSlide === undefined) return;
    const video = activeSlide.querySelector('video');

    if (video) {
      video.muted = false;
      video.play().catch(error => console.error('Video playback failed:', error));
      if (audioRef.current) audioRef.current.pause();
    } else if (audioRef.current && songUrl && !muted) {
      audioRef.current.play().catch(error => console.error('Song playback failed:', error));
    }
  };

  const handleItemTypeSelect = async (type) => {
    let useUpload = false;

    switch (type) {
      case ItemType.STREAM:
        useUpload = false;

        console.log("Adding stream...");

        // Add a stream item to the item.items array
        const streamItem = {
          key: generateKey(),
          type: ItemType.STREAM,
          profileKey: profile.key,
          profile: {
            key: profile.key,
            username: profile.username,
            photo: profile.photo,
          },
          contributorKey: profile.key,
          contributorProfile: getDisplayProfile(profile),
        }

        const data = {
          items: [...item.items, streamItem],
        };

        await dataManager.update(collections.items, profile.key, item.key, data);

        break;
      case ItemType.PHOTO:
        setUploadFileTypes(PHOTO_MIME_TYPES.join(','));
        useUpload = true;
        break;
      case ItemType.VIDEO:
        setUploadFileTypes(VIDEO_MIME_TYPES.join(','));
        useUpload = true;
        break;
      case ItemType.NOTE:
        setAddNoteOpen(true);
        useUpload = false;
        break;
      default:
        setUploadFileTypes(ALL_MIME_TYPES.join(','));
        useUpload = true;
        break;
    }

    if (useUpload && uploaderRef.current) {
      uploaderRef.current.click();
    }
  };

  const handleSelectComplete = async (localFilePaths) => {
    showProgress('Uploading...');

    // Fetch MIME types for each blob URL
    const fileTypes = await Promise.all(
      localFilePaths.map(async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return { url, type: blob.type };
      })
    );

    const newItems = localFilePaths.map((filePath) => {
      const fileType = fileTypes.find(ft => ft.url === filePath)?.type || '';
      let itemType;

      if (PHOTO_MIME_TYPES.includes(fileType)) {
        itemType = ItemType.PHOTO;
      } else if (VIDEO_MIME_TYPES.includes(fileType)) {
        itemType = ItemType.VIDEO;
      } else {
        itemType = ItemType.PHOTO; // Default to PHOTO if unknown
      }

      return {
        key: generateKey(),
        type: itemType,
        profileKey: profile.key,
        profile: {
          key: profile.key,
          username: profile.username,
          photo: profile.photo,
        },
        url: filePath,
        contributorKey: profile.key,
        contributorProfile: getDisplayProfile(profile),
      };
    });

    const updatedItems = await handleContributionUploads(newItems);

    const data = {
      items: item.items.concat(updatedItems),
    };

    await dataManager.update(collections.items, profile.key, item.key, data);

    setContributionMenuOpen(false);
    hideProgress();
  };

  const handleContributionUploads = async (newItems) => {
    for (let i = 0; i < newItems.length; i++) {
      const newItem = newItems[i];

      if (
        (newItem.type === ItemType.PHOTO || newItem.type === ItemType.VIDEO) &&
        newItem.url.startsWith('blob:')
      ) {
        try {
          const file = await fetch(newItem.url).then(res => res.blob());
          const fileExtension = file.type.split('/')[1] || 'bin'; // Use MIME type for extension
          const fileName = `file_${Date.now()}`;
          const filePath = `uploads/${fileName}.${fileExtension}`;
          const storageRef = ref(storage, filePath);

          await uploadBytes(storageRef, file);
          const fileUrl = await getDownloadURL(storageRef);

          URL.revokeObjectURL(newItem.url); // Clean up blob URL
          newItems[i].url = fileUrl;
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    }

    return newItems;
  };

  const handleNoteAdded = async (note) => {
    const data = {
      items: item.items.concat(note),
    };

    await dataManager.update(collections.items, profile.key, item.key, data);

    setContributionMenuOpen(false);
  };

  const handleEditClick = () => {
    setEditPostOpen(true);
    setCreatorMenuOpen(false);
  };

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    await dataManager.delete(collections.items, profile.key, item.key);
    onDelete();
  };

  const handleSlideClick = () => handleDetailsClick();

  const handlePrev = () => swiperRef.current?.slidePrev();
  const handleNext = () => swiperRef.current?.slideNext();

  return (
    <>
      <InlineStyles
        dotsTop={isMobile ? '60px' : '20px'}
      />
      <div
        className="post-feed-item-container"
        style={{
          backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.5),
          borderRadius: isMobile ? '0' : '17px',
        }}
        ref={containerRef}
        title={item.title}
      >
        {contributionMenuOpen && (

          <ItemMenu
            item={item}
            setOpen={setContributionMenuOpen}
          >
            <ItemMenuButton
              icon={StreamFilledIcon}
              iconColor={streaming ? "white" : theme.foregroundColor}
              iconBackgroundColor={streaming ? "red" : "green"}
              label={streaming ? 'Stop Streaming' : 'Start Streaming'}
              onClick={() => handleItemTypeSelect(ItemType.STREAM)}
            />

            <Divider
              color="#333333"
              margin="14px"
            />

            <ItemMenuButton
              icon={AddVideoIcon}
              iconColor={theme.foregroundColorFaded}
              iconBackgroundColor="transparent"
              iconSize={18}
              label="Add Photos and Videos"
              onClick={() => handleItemTypeSelect(ItemType.PHOTO)}
            />

            <Divider
              color="#333333"
              margin="14px"
            />

            <ItemMenuButton
              icon={AddNoteIcon}
              iconColor={theme.foregroundColorFaded}
              iconBackgroundColor="transparent"
              iconSize={18}
              label="Add Notes"
              onClick={() => handleItemTypeSelect(ItemType.NOTE)}
            />
          </ItemMenu>
        )}

        {creatorMenuOpen && (

          <ItemMenu
            item={item}
            setOpen={setCreatorMenuOpen}
          >
            <ItemMenuButton
              icon={StreamFilledIcon}
              iconColor={streaming ? "white" : theme.foregroundColor}
              iconBackgroundColor={streaming ? "red" : "green"}
              label={streaming ? 'Stop Streaming' : 'Start Streaming'}
              onClick={() => handleItemTypeSelect(ItemType.STREAM)}
            />

            {item && (item.profileKey === profile.key) && (
              <>
                <Divider
                  color="#333333"
                  margin="14px"
                />

                <ItemMenuButton
                  icon={PencilIcon}
                  iconColor={theme.foregroundColorFaded}
                  iconBackgroundColor="transparent"
                  iconSize={18}
                  label="Edit Post"
                  onClick={handleEditClick}
                />

                <Divider
                  color="#333333"
                  margin="14px"
                />

                <ItemMenuButton
                  icon={DeleteIcon}
                  iconColor={theme.highlightBackgroundColor}
                  iconBackgroundColor="transparent"
                  iconSize={18}
                  label="Delete"
                  labelColor={theme.highlightBackgroundColor}
                  onClick={handleDeleteClick}
                />
              </>
            )}

          </ItemMenu>
        )}

        {displayItems.length > 0 ? (
          <div className="post-feed-item-slideshow-container">
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={0}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation={false}
              touchRatio={isMobile ? 1 : 0}
              speed={50} // milliseconds
              loop={true}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSlideChange={handleSlideChange}
            >
              {displayItems.map((contribution, index) => (
                <SwiperSlide key={`${contribution.type}-${index}`}>
                  <>
                    <div className="post-feed-item-slide-wrapper"
                      onClick={handleSlideClick}>

                      {/* STREAM FEED ITEM */}
                      {contribution.type === ItemType.STREAM && (
                        <PostStream
                          streamViewerOpen={true}
                          stream={stream}
                          setStream={setStream}
                        />
                      )}

                      {/* PHOTO FEED ITEM */}
                      {contribution.type === ItemType.PHOTO && (
                        <img
                          className="post-feed-item-image"
                          src={contribution.url}
                          alt={`Slide ${index + 1}`}
                          onLoad={(e) => {
                            const img = e.target;
                            img.classList.add(img.naturalHeight > img.naturalWidth ? 'portrait' : 'landscape');
                          }}
                        />
                      )}

                      {/* VIDEO FEED ITEM */}
                      {contribution.type === ItemType.VIDEO && (
                        <video
                          ref={el => (videoRefs.current[index] = el)}
                          controls={false}
                          muted
                          loop
                          src={contribution.url}
                          className="video-gallery-summary-video"
                          style={{ width: '100%' }}
                        />
                      )}

                      {/* NOTE FEED ITEM */}
                      {contribution.type === ItemType.NOTE &&
                        <NoteFeedItem item={contribution} />
                      }

                    </div>

                    {/* CONTRIBUTOR BADGE (OVERLAY)*/}
                    {overlayVisible && contribution.contributorProfile && (
                      <ContributorBadge
                        contribution={contribution}
                      />
                    )}
                  </>
                </SwiperSlide>
              ))}
            </Swiper>
            {!isMobile && item.items.length > 1 && (
              <>
                <button className="post-feed-item-custom-arrow post-feed-item-custom-arrow-prev" onClick={handlePrev}>
                  ◀
                </button>
                <button className="post-feed-item-custom-arrow post-feed-item-custom-arrow-next" onClick={handleNext}>
                  ▶
                </button>
              </>
            )}
          </div>
        ) : (
          <div
            className="post-feed-item-no-content-added-wrapper"
            style={{ backgroundColor: theme.backgroundColorFaded }}
          >
            <div className="post-feed-item-no-content-added-text" style={{ color: theme.foregroundColorFaded }}>
              No content added
            </div>
          </div>
        )}

        {overlayVisible && (
          <PostFeedItemFooter
            item={item}
            alreadyCopied={alreadyCopied}
            handleRemoveClick={handleRemoveClick}
            songUrl={songUrl}
            songTitle={songTitle}
            songArtist={songArtist}
            onProfileClick={handleProfileClick}
            onAddToCalendar={handleAddClick}
            onContributeClick={handleContributeClick}
          />
        )}
      </div>

      {songUrl && (
        <audio ref={audioRef} controls={false} style={{ display: 'none' }}>
          <source src={songUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}

      {/* PROFILE */}
      <ProfileModal
        profile={item.profile}
        isOpen={profileModalOpen}
        setOpen={setProfileModalOpen}
      />

      {/* POST WIZARD */}
      <PostWizard
        item={item}
        isOpen={editPostOpen}
        setOpen={setEditPostOpen}
        onSave={handleEditClick}
      />

      {/* UPLOADER */}
      <Uploader
        ref={uploaderRef}
        accept={uploadFileTypes}
        selectionMode="multiple"
        folderPath="uploads"
        uploadReady={uploadReady}
        setUploadReady={setUploadReady}
        onSelectComplete={handleSelectComplete}
      />

      {/* NOTE ADD */}
      <NoteForm
        isOpen={addNoteOpen}
        setOpen={setAddNoteOpen}
        delayAdd={true}
        onAdded={handleNoteAdded}
      />

      {/* CONFIRM DELETE */}
      <Confirm
        title="Confirm Delete"
        message="Are you sure you want to delete this post? This action cannot be undone."
        isOpen={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteConfirm}
      />

    </>
  );
};

export default PostFeedItem;