import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { EngagementType } from '../../../../common/utilities/Enums';
import { getUsersPhrase, getUsersPhraseWithUsernames } from '../../../../common/utilities/Profiles.js';

// Styles
import './Notification.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import PostThumbItem from '../../../items/post/views/thumb/PostThumbItem';
import ProfileDot from '../../../components/profiledot/ProfileDot';

const Notification = ({ notification }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [prefix, setPrefix] = useState('');

    const { type, usernames, count, text } = notification;

    useEffect(() => {
        if (!profile || !notification) return;
        setPrefix(getUsersPhraseWithUsernames(usernames));
    }, [profile, notification, usernames]);

    const getNotificationText = () => {

        switch (type) {
            case EngagementType.POST_BOOKMARK:
                return `${prefix} added your post to Favorites.`;
            case EngagementType.POST_COMMENT:
                return `${prefix} commented: ${text}`;
            case EngagementType.POST_LIKE:
                return `${prefix} liked your post.`;
            case EngagementType.POST_REPOST:
                return `${prefix} reposted your post`;
            case EngagementType.COMMENT_LIKE:
                return `${prefix} liked your comment.`;
            case EngagementType.COMMENT_REPLY:
                return `${prefix} replied to your comment: ${text}`;
            case EngagementType.CONTRIBUTION_GIFT:
                return `${prefix} sent you a gift.`;
            case EngagementType.CONTRIBUTION_VIEW:
                return `${prefix} viewed your contribution.`;
            case EngagementType.PROFILE_VIEW:
                return `${prefix} viewed your profile.`;
            default:
                return '';
        }
    };

    // For individual listing types (comments and replies), we'll show each separately
    const shouldListIndividually = [
        EngagementType.POST_COMMENT,
        EngagementType.COMMENT_REPLY,
    ].includes(type);

    return (
        <div className="notification"
            style={{
                color: theme.foregroundColor,
            }}>

            {/* PROFILE DOT */}
            <div className="notification-profile-dot-wrapper">
                <ProfileDot
                    item={notification.firstProfile}
                    size={40}
                />
            </div>

            {/* TEXT */}
            {shouldListIndividually ? (
                <div className="notification-text">{getNotificationText()}</div>
            ) : (
                <div className="notification-text">
                    {getNotificationText()}
                </div>
            )}

            {(type !== EngagementType.COMMENT_REPLY) && (notification.firstItemSummary) && (
                <PostThumbItem
                    item={notification.firstItemSummary}
                />
            )}

        </div>
    );
};

export default Notification;