import React, { useEffect, useRef, useState } from 'react';

import Peer from 'simple-peer';

// Styles
import './PostStream.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const PostStream = ({ streamViewerOpen, stream, setStream }) => {

    // Theme
    const { theme } = useTheme();

    // References
    const localVideoRef = useRef(null);
    const [peerSignal, setPeerSignal] = useState('');
    const [peerConnected, setPeerConnected] = useState(false);
    const remoteVideoRef = useRef(null);
    const peerRef = useRef(null);

    useEffect(() => {
        if (streamViewerOpen) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((mediaStream) => {
                    setStream(mediaStream);
                    if (localVideoRef.current) {
                        localVideoRef.current.srcObject = mediaStream;
                    }
                })
                .catch((err) => console.error('Failed to get local stream', err));
        } else {
            // Clean up the stream and peer connection
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                setStream(null);
            }
            if (peerRef.current) {
                peerRef.current.destroy();
                peerRef.current = null;
            }
        }
    }, [streamViewerOpen]);

    return (
        <>
            {streamViewerOpen &&
                <div className="post-stream-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <video className="post-stream" ref={localVideoRef} autoPlay />
                </div>
            }

            {/* REMOTE 
            <div className="video-chat-video-wrapper">
                <video ref={remoteVideoRef} autoPlay />
            </div>
            */}
        </>
    );
};

export default PostStream;
