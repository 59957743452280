import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { HomeToolbarKey } from '../../common/utilities/Enums';

// Styles
import './PublicToolbar.css';

// Components
import PublicToolbarButton from './button/PublicToolbarButton';

const PublicToolbar = () => {

    // Global
    const {
        selectedHomeToolbarKey,
        setSelectedHomeToolbarKey
    } = useContext(Global);

    return (
        <div className="public-toolbar">

            <PublicToolbarButton key={HomeToolbarKey.FORYOU} title="For You" selected={selectedHomeToolbarKey === HomeToolbarKey.FORYOU} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.FORYOU)} />
            <PublicToolbarButton key={HomeToolbarKey.UPCOMING} title="Upcoming" selected={selectedHomeToolbarKey === HomeToolbarKey.UPCOMING} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.UPCOMING)} />
            <PublicToolbarButton key={HomeToolbarKey.RECENT} title="Recent" selected={selectedHomeToolbarKey === HomeToolbarKey.RECENT} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.RECENT)} />
            
        </div>
    );
};

export default PublicToolbar;
