import React from 'react';

const SongIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M323.08-160h-98.46q-27.62 0-46.12-18.5Q160-197 160-224.62V-480q0-66.54 25.04-124.73t68.54-101.69q43.5-43.5 101.69-68.54Q413.46-800 480-800t124.73 25.04q58.19 25.04 101.69 68.54 43.5 43.5 68.54 101.69Q800-546.54 800-480v255.38q0 27.62-18.5 46.12Q763-160 735.38-160h-98.46v-246.15H760V-480q0-117-81.5-198.5T480-760q-117 0-198.5 81.5T200-480v73.85h123.08V-160Zm-40-206.15H200v141.53q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h58.46v-166.15Zm393.84 0V-200h58.46q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-141.53h-83.08Zm-393.84 0H200h83.08Zm393.84 0H760h-83.08Z" />
  </svg>

);

export default SongIcon;
