import React from 'react';

const HomeIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M164.62-100v-435.39H100v-208.45h206.85q-8.46-9.77-11.5-22.01-3.04-12.23-3.04-25.69 0-44.87 31.41-76.28 31.41-31.41 76.28-31.41 23 0 42.62 9.65 19.61 9.66 35.07 25.81 15.46-16.77 35.08-26.11 19.61-9.35 42.61-9.35 44.88 0 76.29 31.41 31.41 31.41 31.41 76.28 0 13.31-3.35 25.31-3.35 12-11.19 22.39H860v208.45h-64.62V-100H164.62Zm390.76-739.23q-20.27 0-33.98 13.71-13.71 13.71-13.71 33.98 0 20.27 13.71 33.98 13.71 13.72 33.98 13.72 20.27 0 33.99-13.72 13.71-13.71 13.71-33.98 0-20.27-13.71-33.98-13.72-13.71-33.99-13.71Zm-203.07 47.69q0 20.27 13.71 33.98 13.71 13.72 33.98 13.72 20.27 0 33.98-13.72 13.71-13.71 13.71-33.98 0-20.27-13.71-33.98-13.71-13.71-33.98-13.71-20.27 0-33.98 13.71-13.71 13.71-13.71 33.98ZM160-683.85v88.47h290v-88.47H160ZM450-160v-375.39H224.61V-160H450Zm60 0h225.39v-375.39H510V-160Zm290-435.38v-88.47H510v88.47h290Z" />
  </svg>

);

export default HomeIcon;
