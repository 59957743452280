import React from 'react';

const MuteOnIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M765.85-92.92 645.62-213.15q-16.54 10.61-34.54 18.65t-37.23 14.27v-42q11.69-4.23 22.5-8.85 10.8-4.61 20.5-10.84L463.08-395.69v138.77L320-400H183.08v-160h115.69L103.69-755.08 132-783.39l662.15 662.16-28.3 28.31Zm-31.08-200.46-28-28q24.69-34.08 38.19-74.62 13.5-40.54 13.5-85 0-87.85-50.77-158.77-50.77-70.92-133.84-100v-42Q674-749.92 736.23-667.42q62.23 82.5 62.23 186.42 0 52.23-16.81 100.08-16.8 47.84-46.88 87.54ZM623.85-404.31l-50-50v-160q33.92 22 51.57 57.93 17.66 35.92 17.66 76.38 0 20.38-4.81 39.5t-14.42 36.19ZM463.08-565.08l-68.62-69.38 68.62-68.62v138Zm-40 211.08v-81.69L338.77-520H223.08v80h114l86 86Zm-42.16-123.85Z" />
  </svg>

);

export default MuteOnIcon;
