import React from 'react';

// Utilities
import { hexToRgba } from '../../../../common/utilities/Colors';

// Styles
import './AddMediaButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const AddMediaButton = ({ icon: IconComponent, label, onClick, enabled = true }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="event-wizard-add-media-button-container"
            style={{
                backgroundColor: hexToRgba(theme.backgroundColorFaded, 0.5),
            }}
            onClick={enabled ? onClick : null}>

            <IconComponent
                color={enabled ? theme.foregroundColorFaded : theme.backgroundColorFaded}
                width={30}
                height={30}
            />

            <div className="event-wizard-add-media-button-label"
                style={{
                    color: enabled ? theme.foregroundColor : theme.backgroundColorFaded
                }}>
                {label}
            </div>

        </div>
    );
};

export default AddMediaButton;
