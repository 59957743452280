// Utilities
export const createCustomZoomControl = (map, theme) => {
  const controlDiv = document.createElement('div');
  controlDiv.style.margin = '10px';

  const zoomInButton = document.createElement('div');
  zoomInButton.classList.add('pins-map-zoom-button');
  zoomInButton.style.backgroundColor = theme.highlightBackgroundColor;
  zoomInButton.style.borderColor = theme.backgroundColorFaded;
  zoomInButton.style.color = theme.highlightForegroundColor;
  zoomInButton.textContent = '+';

  const zoomOutButton = document.createElement('div');
  zoomOutButton.classList.add('pins-map-zoom-button');
  zoomOutButton.style.backgroundColor = theme.highlightBackgroundColor;
  zoomOutButton.style.borderColor = theme.backgroundColorFaded;
  zoomOutButton.style.color = theme.highlightForegroundColor;
  zoomOutButton.textContent = '-';

  controlDiv.appendChild(zoomInButton);
  controlDiv.appendChild(zoomOutButton);

  zoomInButton.addEventListener('click', () => map.setZoom(map.getZoom() + 1));
  zoomOutButton.addEventListener('click', () => map.setZoom(map.getZoom() - 1));

  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
};

export const getHomePinSvgString = (color, width, height) => {
  return `<svg
      height="${height}"
      viewBox="0 -960 960 960"
      width="${width}"
      xmlns="http://www.w3.org/2000/svg">
      <path fill="${color}" d="M378.46-453.85h67.69v-94.61h67.7v94.61h67.69v-162.3L480-683.85l-101.54 67.7v162.3ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Z"/>
    </svg>`;
};

export const getExplorePinSvgString = (color, width, height) => {
  return `<svg
      height="${height}"
      viewBox="0 -960 960 960"
      width="${width}"
      xmlns="http://www.w3.org/2000/svg">
      <path fill="${color}" d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.60Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.60 19.02 18.88 45.73 18.88ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q13.08 0 25 .96t24.23 3.66v146.15h120v120h113.92q.47 4.83.58 10.32.12 5.49.12 10.76 0 88.46-71.43 196.34Q621-243.92 480-119.23ZM720-640v-120H600v-40h120v-120h40v120h120v40H760v120h-40Z"/>
    </svg>`;
};
export const clearPinMarkers = (pinMarkersRef) => {
  pinMarkersRef.current.forEach(({ marker, circle }) => {
    marker.setMap(null);
    circle.setMap(null);
  });
  pinMarkersRef.current = [];
};

export const placeMarkerAndCircle = (pin, location, googleMapRef, theme, setSelectedPin) => {
  const svgString = pin.isHome
    ? getHomePinSvgString(theme.highlightBackgroundColor, 40, 40)
    : getExplorePinSvgString(theme.highlightBackgroundColor, 40, 40);

  const marker = new window.google.maps.Marker({
    position: location,
    map: googleMapRef.current,
    title: pin.title,
    icon: {
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString),
      scaledSize: new window.google.maps.Size(30, 40),
    },
  });

  const circle = new window.google.maps.Circle({
    strokeColor: theme.highlightBackgroundColor,
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: theme.highlightBackgroundColor,
    fillOpacity: 0.15,
    map: googleMapRef.current,
    center: location,
    radius: pin.radius * 1609.34,
  });

  // Add click event listener to the circle
  window.google.maps.event.addListener(circle, 'click', () => {
    setSelectedPin(pin);
  });

  return { marker, circle };
};

export const reverseGeocode = (latLng) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          let city = '';
          let state = '';
          let country = '';

          results[0].address_components.forEach((component) => {
            if (component.types.includes('locality')) city = component.long_name;
            else if (component.types.includes('administrative_area_level_1')) state = component.short_name;
            else if (component.types.includes('country')) country = component.long_name;
          });

          resolve({ city, state, country });
        } else {
          reject(new Error('Geocoding failed: ' + status));
        }
      });
    });
  };

/*
export const setDefaultMarker = (googleMapRef, markerRef, circleRef, profileRadius, theme) => {
  const defaultLatLng = new window.google.maps.LatLng(39.8283, -98.5795); // Fallback

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        const { marker, circle } = placeMarkerAndCircle(
          userLocation,
          googleMapRef,
          profileRadius,
          PinType.HOME,
          theme,
          'Home'
        );
        markerRef.current = marker;
        circleRef.current = circle;
        googleMapRef.current.setCenter(userLocation);
        googleMapRef.current.fitBounds(circleRef.current.getBounds());
      },
      () => {
        console.warn('Geolocation permission denied or unavailable, using default location.');
        const { marker, circle } = placeMarkerAndCircle(
          defaultLatLng,
          googleMapRef,
          profileRadius,
          PinType.HOME,
          theme,
          'Home'
        );
        markerRef.current = marker;
        circleRef.current = circle;
        googleMapRef.current.setCenter(defaultLatLng);
      }
    );
  } else {
    console.warn('Geolocation not supported, using default location.');
    const { marker, circle } = placeMarkerAndCircle(
      defaultLatLng,
      googleMapRef,
      profileRadius,
      PinType.HOME,
      theme,
      'Home'
    );
    markerRef.current = marker;
    circleRef.current = circle;
    googleMapRef.current.setCenter(defaultLatLng);
  }
};
*/