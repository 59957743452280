import React from 'react';

// Styles
import './CommentReply.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import ReplyArrowIcon from '../../../../../../common/svg/ReplyArrowIcon';

// Components
import CommentFooter from '../footer/CommentFooter';
import ProfileDot from '../../../../../../common/components/profiledot/ProfileDot';

/**
 * CommentReply component
 * 
 * Renders a reply to a comment or reply.
 * 
 */
const CommentReply = ({
    reply,
    comment,
    comments,
    onReplyClicked,
    onLikeClicked,
    onDislikeClicked
}) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div key={reply.key} className="comment-reply-container">

            {/* PROFILE DOT */}
            <ProfileDot item={reply.profile} size={24} />

            <div className="comment-reply-right">

                {/* REPLY HEADER */}
                <div className="comment-reply-header" style={{ color: theme.foregroundColorFaded }}>
                    {reply.parentKey === comment.key ? (
                        reply.profile.username
                    ) : (
                        <>
                            {reply.profile.username}
                            <ReplyArrowIcon
                                color={theme.foregroundColorFaded}
                                width="16"
                                height="16"
                            />
                            {comments.find(c => c.key === reply.parentKey)?.profile.username || ''}
                        </>
                    )}
                </div>

                {/* REPLY */}
                <div className="comment-text" style={{ color: theme.foregroundColor }}>
                    {reply.text}
                </div>

                {/* REPLY FOOTER */}
                <div className="comment-footer">
                    <CommentFooter
                        comment={reply}
                        onReplyClicked={onReplyClicked}
                        onLikeClicked={onLikeClicked}
                        onDislikeClicked={onDislikeClicked}
                    />
                </div>

            </div>

        </div>
    );
};

export default CommentReply;
