import React from 'react';

const PlayIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M360-272.31v-415.38L686.15-480 360-272.31Z" />
  </svg>

);

export default PlayIcon;
