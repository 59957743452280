import React, { useState } from 'react';

// Styles
import './PostSuccessful.css';

// Components
import ItemViewer from '../../../common/items/itemviewer/ItemViewer.js';
import Modal from '../modal/Modal';
import ModalButton from '../modal/buttons/ModalButton';
import ModalButtons from '../modal/buttons/ModalButtons';
import ModalMessage from '../modal/message/ModalMessage';

const PostSuccessful = ({ item, isOpen, setOpen }) => {

  const [itemViewerOpen, setItemViewerOpen] = useState(false);

  const message = "Your post was successfully created!";

  const handleViewPost = () => {
    setOpen(false);
    setItemViewerOpen(true);
  }

  return (
    <>
      {/* MODAL */}
      <Modal title="Post Created"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        height={"auto"}
        width={400}>

        {/* CONFIRM MESSAGE */}
        <ModalMessage text={message} />

        {/* BUTTONS */}
        <ModalButtons>

          {/* CANCEL BUTTON */}
          <ModalButton
            onClick={handleViewPost}
            label="View Post"
            highlighted={true}
          />

        </ModalButtons>

      </Modal>

      {/* ITEM VIEWER */}
      <ItemViewer
        item={item}
        isOpen={itemViewerOpen}
        setOpen={setItemViewerOpen}
      />
    </>
  );
};

export default PostSuccessful;
