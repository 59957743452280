import React, { useState, useRef, useEffect } from 'react';

// Framer Motion
import { motion, AnimatePresence } from 'framer-motion';

// Styles
import './ProfileTabs.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ProfileTabs Component
 */
const ProfileTabs = ({ tabsData }) => {
    // Theme
    const { theme } = useTheme();

    // Local State
    const [activeTab, setActiveTab] = useState(1);

    // References
    const prevTabRef = useRef(activeTab);

    /**
     * Whenever the activeTab changes, update the ref.
     */
    useEffect(() => {
        prevTabRef.current = activeTab;
    }, [activeTab]);

    /**
     * Determine the direction of the slide based on whether
     * the new tab index is higher or lower than the old tab index
     */
    const direction = activeTab > prevTabRef.current ? 1 : -1;

    /**
     * Event handler for when a user clicks on a tab.
     *
     * @param {number} index - The index of the tab that was clicked
     */
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // Animation variants for the tab content
    const tabVariants = {
        initial: (direction) => ({
            x: direction > 0 ? 100 : -100, // Slide in from right or left
            opacity: 0,
        }),
        animate: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.3 },
        },
        exit: (direction) => ({
            x: direction > 0 ? -100 : 100, // Slide out to left or right
            opacity: 0,
            transition: { duration: 0.3 },
        }),
    };

    return (
        <div className="profile-tabs-container">
            {/* HEADER */}
            <div
                className="profile-tabs-header"
                style={{
                    width: '460px',
                }}
            >
                {tabsData.map((tab, index) => {
                    const IconComponent = tab.icon;

                    return (
                        <div
                            key={index}
                            title={tab.title}
                            className="profile-tab-header"
                            style={{
                                borderBottomColor:
                                    activeTab === index
                                        ? theme.foregroundColor
                                        : theme.backgroundColorFaded,
                            }}
                            onClick={() => handleTabClick(index)}
                        >
                            {IconComponent && (
                                <IconComponent
                                    color={
                                        activeTab === index
                                            ? theme.foregroundColor
                                            : theme.foregroundColorFaded
                                    }
                                    width="20"
                                    height="20"
                                />
                            )}
                        </div>
                    );
                })}
            </div>

            {/* CONTENT */}
            <div className="profile-tabs-content">
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={activeTab} // Unique key to trigger animation on tab change
                        custom={direction} // Pass direction to variants
                        variants={tabVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="profile-tab-content"
                    >
                        {tabsData[activeTab]?.content || null}
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default ProfileTabs;