import React from 'react';

// Styles
import './WizardButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

const WizardButton = ({ label, onClick, enabled = true }) => {

    // Theme
    const { theme } = useTheme();

    const handleClick = () => {
        if (enabled) {
            onClick();
        }
    }

    return (
        <div
            className="wizard-button-container"
            onClick={handleClick}
            style={{
                backgroundColor: enabled ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                color: theme.highlightForegroundColor,
            }}>
            {label}
        </div>
    );
};

export default WizardButton;
