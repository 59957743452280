import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ThemeMode } from '../utilities/Enums';

// Styles
import './AppWallpaperEdit.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * AppWallpaperEdit Component
 * 
 * This component allows the user to select a dark or light mode icon.
 * 
 * @param {object} title - Title to display above the wallpaper.
 * @param {string} wallpaper - Wallpaper file.
 * @param {string} mode - "DARK" or "LIGHT".
 * @param {function} onSelect - Function to call when the icon is selected.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppWallpaperEdit = ({ title, mode, onSelect, onClear }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile
    } = useContext(Global);

    // Local State
    const [previewUrl, setPreviewUrl] = useState(null);

    // Load the preview from the existing dark or light wallpaper
    useEffect(() => {
        if (!profile) return;
        setPreviewUrl(mode === ThemeMode.DARK ? profile.wallpaperDark : profile.wallpaperLight);
    }, [profile, mode]);

    /**
     * Method to handle file selection and pass it to the parent component.
     * 
     * @param {event} e - The file selection event.
     */
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            if (onSelect) {
                onSelect(file); // send the file up
            }
        }
    };

    const handleClear = async () => {
        setPreviewUrl(null);

        // Clear out the value
        const updatedApp = {
            ...profile,
            [mode === ThemeMode.DARK ? 'wallpaperDark' : 'wallpaperLight']: null,
        };

        // Update Firestore document
        await updateDoc(doc(db, collections.apps, profile.key), updatedApp);

        onClear();
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-wallpaper-edit-container">

                {/* TITLE */}
                <div
                    className="app-wallpaper-edit-title"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: profile.fontFamily
                    }}>
                    {title}
                </div>

                {/* WALLPAPER WRAPPER */}
                <div className="app-wallpaper-edit-image-wrapper">
                    <label htmlFor={`wallpaper-upload-${profile.key}-${mode}`}>
                        <div className={isMobile ? "app-wallpaper-edit-image-mobile" : "app-wallpaper-edit-image"}
                            style={{
                                backgroundColor: theme.backgroundColor,
                                borderColor: theme.backgroundColorFaded
                            }}>

                            {/* IMAGE */}
                            {previewUrl && 
                                <img
                                    className="app-wallpaper-edit-image-file"
                                    alt="App Wallpaper"
                                    src={previewUrl}
                                />
                            }
                        </div>
                    </label>
                </div>

                {/* CLEAR */}
                <div className="app-settings-wallpaper-clear-button"
                    style={{
                        backgroundColor: theme.highlightBackgroundColor,
                        color: theme.highlightForegroundColor
                    }}
                    onClick={handleClear}>
                    Clear
                </div>

                {/* BACKGROUND INPUT */}
                <input
                    id={`wallpaper-upload-${profile.key}-${mode}`}
                    type="file"
                    onChange={handleFileChange} // Only set the file, no upload
                    style={{ display: 'none' }}
                />
            </div>
        </>
    );
};

export default AppWallpaperEdit;
