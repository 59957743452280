// Firebase
import { collections, storage } from '../../firebaseConfig';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

// Utilities
import { generateKey } from '../../common/utilities/Keys';
import { ItemType } from '../../common/utilities/Enums';
import { getDisplayProfile } from '../../common/utilities/Profiles';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Default structure for a new post in the PostWizard.
 */
export const defaultPost = {
  key: null, // Populated on save
  profileKey: null, // Populated on save
  profile: null, // Populated on save
  type: ItemType.POST,
  title: '',
  shared: true,
  paywallEnabled: false,
  price: 0.00,
  items: [],

  // Privacy settings
  privacyEveryone: true,
  privacyFriends: false,
  privacySelectedFriends: [],
  privacyOnlyYou: false,
  privacyEverywhere: true,
  privacyLocations: false,
  privacySelectedLocations: [],
  privacyPaywall: false,

  // Contribution settings
  contributeEveryone: false,
  contributeFriends: false,
  contributeSelectedFriends: [],
  contributeOnlyYou: true,
  contributeEverywhere: true,
  contributeLocations: false,
  contributeSelectedLocations: [],

  // Comments settings
  commentsEveryone: true,
  commentsFriends: false,
  commentsSelectedFriends: [],
  commentsOnlyYou: false,
  commentsEverywhere: true,
  commentsLocations: false,
  commentsSelectedLocations: [],

  // Engagements
  bookmarkCount: 0,
  commentCount: 0,
  likeCount: 0,
  shareCount: 0,
};
/**
 * Generates a summary phrase for post settings based on a prefix.
 * @param {Object} post - The post object with flattened settings.
 * @param {string} prefix - The settings prefix ("privacy", "contribution", "comments").
 * @param {boolean} [hasPaywall=false] - Whether to include paywall logic (for privacy only).
 * @returns {string} A summary phrase of the settings.
 */
export function getOptionsSummaryPhrase(post, prefix, hasPaywall = false) {
  // Handle "Only you" case first
  if (post[`${prefix}OnlyYou`]) {
    return "Only you";
  }

  // Handle friends-related cases
  if (post[`${prefix}Friends`]) {
    if (post[`${prefix}SelectedFriends`].length === 0) {
      return "All friends, everywhere";
    }

    const usernames = post[`${prefix}SelectedFriends`].map(friend => friend.username);

    if (usernames.length === 1) {
      return usernames[0];
    }
    if (usernames.length === 2) {
      return `${usernames[0]} and ${usernames[1]}`;
    }
    if (usernames.length === 3) {
      return `${usernames[0]}, ${usernames[1]} and ${usernames[2]}`;
    }
    return `${usernames[0]}, ${usernames[1]} and ${usernames.length - 2} others`;
  }

  // Handle everyone cases
  if (post[`${prefix}Everyone`]) {
    // Check paywall first if applicable (only for privacy)
    if (hasPaywall && post[`${prefix}Paywall`]) {
      return "Paying users";
    }

    if (post[`${prefix}Everywhere`] && post[`${prefix}SelectedLocations`].length === 0) {
      return "Everyone, everywhere";
    }

    if (post[`${prefix}SelectedLocations`].length > 0) {
      const locationTitles = post[`${prefix}SelectedLocations`].map(loc => loc.title);

      if (locationTitles.length === 1) {
        return `Everyone in ${locationTitles[0]}`;
      }
      if (locationTitles.length === 2) {
        return `Everyone in ${locationTitles[0]} and ${locationTitles[1]}`;
      }
      if (locationTitles.length === 3) {
        return `Everyone in ${locationTitles[0]}, ${locationTitles[1]} and ${locationTitles[2]}`;
      }
      return `Everyone in ${locationTitles[0]}, ${locationTitles[1]} and ${locationTitles.length - 2} other locations`;
    }

    return "Everyone, everywhere"; // Default everyone case
  }

  // Fallback case
  return `${prefix.charAt(0).toUpperCase() + prefix.slice(1)} settings not specified`;
}

export const addMediaToPost = (type, {
  showEventProperties,
  showSongProperties,
  setUploadItemType,
  setUploadFileTypes,
  setAddNoteOpen,
  setStreamViewerOpen,
  setStream,
  uploaderRef,
  profile,
  item,
  stream,
  streamViewerOpen,
  addStream,
  deleteStream
}) => {
  let selectionMode = "multiple";
  let useUpload = false;

  // Assuming ItemType is an enum that would also need to be exported/imported
  switch (type) {
    case ItemType.EVENT:
      useUpload = false;
      showEventProperties();
      break;
    case ItemType.PHOTO:
      setUploadItemType(ItemType.PHOTO);
      setUploadFileTypes("image/*");
      selectionMode = "multiple";
      useUpload = true;
      break;
    case ItemType.VIDEO:
      setUploadItemType(ItemType.VIDEO);
      setUploadFileTypes("video/*");
      selectionMode = "single";
      useUpload = true;
      break;
    case ItemType.SONG:
      useUpload = false;
      showSongProperties();
      break;
    case ItemType.NOTE:
      setAddNoteOpen(true);
      break;
    case ItemType.STREAM:
      if (streamViewerOpen) {
        setStreamViewerOpen(false);
        deleteStream(profile);
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          setStream(null);
        }
      } else {
        setStreamViewerOpen(true);
        addStream(profile, item);
      }
      break;
    default:
      break;
  }

  if (useUpload && uploaderRef.current) {
    uploaderRef.current.reset();
    uploaderRef.current.selectionMode = selectionMode;
    const timeoutId = setTimeout(() => {
      uploaderRef.current.click();
    }, 200);
    return () => clearTimeout(timeoutId);
  }
};


export const handleRemoveItems = async ({
  item,
  tempItems,
  combinedItems,
  selectedItems,
  setTempItems,
  setSelectedItems,
  profile,
}) => {

  if (item) {

    // Remove selected items from the item.items array
    const updatedItems = combinedItems.filter((item) => !selectedItems.some((selected) => selected.key === item.key));

    // Update the event in the database
    const data = {
      items: updatedItems,
    };

    // Save the event in the database
    try {
      await dataManager.update(collections.items, profile.key, item.key, data);
    } catch (error) {
      console.error(`Error saving event:`, error);
    }

    // Loop through the selected items and delete them from the database
    for (const selectedItem of selectedItems) {
      // Delete the item from the database
      await dataManager.delete(collections.items, profile.key, selectedItem.key);
    }

    // Clear selected items
    setSelectedItems([]);

  } else {

    // Remove selected items from the local items array
    const updatedItems = tempItems.filter((item) => !selectedItems.some((selected) => selected.key === item.key));
    setTempItems(updatedItems);

    // Clear selected items
    setSelectedItems([]);

  }
};

export const handleContribute = async ({
  handleUpload,
  hideProgress,
  item,
  onSave,
  setOpen,
  profile,
  showProgress,
}) => {
  showProgress("Contributing...");

  // Close the form
  setOpen(false);

  const updatedItems = await handleUpload();

  const data = {
    items: updatedItems,
  };

  // Update the event in the database
  try {

    await dataManager.update(collections.items, profile.key, item.key, data);

    // Notify the parent component (if applicable)
    if (onSave) {
      onSave(data);
    }

  } catch (error) {
    console.error(`Error saving event:`, error);
  }

  hideProgress();
};

/**
 * Method to handle the removal of an event.
 */
export const handleDelete = async ({
  item,
  setOpen,
  profile,
}) => {
  // Delete event copies referencing this event. Use a null item key
  // and pass in the referencePostKey as a parameter
  const params = [
    { field: 'referencePostKey', operator: '==', value: item.key },
  ];
  await dataManager.delete(collections.items, profile.key, null, params);

  // Delete the Post
  await dataManager.delete(collections.items, profile.key, item.key);

  setOpen(false);
};

export const handleUpload = async (items) => {
  const updatedItems = [...items];

  for (let i = 0; i < updatedItems.length; i++) {
    const item = updatedItems[i];

    if (
      (item.type === ItemType.PHOTO ||
        item.type === ItemType.VIDEO ||
        item.type === ItemType.FILE ||
        item.type === ItemType.SONG) &&
      item.url.startsWith('blob:')
    ) {
      try {
        const file = await fetch(item.url).then((res) => res.blob());
        const fileName = `file_${Date.now()}`;
        const fileExtension = file.type.split('/')[1];
        const filePath = `uploads/${fileName}.${fileExtension}`;
        const storageRef = ref(storage, filePath);

        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);
        updatedItems[i].url = fileUrl;
      } catch (error) {
        console.error(`Error uploading file:`, error);
      }
    }
  }

  return updatedItems;
};

// Handle saving or updating a post
export const handleSave = async ({
  post,
  profile,
  hideProgress,
  showProgress,
  setOpen,
  onSave,
}) => {

  showProgress("Posting...");

  // Close the wizard
  setOpen(false);

  // Validate title
  if (post.title.trim() === '') {
    post.title = '(No title)';
  }

  // Upload any local files in post.items and update the post object
  post.items = await handleUpload(post.items);

  // Prepare the final post object
  const data = {
    ...post, // Spread all existing post properties
    ...(post.key ? {} : { // Add these only for new posts
      key: generateKey(),
      profileKey: profile.key,
      profile: getDisplayProfile(profile),
    }),
  };

  try {
    if (post.key) {
      console.log('Updating post:', data);
      // Update existing post
      await dataManager.update(collections.items, profile.key, post.key, data);
    } else {
      console.log('Adding post:', data);
      // Add new post
      await dataManager.add(collections.items, profile.key, data.key, data);
    }

    // Notify parent component
    if (onSave) {
      onSave(data);
    }
  } catch (error) {
    console.error(`Error saving post:`, error);
  }

  hideProgress();
};