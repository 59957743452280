import React, { useContext, forwardRef } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Style
import './CommentsAddInput.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Components
import CommentsEmojiSelector from './emojis/CommentsEmojiSelector';

const CommentsAddInput = forwardRef(({
    newComment,
    onFocus,
    onChange,
    placeholder
}, ref) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile
    } = useContext(Global);

    if (!profile) return null;

    const handleChange = (e) => {
        onChange(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const handleEmojiClick = (emoji) => {
        const textarea = ref.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = newComment || '';

        // Insert emoji at cursor position
        const newText = text.slice(0, start) + emoji + text.slice(end);
        onChange(newText);

        // Move cursor after the inserted emoji
        setTimeout(() => {
            textarea.focus();
            const newPosition = start + emoji.length;
            textarea.setSelectionRange(newPosition, newPosition);
        }, 0);
    };

    return (
        <div className="comments-add-input-wrapper">
            <CommentsEmojiSelector onEmojiClick={handleEmojiClick} />
            <textarea
                ref={ref}
                name="comment"
                className="comments-add-input-container"
                placeholder={placeholder}
                onFocus={onFocus}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColor,
                    color: theme.foregroundColor,
                    resize: 'none',
                    overflow: 'hidden',
                    fontFamily: profile.fontFamily,
                }}
                value={newComment}
                rows={1}
                onChange={handleChange}
            />
        </div>
    );
});

export default CommentsAddInput;