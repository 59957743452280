import { useEffect } from 'react';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadItems = (profile, setPrivateItems, setPublicItems) => {

  useEffect(() => {

    // Exit early if no profile
    if (!profile || !profile.key) {
      setPublicItems([]);
      return;
    }

    const load = () => {

      const handleUpdate = (items) => {

        // Set public and private items based upon the "shared" property.
        const publicItems = items.filter(item => item.shared);
        const privateItems = items.filter(item => !item.shared && !item.eventKey && !item.photosKey && !item.videoKey);

        setPrivateItems(privateItems); 
        setPublicItems(publicItems);
      };

      const params = [
        { field: "profileKey", operator: "==", value: profile.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
      
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };
  }, [profile, setPrivateItems, setPublicItems]);

};

export default usePreloadItems;
