import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Drag/Drop
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Utilities
import { ItemType } from '../../../../../common/utilities/Enums';

// Styles
import './CalendarWeek.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import CalendarWeekHeader from './CalendarWeekHeader';
import CalendarWeekHourBlock from './CalendarWeekHourBlock';
import CalendarWeekEvent from './CalendarWeekEvent';
import CalendarWeekAllDayEvent from './CalendarWeekAllDayEvent';

// Managers
import EventManager from '../../../../managers/EventManager';

const CalendarWeek = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        profileEvents,
        selectedCalendarTags,
        setCalendarTitle,
        showCalendarFeed,
        homeItems,
    } = useContext(Global);

    const [daysToShow, setDaysToShow] = useState([]);
    const [weekDayEvents, setWeekDayEvents] = useState([]);
    const [overlayWidth, setOverlayWidth] = useState(0);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [multiDayEvents, setMultiDayEvents] = useState([]);
    const [feedEvents, setFeedEvents] = useState([]);

    const overlayRef = useRef(null);
    let hourLabelWidth = 60;

    // Process feed events
    useEffect(() => {
        if (!homeItems) return;
        const feedEvents = homeItems.filter(item => item.type === ItemType.EVENT);
        setFeedEvents(feedEvents);
    }, [homeItems]);

    // Process all events (personal + feed when applicable)
    useEffect(() => {
        if (!weekDayEvents || !daysToShow.length) return;

        // Process personal events
        const filteredPersonalEvents = weekDayEvents.filter(event => {
            if (selectedCalendarTags.length === 0) return true;
            return event.tags.some(tag => selectedCalendarTags.includes(tag));
        });

        // Normalize dates helper
        const normalizeDate = date => {
            const d = new Date(date);
            return new Date(d.getFullYear(), d.getMonth(), d.getDate());
        };
        const normalizedDaysToShow = daysToShow.map(normalizeDate);
        
        let allMultiDay = [];
        let allSingleDay = [];

        // Process personal events (always included)
        filteredPersonalEvents.forEach(event => {
            const eventStart = event.startDate.toDate();
            const eventEnd = event.endDate.toDate();
            const normalizedStart = normalizeDate(eventStart);
            const normalizedEnd = normalizeDate(eventEnd);

            if (
                normalizedStart < normalizedDaysToShow[0] ||
                normalizedEnd > normalizedDaysToShow[6] ||
                normalizedStart.getTime() !== normalizedEnd.getTime()
            ) {
                allMultiDay.push(event);
            } else {
                allSingleDay.push(event);
            }
        });

        // Add feed events only when showCalendarFeed is true
        if (showCalendarFeed && feedEvents.length > 0) {
            feedEvents.forEach((event, index) => {
                const eventStart = new Date(event.startDate.seconds * 1000);
                const eventEnd = new Date(event.endDate.seconds * 1000);
                const normalizedStart = normalizeDate(eventStart);
                const normalizedEnd = normalizeDate(eventEnd);

                if (normalizedStart <= normalizedDaysToShow[6] && normalizedEnd >= normalizedDaysToShow[0]) {
                    const formattedEvent = {
                        ...event,
                        key: event.key || `feed_${index}`
                    };

                    if (
                        normalizedStart < normalizedDaysToShow[0] ||
                        normalizedEnd > normalizedDaysToShow[6] ||
                        normalizedStart.getTime() !== normalizedEnd.getTime()
                    ) {
                        allMultiDay.push(formattedEvent);
                    } else {
                        allSingleDay.push(formattedEvent);
                    }
                } 
            });
        } 

        setMultiDayEvents(allMultiDay);
        setDisplayEvents(allSingleDay);
    }, [weekDayEvents, selectedCalendarTags, daysToShow, showCalendarFeed, feedEvents]);

    // Rest of your existing useEffect hooks
    useEffect(() => {
        const updateOverlayWidth = () => {
            if (overlayRef.current) {
                setOverlayWidth(overlayRef.current.offsetWidth);
            }
        };
        updateOverlayWidth();
        window.addEventListener('resize', updateOverlayWidth);
        return () => window.removeEventListener('resize', updateOverlayWidth);
    }, []);

    useEffect(() => {
        if (activeDate) {
            const baseDate = new Date(activeDate);
            baseDate.setDate(baseDate.getDate() - baseDate.getDay());
            const newDaysToShow = Array.from({ length: 7 }, (_, index) => {
                return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate() + index);
            });
            setDaysToShow(newDaysToShow);
        }
    }, [activeDate]);

    const hourBlocksRef = useRef(null);
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70;
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (daysToShow.length === 7) {
            setCalendarTitle(`${formatDateLong(daysToShow[0])} - ${formatDateLong(daysToShow[6])}`);
        }
    }, [daysToShow, setCalendarTitle]);

    useEffect(() => {
        if (daysToShow.length === 7) {
            const eventManager = new EventManager();
            setWeekDayEvents(eventManager.getEventsBetweenDates(profileEvents, daysToShow[0], daysToShow[6]));
        }
    }, [activeDate, profileEvents, daysToShow]);

    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    const formatDateLong = (date) => {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {/* Multi-Day Events */}
            <div className="calendar-week-multi-day-events" ref={overlayRef} style={{ marginLeft: hourLabelWidth }}>
                {multiDayEvents.map((event, index) => (
                    <CalendarWeekAllDayEvent
                        key={event.key || index}
                        event={event}
                        daysToShow={daysToShow}
                        overlayWidth={overlayWidth}
                    />
                ))}
            </div>

            {/* CONTAINER - SCROLLABLE */}
            <div className="calendar-week-container" ref={hourBlocksRef}>
                <div className="calendar-week-header" style={{ borderBottomColor: theme.backgroundColorFaded }}>
                    <CalendarWeekHeader daysToShow={daysToShow} />
                </div>
                <div className="calendar-week-wrapper">
                    {hours.map((hour, hourIndex) => (
                        <CalendarWeekHourBlock
                            key={`${hour}_${hourIndex}`}
                            hour={hour}
                            hourIndex={hourIndex}
                            leftOffset={hourLabelWidth}
                            daysToShow={daysToShow}
                            useDrop={useDrop}
                        />
                    ))}

                    <div ref={overlayRef} className="calendar-week-events-overlay">
                        {displayEvents.length > 0 && (
                            displayEvents.map((event, index) => (
                                <CalendarWeekEvent
                                    key={event.key || index}
                                    event={event}
                                    index={index}
                                    leftOffset={hourLabelWidth}
                                    daysToShow={daysToShow}
                                    overlayWidth={overlayWidth}
                                    useDrag={useDrag}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default CalendarWeek;