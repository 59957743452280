import React from 'react';

// Styles
import './HomeToolbarButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const HomeToolbarButton = ({ title, selected = false, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="home-toolbar-button-container"
            onClick={onClick}
            style={{
                color: theme.foregroundColor
            }}>
            <div className="home-toolbar-button-title">
                {title}
            </div>
            <div className="home-toolbar-button-bar-wrapper">
                {selected &&
                    <div className="home-toolbar-button-bar"
                        style={{
                            backgroundColor: theme.highlightBackgroundColor,
                        }}></div>
                }
            </div>
        </div>
    );
};

export default HomeToolbarButton;
