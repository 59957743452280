import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Styles
import './ProfilePhoto.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../../common/svg/PlusIcon';

/**
 * ProfilePhoto Component
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfilePhoto = ({ item, size }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        setProfile,
        showProgress,
        hideProgress,
    } = useContext(Global);

    /**
     * Method to handle file selection and initiate upload.
     * 
     * @param {Event} event - The file input change event.
     */
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            await uploadPhoto(file);
        }
    };

    /**
     * Method to upload a profile photo to Firebase Storage.
     * 
     * @param {File} file - The file to upload.
     */
    const uploadPhoto = async (file) => {

        if (profile.key !== item.key) return;

        showProgress("Setting profile photo...");

        const fileExtension = file.name.split('.').pop();

        try {
            const folderRef = ref(storage, `profiles/${item.key}/photo/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(folderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(item.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `profiles/${item.key}/photo/${item.key}.${fileExtension}`);
            await uploadBytes(storageRef, file); // Upload the file
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            const updatedProfile = {
                photo: fileUrl,
            };

            // Update local profile state
            setProfile((prev) => ({
                ...prev,
                ...updatedProfile,
            }));

            // Update Firestore document
            await updateDoc(doc(db, collections.profiles, item.key), updatedProfile);

        } catch (error) {
            console.error("Error handling profile photo upload:", error);
        }

        hideProgress();
    };

    return (
        <div className="profile-photo-container"
            style={{
                height: `${size}px`,
                width: `${size}px`,
                cursor: profile.key === item.key ? "pointer" : "default",
            }}>

            {/* Only render the label and input if profile.key === item.key */}
            {profile.key === item.key ? (
                <label htmlFor={`logo-upload-${item.key}`}>
                    {/* PHOTO WRAPPER */}
                    <div className="profile-photo-circle-wrapper">
                        <div className="profile-photo-circle"
                            style={{
                                backgroundColor: theme.backgroundColor,
                                borderColor: theme.backgroundColorFaded,
                                height: `${size}px`,
                                width: `${size}px`,
                            }}>

                            {/* IMAGE */}
                            {item && item.photo && (
                                <img
                                    alt={item.username}
                                    className="profile-photo-image"
                                    src={item.photo}
                                />
                            )}
                        </div>
                    </div>

                    <div className="profile-photo-add-button"
                        style={{
                            backgroundColor: theme.highlightBackgroundColor,
                            borderColor: theme.backgroundColor,
                        }}>
                        <PlusIcon
                            color={theme.highlightForegroundColor}
                            width="16"
                            height="16"
                        />
                    </div>
                </label>
            ) : (
                // If profile.key !== item.key, just render the photo without the label
                <div className="profile-photo-circle-wrapper">
                    <div className="profile-photo-circle"
                        style={{
                            backgroundColor: theme.backgroundColor,
                            borderColor: theme.backgroundColorFaded,
                            height: `${size}px`,
                            width: `${size}px`,
                        }}>

                        {/* IMAGE */}
                        {item && item.photo && (
                            <img
                                alt={item.username}
                                className="profile-photo-image"
                                src={item.photo}
                            />
                        )}
                    </div>
                </div>
            )}

            {/* Only render the input if profile.key === item.key */}
            {profile.key === item.key && (
                <input
                    id={`logo-upload-${item.key}`}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            )}
        </div>
    );
};

export default ProfilePhoto;