import React from 'react';

// Utilities
import { hexToRgba } from '../../utilities/Colors.js';
import { ItemType, ThemeMode } from '../../utilities/Enums.js';

// Styles
import './ItemMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

const ItemMenu = ({ item, setOpen, children }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    if (!item) return null;

    return (
        <>
            <div
                className="contribute-menu-container"
                style={{
                    backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.7),
                }}>
                    {children}

            </div>
            <div
                className="contribute-menu-mask"
                onClick={() => setOpen(false)}
                style={{
                    backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.7),
                }}
            ></div>
        </>
    );
};

export default ItemMenu;
