import { useEffect } from 'react';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load a profile with real-time updates.
 */
const usePreloadFullProfile = (profile, setFullProfile) => {

  useEffect(() => {

    // Exit early if no profile
    if (!profile || !profile.key) {
      setFullProfile(null);
      return;
    }

    const load = () => {

      const handleUpdate = (items) => {
        setFullProfile(items[0]); 
      };

      const params = [
        { field: "key", operator: "==", value: profile.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.profiles,
        profile.key,
        handleUpdate,
        params
      );

      return unsubscribe;
      
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };
  }, [profile, setFullProfile]);

};

export default usePreloadFullProfile;
