import React from 'react';

const AddAudioIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M484.62-167.69q-49.5 0-84.75-35.25t-35.25-84.75q0-49.5 35.25-84.75t84.75-35.25q23 0 43.65 8.19t36.35 24.58v-417.39H760v101.54H604.62v403.08q0 49.5-35.25 84.75t-84.75 35.25ZM289.23-512.31v-120h-120v-40h120v-120h40v120h120v40h-120v120h-40Z" />
  </svg>

);

export default AddAudioIcon;
