import React from 'react';

// Styles
import './ItemMenuButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ItemMenuButton = ({ icon: IconComponent, iconColor, iconBackgroundColor, iconSize = 14, label, labelColor = null, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div
            className="item-menu-button-container"
            onClick={onClick}>

            {/* ICON */}
            <div className="item-menu-button-icon"
                style={{
                    backgroundColor: iconBackgroundColor,
                }}>
                <IconComponent
                    color={iconColor}
                    width={iconSize}
                    height={iconSize}
                />
            </div>

            {/* LABEL */}
            <div className="item-menu-button-label"
                style={{
                    color: labelColor ? labelColor : theme.foregroundColor
                }}>
                {label}
            </div>

        </div>
    );
};

export default ItemMenuButton;
