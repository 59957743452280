import React from 'react';

// Styles
import './NoteThumbItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import NoteIcon from '../../../../svg/NoteIcon';

// Components
import ThumbDelete from '../../../common/thumbdelete/ThumbDelete';

/**
 * NoteThumbItem
 * 
 * A grid item for displaying notes.
 * 
 * @param {Object} item The note item.
 * @param {Function} onClick The click note handler.
 * 
 * @returns {JSX.Element} NoteThumbItem component.
 */
const NoteThumbItem = ({ item, onClick, onDelete }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="note-thumb-item-container"
            onClick={(note) => {
                if (onClick) onClick(note, item);
            }}
            title={item.title}>

            {/* DELETE BUTTON */}
            <ThumbDelete 
                item={item} 
                onDelete={onDelete} 
            />

            {/* ICON */}
            <div className="note-thumb-item-icon-container"
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                }}>
                <NoteIcon
                    color={theme.highlightBackgroundColor}
                    width="50"
                    height="50"
                />
            </div>

        </div>

    );
};

export default NoteThumbItem;
