import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

// Managers
import ProfileManager from '../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * useConfirmLoginStatus
 * 
 * Custom hook to detect a person's login status and set the value of
 * the profile variable in the global context. If the person is logged
 * in, the profile is fetched from the database and set. If the person
 * is not logged in, the profile is set to null. This will allow the
 * system to detect whether a login modal should be displayed at any
 * given time, wherever the user is in the app.
 * 
 * The auth user will have a user UID, which will be identical
 * to the profile key of the profile we need to load into state.
 * 
 * Additionally, if the user is not logged in, this will display the
 * Login modal to force a login. This will ensure that a profile is always
 * set in the global context before utilizing the app.
 */
const useConfirmLoginStatus = () => {

  // Global
  const { 
    setLoginOpen,
    setProfile,
  } = useContext(Global);

  useEffect(() => {
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const fetched = await profileManager.getProfile(user.uid);
          setProfile(fetched);
          setLoginOpen(false);
        } catch (error) {
          console.log(error);
          setProfile(null);
          setLoginOpen(true);
        }
      } else {
        setProfile(null);
        setLoginOpen(true);
      }
    });

    return () => unsubscribe();

  }, [setProfile, setLoginOpen]);
  
};

export default useConfirmLoginStatus;
