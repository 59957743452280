import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Utilities
import { EngagementType } from '../../common/utilities/Enums';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load engagements for a specific profile.
 */
const usePreloadProfileEngagements = () => {

  // Global
  const {
    profile,
    setProfileEngagements,
    setProfilePosts
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected profile
    if (!profile || !profile.key) {
      setProfileEngagements([]);
      return;
    }

    const load = () => {
      const handleUpdate = (engagements) => {
        setProfileEngagements(engagements);

        // Load events from the engagements
        const posts = engagements.filter(engagement => engagement.type === EngagementType.POST);
        setProfilePosts(posts);
      };

      const params = [
        { field: "profileKey", operator: "==", value: profile.key }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.engagements,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeEngagements = load();

    return () => {
      if (unsubscribeEngagements) {
        unsubscribeEngagements();
      }
    };
  }, [profile, setProfileEngagements, setProfilePosts]);

};

export default usePreloadProfileEngagements;
