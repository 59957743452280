import React from 'react';

// Styles
import './AddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../svg/PlusIcon';

const AddButton = ({ title = '', onClick, size = 40 }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      <div className="add-button-container"
        style={{ 
          //backgroundColor: theme.highlightBackgroundColor, 
          width: `${size}px`,
          height: `${size}px`,
        }}
        title={title}
        onClick={onClick}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width={Math.round(size * 0.5)}
          height={Math.round(size * 0.5)}
        />
      </div>
    </>
  );
};

export default AddButton;
