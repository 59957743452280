import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Utilities
import { getRelativeTime } from '../../../../../utilities/DateTime';

// Styles
import './CommentFooter.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import DislikeIcon from '../../../../../svg/DislikeIcon';
import DislikeFilledIcon from '../../../../../svg/DislikeFilledIcon';
import LikeIcon from '../../../../../svg/LikeIcon';
import LikeFilledIcon from '../../../../../svg/LikeFilledIcon';

/**
 * CommentFooter component
 * 
 * Renders a footer for a comment.
 * 
 */
const CommentFooter = ({ comment, onReplyClicked, onLikeClicked, onDislikeClicked }) => {
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        commentLikes,
        commentDislikes,
        profile
    } = useContext(Global);

    // Local State
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [disliked, setDisliked] = useState(false);

    useEffect(() => {
        if (!comment || !profile) return;

        // Check if the current user has liked the comment
        const liked = commentLikes.some(
            commentLike => 
                commentLike.commentKey === comment.key && 
                commentLike.profileKey === profile.key
        );
        setLiked(liked);

        // Check if the current user has disliked the comment
        const disliked = commentDislikes.some(
            commentDislike => 
                commentDislike.commentKey === comment.key && 
                commentDislike.profileKey === profile.key
        );
        setDisliked(disliked);

        // Total like count (no profile check needed, just counting all likes for this comment)
        const likeCount = commentLikes.filter(
            commentLike => commentLike.commentKey === comment.key
        ).length;
        setLikeCount(likeCount);
    }, [commentLikes, commentDislikes, comment, profile]);

    return (
        <>
            {/* LEFT */}
            <div className="comment-footer-left">
                {/* ELAPSED */}
                <div className="comment-footer-elapsed" style={{ color: theme.foregroundColorFaded }}>
                    {getRelativeTime(comment.dateCreated)}
                </div>

                {/* REPLY BUTTON */}
                <div
                    className="comment-show-reply-button"
                    style={{ color: theme.foregroundColorFaded }}
                    onClick={(event) => onReplyClicked(event, comment)}>
                    Reply
                </div>
            </div>

            {/* RIGHT */}
            <div className="comment-footer-right">
                {/* LIKES BUTTON */}
                <div className="comment-footer-likes-button">
                    {/* ICON */}
                    <div
                        className="comment-footer-likes-icon"
                        onClick={() => onLikeClicked(comment)}>
                        {liked ? (
                            <LikeFilledIcon
                                color={theme.highlightBackgroundColor}
                                width="19"
                                height="19"
                            />
                        ) : (
                            <LikeIcon
                                color={theme.foregroundColorFaded}
                                width="19"
                                height="19"
                            />
                        )}
                    </div>

                    {/* COUNT */}
                    <div
                        className="comment-footer-likes-count"
                        style={{ color: theme.foregroundColorFaded }}>
                        {likeCount}
                    </div>
                </div>

                {/* DISLIKE BUTTON */}
                <div
                    className="comment-footer-dislike-button"
                    onClick={() => onDislikeClicked(comment)}>
                    {disliked ? (
                        <DislikeFilledIcon
                            color={theme.foregroundColorFaded}
                            width="19"
                            height="19"
                        />
                    ) : (
                        <DislikeIcon
                            color={theme.foregroundColorFaded}
                            width="19"
                            height="19"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CommentFooter;