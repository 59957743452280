import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load following for a specific profile.
 */
const usePreloadProfileFollowing = () => {

  // Global
  const {
    profile,
    setProfileFollowing,
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected profile
    if (!profile || !profile.key) {
      setProfileFollowing([]);
      return;
    }

    const load = () => {
      const handleUpdate = (following) => {
        setProfileFollowing(following);
      };

      const params = [
        { field: "followerKey", operator: "==", value: profile.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.follows,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeFollowing = load();

    return () => {
      if (unsubscribeFollowing) {
        unsubscribeFollowing();
      }
    };
  }, [profile, setProfileFollowing]);

};

export default usePreloadProfileFollowing;
