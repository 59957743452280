import React from 'react';

// Utilities
import { hexToRgba } from '../../../../../common/utilities/Colors';

// Styles
import './SongGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import SongIcon from '../../../../../common/svg/SongIcon';

// Components
import GridItem from '../../../../../common/components/grid/griditem/GridItem';

/**
 * SongGridItem
 * 
 * A grid item for displaying songs.
 * 
 * @param {Object} item The song item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} SongGridItem component.
 */
const SongGridItem = ({ item, selected = false, showAuthor = false, onClick, onDoubleClick, onDragStart }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart) onDragStart(event, item);
            }}>

            <div
                className="song-grid-item-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.backgroundColorFaded,
                }}
                title={item.title}>

                {/* ICON */}
                <div className="song-grid-item-icon-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <SongIcon
                        color={theme.highlightBackgroundColor}
                        width="70"
                        height="70"
                    />
                </div>

                {showAuthor &&

                    <div className="song-grid-item-about"
                        style={{
                            backgroundColor: hexToRgba("black", 0.5),
                        }}>
                        {item.title} - {item.artist}
                    </div>

                }

            </div>

        </GridItem>
    );
};

export default SongGridItem;
