import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Pins.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ExplorePinIcon from '../../svg/ExplorePinIcon';
import HomePinIcon from '../../svg/HomePinIcon';

// Components
import Pin from './pin/Pin';
import PinsMap from './map/PinsMap';
import PinsToolbar from './toolbar/PinsToolbar';
import Section from '../../components/section/Section';

const Pins = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profilePins,
    selectedPin,
    setSelectedPin
  } = useContext(Global);

  const handlePinClick = (pin) => {
    setSelectedPin(pin);
  };

  // Filter out the home pin (if it exists)
  const homePin = profilePins.find(pin => pin.isHome === true);
  
  // Filter pins that are not the home pin
  const followingPins = profilePins.filter(pin => !pin.isHome);

  return (
    <Section>
      <div className="pins-map-container">
        {/* TOOLBAR */}
        <PinsToolbar />

        <div className="pins-map-bottom">
          <div className="pins-map-left">
            <div className="pins-map-pins">
              {/* HOME PIN */}
              {homePin && (
                <Pin
                  key={homePin.key}
                  icon={HomePinIcon} // Use HomePinIcon for the home pin
                  pin={homePin}
                  selected={selectedPin && selectedPin.key === homePin.key}
                  onClick={() => handlePinClick(homePin)}
                />
              )}

              {/* FOLLOWING TITLE */}
              <div
                className="pins-map-following"
                style={{
                  color: theme.foregroundColorFaded,
                }}
              >
                Following
              </div>

              {/* FOLLOWING PINS */}
              {followingPins.map((pin) => (
                <Pin
                  key={pin.key}
                  icon={ExplorePinIcon}
                  pin={pin}
                  selected={selectedPin && selectedPin.key === pin.key}
                  onClick={() => handlePinClick(pin)}
                />
              ))}
            </div>
          </div>
          <div className="pins-map-right">
            <PinsMap />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Pins;