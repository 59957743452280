import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { SectionType } from '../../common/utilities/Enums.js';

// Styles
import './FooterMenu.css';

// Images
import CalendarIcon from '../../common/svg/CalendarIcon';
import HomeIcon from '../../common/svg/HomeIcon';
import FriendsIcon from '../../common/svg/FriendsIcon';
import InboxIcon from '../../common/svg/InboxIcon';
import SettingsIcon from '../../common/svg/SettingsIcon';
import UserIcon from '../../common/svg/UserIcon';

// Components
import FooterMenuAddButton from './addbutton/FooterMenuAddButton.js';
import FooterMenuButton from './button/FooterMenuButton.js';
import InboxDialog from '../../common/components/inboxdialog/InboxDialog.js';
import ProfileModal from '../../common/components/profilemodal/ProfileModal.js';

const FooterMenu = () => {

  // Global
  const {
    profile,
    profileIncomingEngagements,
    setCurrentSection,
  } = useContext(Global);

  // Local State
  const [inboxOpen, setInboxOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  const getUnreadCount = () => {
    return profileIncomingEngagements
      .filter(engagement => !engagement.isRead).length;
  };

  const handleHomeClick = () => {
    setCurrentSection(SectionType.HOME);
  };

  const handleFriendsClick = () => {
    setCurrentSection(SectionType.FRIENDS);
  };

  const handleCalendarClick = () => {
    setCurrentSection(SectionType.CALENDAR);

  };

  const handleInboxClick = () => {
    setCurrentSection(SectionType.INBOX);
  };

  const handleProfileClick = () => {
    setProfileOpen(true);
  };

  const handleSettingsClick = () => {
    setCurrentSection(SectionType.SETTINGS);
  };

  return (
    <>
      <div className="footer-menu-container"
        style={{
          backgroundColor: "#000000",
        }}>

        <FooterMenuButton
          icon={HomeIcon}
          title="Home"
          count={0}
          onClick={handleHomeClick}
        />

        <FooterMenuButton
          icon={FriendsIcon}
          title="Friends"
          count={0}
          onClick={handleFriendsClick}
        />

        <FooterMenuButton
          icon={CalendarIcon}
          title="Calendar"
          count={0}
          onClick={handleCalendarClick}
        />

        <FooterMenuAddButton />

        <FooterMenuButton
          icon={InboxIcon}
          title="Inbox"
          count={getUnreadCount()}
          onClick={handleInboxClick}
        />

        <FooterMenuButton
          icon={UserIcon}
          title="Profile"
          count={0}
          onClick={handleProfileClick}
        />

        <FooterMenuButton
          icon={SettingsIcon}
          title="Settings"
          onClick={handleSettingsClick}
        />

      </div>

      {/* INBOX */}
      <InboxDialog
        isOpen={inboxOpen}
        setOpen={setInboxOpen}
      />

      {/* PROFILE */}
      <ProfileModal
        profile={profile}
        isOpen={profileOpen}
        setOpen={setProfileOpen}
      />

    </>
  );
};

export default FooterMenu;