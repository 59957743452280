import { Timestamp } from 'firebase/firestore';

export const dayNamesLetter = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const dayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const dayNamesLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * Formats a timestamp into a human-readable relative time string.
 * Handles both past and future timestamps.
 * @param {Timestamp} timestamp - The Firebase timestamp to format.
 * @returns {string} A formatted relative time string (e.g., "2 days ago" or "in 3 hours").
 */
export function getRelativeTime(timestamp) {
  const now = Timestamp.now().toDate();
  const eventDate = timestamp.toDate();
  const diffInMs = eventDate - now; // Positive for future, negative for past

  const minutes = Math.floor(Math.abs(diffInMs) / 60000);
  const hours = Math.floor(Math.abs(diffInMs) / 3600000);
  const days = Math.floor(Math.abs(diffInMs) / 86400000);
  const months = Math.floor(Math.abs(diffInMs) / 2592000000); // Approximate month
  const years = Math.floor(Math.abs(diffInMs) / 31536000000); // Approximate year

  const isFuture = diffInMs > 0;
  const timeUnit = (value, unit) => `${value} ${unit}${value > 1 ? 's' : ''}`;

  if (years > 0) return isFuture ? `in ${timeUnit(years, 'year')}` : `${timeUnit(years, 'year')} ago`;
  if (months > 0) return isFuture ? `in ${timeUnit(months, 'month')}` : `${timeUnit(months, 'month')} ago`;
  if (days > 0) return isFuture ? `in ${timeUnit(days, 'day')}` : `${timeUnit(days, 'day')} ago`;
  if (hours > 0) return isFuture ? `in ${timeUnit(hours, 'hour')}` : `${timeUnit(hours, 'hour')} ago`;
  if (minutes > 0) return isFuture ? `in ${timeUnit(minutes, 'minute')}` : `${timeUnit(minutes, 'minute')} ago`;
  return isFuture ? 'Soon' : 'Just now';
}

export function getRelativeTimeShort(timestamp) {
  const now = Timestamp.now().toDate();
  const commentDate = timestamp.toDate();
  const diffInMs = now - commentDate;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(diffInMs / 60000);
  const hours = Math.floor(diffInMs / 3600000);
  const days = Math.floor(diffInMs / 86400000);
  const weeks = Math.floor(diffInMs / 604800000); // Approximate week
  const months = Math.floor(diffInMs / 2592000000); // Approximate month
  const years = Math.floor(diffInMs / 31536000000); // Approximate year

  if (years > 0) return `${years}y`;
  if (months > 0) return `${months}mo`;
  if (weeks > 0) return `${weeks}w`;
  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  if (seconds > 0) return `${seconds}s`;
  return 'Just now';
};


export function getMonthAndYearString(year, month) {
  return new Date(year, month).toLocaleString('default', { month: 'long' }) + " " + year
};

export function getMonthDays(year, month) {
  return new Date(year, month + 1, 0).getDate();
};

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1).getDay();
};


  /**
   * Calculates the number of days in a given month and year.
   * 
   * @param {number} month The month.
   * @param {number} year The year.
   * 
   * @returns {Array} The days in the month.
   */
  export function getDaysInMonth(monthIndex, year) {
    const daysInMonth = new Date(year, monthIndex, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
  }

