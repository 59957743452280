import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './NoteFeedItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const NoteFeedItem = ({ item }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    if (!item) return null;

    return (
        <div className="note-container"
            style={{
                paddingTop: isMobile ? "90px" : "60px"
            }}>

            {/* HEADER */}
            <div className="note-header">

                {/* LEFT */}
                <div className="note-header-left">

                    {/* TITLE */}
                    <div className="note-title"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: profile.fontFamily,
                        }}>
                        {item.title}
                    </div>

                </div>

            </div>

            {/* WRAPPER */}
            <div className="note-wrapper">

                <div className="note-content"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: profile.fontFamily
                    }}>
                    {item.text}
                </div>

            </div>

        </div>
    );
};

export default NoteFeedItem;
