import React, { useEffect, useState } from 'react';

import { hexToRgba } from '../../../../utilities/Colors';
import { ItemSize, ThemeMode } from '../../../../utilities/Enums';

// Styles
import './VideoGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import VideoIcon from '../../../../svg/VideoIcon';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';

/**
 * VideoGridItem
 * 
 * A grid item for displaying videos.
 * 
 * @param {Object} item The video item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click video handler.
 * @param {Function} onDoubleClick The double click video handler.
 * @param {Function} onDragStart The drag start video handler.
 * 
 * @returns {JSX.Element} VideoGridItem component.
 */
const VideoGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Local State
        const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {
        // Ensure item.items exists
        if (item?.items) {

            // Gather all videos
            const videos = item.items.filter(i => i.type === "VIDEO");
            if (videos.length > 0) {
                setVideoUrl(videos[0].url);
            }
        } else {
            if (item.url) {
                setVideoUrl(item.url);
            }
        }

    }, [item]);

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(video) => {
                if (onClick) onClick(video, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(video) => {
                if (onDragStart) onDragStart(video, item);
            }}
            size={size}>

            <div className="video-grid-item-container"
                title={item.title}>

                {/* VIDEO */}
                {videoUrl ? (
                    <div className="video-grid-item-image-container">

                        <video
                            className="video-thumb-item-video"
                            src={videoUrl}
                            controls={false}
                            autoPlay={false}
                        />

                    </div>
                ) : (
                    <div
                        className="video-grid-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <VideoIcon
                            color={theme.highlightBackgroundColor}
                            width="110"
                            height="110"
                        />
                    </div>
                )}

                {/* FOOTER */}
                
                {(item.items &&
                    ((item.title && item.title.length > 0) || (item.description && item.description.length > 0))) &&

                    <div className="video-grid-item-footer"
                        style={{
                            backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                        }}>

                        <div className="video-grid-item-title"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {item.title}
                        </div>

                    </div>

                }

            </div>

        </GridItem>
    );
};

export default VideoGridItem;
