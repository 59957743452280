import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../../Global';

// Utilities
import { hexToRgba } from '../../../../../../../common/utilities/Colors';
import { ItemType, ThemeMode } from '../../../../../../../common/utilities/Enums';
import { formatEventDates } from '../../../../../../../common/utilities/Formatters';

// Styles
import './EventDisplay.css';

// Theme
import { useTheme } from '../../../../../../../ThemeContext';

// Images
import AddEventIcon from '../../../../../../../common/svg/AddEventIcon';

const EventDisplay = ({ item, onAddToCalendar }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [eventDisplay, setEventDisplay] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);

    // When the item is set, look for any item.items in which the type === ItemType.EVENT,
    // and if found, set the start/end dates/times to the first event item.
    useEffect(() => {
        if (item && item.items) {
            const eventItem = item.items.find(i => i.type === ItemType.EVENT);
            if (eventItem) {
                const { display } = formatEventDates(eventItem.startDate, eventItem.endDate, false);
                setEventDisplay(display);
                setEventTitle(eventItem.title);
                return;
            }
        }
        setEventDisplay(null);
    }, [item]);

    if (!eventDisplay) return null;

    return (

        <div className="event-display-container">

            {eventTitle && (
                <div className="event-display-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {eventTitle}
                </div>
            )}

            <div className="event-display-details">

                {/* LEFT */}
                <div className="event-display-left">
                    <div className="event-display-dates"
                        style={{
                            color: theme.foregroundColor,
                            whiteSpace: 'pre-line', // Respect line breaks
                        }}>
                        {eventDisplay}
                    </div>
                </div>

                {/* RIGHT */}
                <div className="event-display-right">

                    {profile &&
                        <div className="post-feed-item-footer-button"
                            onClick={onAddToCalendar}
                            style={{
                                backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.4),
                                color: theme.highlightForegroundColor,
                            }}>
                            <AddEventIcon
                                color={theme.highlightBackgroundColor}
                                width="14"
                                height="14"
                            />
                            <div className="post-feed-item-footer-button-label" style={{
                                color: theme.highlightForegroundColor,
                            }}>
                                Save This Event
                            </div>
                        </div>
                    }

                </div>

            </div>

        </div>
    );
};

export default EventDisplay;