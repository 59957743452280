import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ProfileButtons.css';

// Images
import AddFriendIcon from '../../../../common/svg/AddFriendIcon';

// Components
import ProfileButton from '../button/ProfileButton';

const ProfileButtons = ({ viewingProfile, onEditClick, onShareClick, onAddFriendClick, onFollowClick, onUnfollowClick }) => {

    // Global
    const {
        profile,
        profileFollowing,
    } = useContext(Global);

    // Local State
    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        // Check if the viewing profile is already followed
        const following = profileFollowing.some(follow => follow.followingKey === viewingProfile.key);
        setIsFollowing(following);
    }, [profileFollowing, viewingProfile.key]);

    return (
        <div className="profile-buttons-container">

            {profile.key === viewingProfile.key ? (
                <>
                    {/* EDIT PROFILE */}
                    <ProfileButton
                        title="Edit Profile"
                        onClick={onEditClick}
                    />

                    {/* SHARE PROFILE */}
                    <ProfileButton
                        title="Share Profile"
                        onClick={onShareClick}
                    />

                    {/* ADD FRIENDS */}
                    <ProfileButton
                        icon={AddFriendIcon}
                        onClick={onAddFriendClick}
                    />
                </>
            ) : (
                <>
                    {/* FOLLOW/UNFOLLOW */}
                    {isFollowing ? (
                        <ProfileButton
                            title="Unfollow"
                            onClick={onUnfollowClick}
                            highlighted={false}
                            width="100px"
                        />
                    ) : (
                        <ProfileButton
                            title="Follow"
                            onClick={onFollowClick}
                            highlighted={true}
                            width="100px"
                        />
                    )}

                    {/* SHARE PROFILE */}
                    <ProfileButton
                        title="Share Profile"
                        onClick={onShareClick}
                    />
                </>
            )}


        </div>
    );
};

export default ProfileButtons;

