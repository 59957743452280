import { useEffect } from 'react';

// Firebase
import { collections } from '../../firebaseConfig';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load public items.
 */
const usePreloadPublicItems = (setPublicItems) => {

  useEffect(() => {

    const load = () => {

      const handleUpdate = (items) => {
        setPublicItems(items);
      };

      const params = [
        { field: "shared", operator: "==", value: true },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        null,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };

  }, [setPublicItems]);

};

export default usePreloadPublicItems;
