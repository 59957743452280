import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType } from '../../../../common/utilities/Enums';
import { generateKey } from '../../../../common/utilities/Keys';
import { defaultPost, getOptionsSummaryPhrase, addMediaToPost, handleUpload, handleSave } from '../../../../common/utilities/Posts';
import { addStream, deleteStream } from '../stream/PostStreamUtils';
import { getDisplayProfile } from '../../../../common/utilities/Profiles';

// Styles
import './PostWizard.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import EventEdit from '../../../../common/items/common/eventedit/EventEdit';
import FriendSelector from '../../../../common/components/friendselector/FriendSelector';
import LocationSelector from '../../../../common/components/locationselector/LocationSelector';
import Modal from '../../../../common/components/modal/Modal';
import PostOptionsPanel from './options/optionspanel/PostOptionsPanel';
import PostOptions from './options/PostOptions';
import PostWizardPreview from './preview/PostWizardPreview';
import SongEdit from '../../../../common/items/common/songedit/SongEdit';
import Uploader from '../../../components/uploader/Uploader';
import Wizard from '../../../../common/wizard/Wizard';
import WizardStep from '../../../../common/wizard/WizardStep';
import WizardStepContent from '../../../../common/wizard/WizardStepContent';
import WizardButtons from '../../../../common/wizard/WizardButtons';
import WizardButton from '../../../wizard/WizardButton';

// Hooks
import useInitializePost from './hooks/InitializePost';

/**
 * Handles the creation and management of posts.
 * 
 * @param {Object} item - The existing post item. Null if creating a new post.
 * @param {boolean} isOpen - Indicates if the modal is open.
 * @param {Function} setOpen - Function to set the modal open state.
 * @param {Function} onSave - Function to call when the post is saved.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const PostWizard = ({ item, isOpen, setOpen, onSave }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    hideProgress,
    profile,
    showProgress
  } = useContext(Global);

  // Local State

  // Post Being Edited
  const [post, setPost] = useState(defaultPost);

  // Wizard
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState(0); // 1 for next, -1 for prev

  // Summary Phrases
  const [privacyPhrase, setPrivacyPhrase] = useState("");
  const [contributePhrase, setContributePhrase] = useState("");
  const [commentsPhrase, setCommentsPhrase] = useState("");

  // Dialogs
  const [songEditOpen, setSongEditOpen] = useState(false);
  const [eventEditOpen, setEventEditOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);

  // Streaming
  const [streamViewerOpen, setStreamViewerOpen] = useState(false);
  const [stream, setStream] = useState(null);

  // Upload-Related State
  const [uploadReady, setUploadReady] = useState(false);
  const [uploadItemType, setUploadItemType] = useState(ItemType.PHOTO);
  const [uploadFileTypes, setUploadFileTypes] = useState("*");
  const [uploadItemSongTitle, setUploadItemSongTitle] = useState("");
  const [uploadItemSongArtist, setUploadItemSongArtist] = useState("");

  // References
  const uploaderRef = useRef(null);

  // Hooks
  useInitializePost({ item, defaultPost, profile, isOpen, setPost });

  // Resets to start if the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setStep(1);
      return;
    }
  }, [isOpen]);

  // Gathers summary information for the user
  useEffect(() => {
    setPrivacyPhrase(getOptionsSummaryPhrase(post, "privacy", true)); // hasPaywall=true for privacy
    setContributePhrase(getOptionsSummaryPhrase(post, "contribute"));
    setCommentsPhrase(getOptionsSummaryPhrase(post, "comments"));
  }, [post]);

  /**
   * Handles the change of a post property.
   * 
   * @param {string} key - The property key to change.
   * @param {any} value - The new value for the property.
   */
  const handleChange = (key, value) => {
    setPost((prev) => ({ ...prev, [key]: value }));
  };

  // Wizard Navigation
  const nextStep = () => {
    setDirection(1);
    setStep(step + 1);
  };

  const prevStep = () => {
    setDirection(-1);
    setStep(step - 1);
  };

  /**
   * Routes the user to the next action based on the item type selected.
   */
  const handleItemTypeSelect = (type) => {

    addMediaToPost(type, {
      showEventProperties,
      showSongProperties,
      setUploadItemType,
      setUploadFileTypes,
      setAddNoteOpen,
      setStreamViewerOpen,
      setStream,
      uploaderRef,
      profile,
      item,
      stream,
      streamViewerOpen,
      addStream,
      deleteStream
    });

  };

  /**
   * Displays the song properties modal.
   */
  const showSongProperties = () => {
    setSongEditOpen(true);
  };

  /**
   * Displays the event properties modal.
   */
  const showEventProperties = () => {
    setEventEditOpen(true);
  };

  /**
   * Handles the confirmation of song data from the song dialog.
   */
  const handleSongConfirmed = (song) => {

    handleChange("items", [...post.items, song]);




  };

  /**
   * Handles the confirmation of event data from the event dialog.
   */
  const handleEventConfirmed = (result) => {

    const { title, timespan } = result;

    // Convert JavaScript Dates to Firestore Timestamps
    const startTimestamp = Timestamp.fromDate(timespan.start);
    const endTimestamp = Timestamp.fromDate(timespan.end);

    // Create a new event item
    const data = {
      key: generateKey(),
      type: ItemType.EVENT,
      profileKey: profile.key,
      profile: getDisplayProfile(profile),
      title: title || '',
      startDate: startTimestamp,
      endDate: endTimestamp,
    }

    handleChange("items", [...post.items, data]);
  };

  /**
   * Handles the event fired after the user selects more files to include.
   */
  const handleSelectComplete = (localFilePaths) => {
    const newItems = localFilePaths.map((filePath) => ({
      key: generateKey(),
      type: uploadItemType,
      profileKey: profile.key,
      profile: getDisplayProfile(profile),
      url: filePath,
    }));

    // Song will be single selection
    if ((uploadItemType === ItemType.SONG) && localFilePaths.length > 0) {
      newItems[0].title = uploadItemSongTitle;
      newItems[0].artist = uploadItemSongArtist;
    }

    handleChange("items", [...post.items, ...newItems]);
  };

  return (
    <Modal
      title="Create a Post"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      width={isMobile ? "100%" : "400px"}
      height={isMobile ? "100%" : "700px"}>

      <Wizard step={step} direction={direction}>

        {/* STEP 1: POST OPTIONS */}
        <WizardStep step={1}>

          <WizardStepContent>

            <PostOptions
              post={post}
              handleChange={handleChange}
              onPrivacyClick={() => setStep(3)}
              onContributeClick={() => setStep(4)}
              onCommentsClick={() => setStep(5)}
              privacyPhrase={privacyPhrase}
              contributePhrase={contributePhrase}
              commentsPhrase={commentsPhrase}
            />

          </WizardStepContent>

          <WizardButtons>
            <WizardButton label="Next" onClick={nextStep} />
          </WizardButtons>

        </WizardStep>

        {/* STEP 2: PREVIEW */}
        <WizardStep step={2}>

          <WizardStepContent>

            <PostWizardPreview
              item={post}
              handleChange={handleChange}
              onItemTypeSelect={handleItemTypeSelect}
            />

          </WizardStepContent>

          <WizardButtons>
            <WizardButton label="Previous" onClick={prevStep} />
            <WizardButton label="Finish"
              enabled={post.items.length > 0}
              onClick={() => handleSave({
                post,
                profile,
                hideProgress,
                showProgress,
                setOpen,
                onSave,
              })} />
          </WizardButtons>

        </WizardStep>

        {/* STEP 3: PRIVACY OPTIONS */}
        <WizardStep step={3}>

          <WizardStepContent>

            <PostOptionsPanel
              post={post}
              handleChange={handleChange}
              setStep={setStep}
              title="Who can view this post"
              prefix="privacy"
              friendSelectorStep={6}
              locationSelectorStep={7}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 4: CONTRIBUTION OPTIONS */}
        <WizardStep step={4}>

          <WizardStepContent>

            <PostOptionsPanel
              post={post}
              handleChange={handleChange}
              setStep={setStep}
              title="Who can contribute media to this post"
              prefix="contribute"
              friendSelectorStep={8}
              locationSelectorStep={9}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 5: COMMENT OPTIONS */}
        <WizardStep step={5}>

          <WizardStepContent>

            <PostOptionsPanel
              post={post}
              handleChange={handleChange}
              setStep={setStep}
              title="Who can comment on this post"
              prefix="comments"
              friendSelectorStep={10}
              locationSelectorStep={11}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 6: PRIVACY USERS */}
        <WizardStep step={6}>

          <WizardStepContent>

            <FriendSelector
              post={post}
              handleChange={handleChange}
              prefix="privacy"
              title="Specify friends who can view this post"
              onBackClick={() => setStep(3)}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 7: PRIVACY LOCATIONS */}
        <WizardStep step={7}>

          <WizardStepContent>

            <LocationSelector
              post={post}
              handleChange={handleChange}
              prefix="privacy"
              title="Limit viewing to these specific locations"
            />

          </WizardStepContent>

          <WizardButtons>
            <WizardButton label="Continue" onClick={() => setStep(3)} />
          </WizardButtons>

        </WizardStep>

        {/* STEP 8: CONTRIBUTOR USERS */}
        <WizardStep step={8}>

          <WizardStepContent>

            <FriendSelector
              post={post}
              handleChange={handleChange}
              prefix="contribute"
              title="Specify friends who can contribute to this post"
              onBackClick={() => setStep(4)}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 9: CONTRIBUTOR LOCATIONS */}
        <WizardStep step={9}>

          <WizardStepContent>

            <LocationSelector
              post={post}
              handleChange={handleChange}
              prefix="contribute"
              title="Limit contributors to these specific locations"
            />

          </WizardStepContent>

          <WizardButtons>
            <WizardButton label="Continue" onClick={() => setStep(4)} />
          </WizardButtons>

        </WizardStep>

        {/* STEP 10: COMMENTS USERS */}
        <WizardStep step={10}>

          <WizardStepContent>

            <FriendSelector
              post={post}
              handleChange={handleChange}
              prefix="comments"
              title="Specify friends who can comment on this post"
              onBackClick={() => setStep(5)}
            />

          </WizardStepContent>

        </WizardStep>

        {/* STEP 11: COMMENTS LOCATIONS */}
        <WizardStep step={11}>

          <WizardStepContent>

            <LocationSelector
              post={post}
              handleChange={handleChange}
              prefix="comments"
              title="Limit comments to these specific locations"
            />

          </WizardStepContent>

          <WizardButtons>
            <WizardButton label="Continue" onClick={() => setStep(5)} />
          </WizardButtons>

        </WizardStep>

      </Wizard>

      {/* EVENT */}
      <EventEdit
        isOpen={eventEditOpen}
        setOpen={setEventEditOpen}
        onSave={handleEventConfirmed}
      />

      {/* SONG */}
      <SongEdit
        isOpen={songEditOpen}
        setOpen={setSongEditOpen}
        onSave={handleSongConfirmed}
      />

      {/* UPLOADER */}
      <Uploader
        ref={uploaderRef}
        accept={uploadFileTypes}
        selectionMode="multiple"
        folderPath="uploads"
        uploadReady={uploadReady}
        setUploadReady={setUploadReady}
        onSelectComplete={handleSelectComplete}
      />

    </Modal>
  );
};

export default PostWizard;