import { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Google API key
const GOOGLE_API_KEY = 'AIzaSyCwW0sA6X_2we_489QM--RJOyHqxlkwtmI';

const useLoadCityState = () => {

    // Global
    const {
        location,
        setLocation,
    } = useContext(Global);

    useEffect(() => {
        const fetchLocationGuess = async () => {
            try {
                // Using Google's Geolocation API with minimal data
                // When no cell tower/WiFi data is provided, it falls back to IP-based location
                const response = await fetch(
                    `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            considerIp: true // This tells the API to use IP as a fallback
                        })
                    }
                );

                if (!response.ok) {
                    throw new Error('Geolocation API request failed');
                }

                const geoData = await response.json();
                const { lat, lng } = geoData.location;

                // Now use the Geocoding API to convert lat/lng to city/state
                const geocodeResponse = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
                );

                if (!geocodeResponse.ok) {
                    throw new Error('Geocoding API request failed');
                }

                const geocodeData = await geocodeResponse.json();

                if (geocodeData.status === 'OK' && geocodeData.results.length > 0) {
                    const addressComponents = geocodeData.results[0].address_components;

                    let city = null;
                    let state = null;

                    // Extract city and state from address components
                    addressComponents.forEach(component => {
                        if (component.types.includes('locality')) {
                            city = component.long_name;
                        }
                        if (component.types.includes('administrative_area_level_1')) {
                            state = component.short_name; // short_name gives state abbreviation (e.g., "CA")
                        }
                    });

                    setLocation({
                        city,
                        state,
                        isLoading: false,
                        error: null
                    });
                } else {
                    throw new Error('No geocoding results found');
                }
            } catch (err) {
                setLocation({
                    city: null,
                    state: null,
                    isLoading: false,
                    error: err.message
                });
            }
        };

        fetchLocationGuess();
    }, [setLocation]); // Empty dependency array means it runs once on mount

    return location;
};

export default useLoadCityState;