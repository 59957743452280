import React from 'react';

// Styles
import './SongThumbItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import ThumbDelete from '../../../../../common/items/common/thumbdelete/ThumbDelete';

// Images
import SongIcon from '../../../../../common/svg/SongIcon';

/**
 * SongThumbItem
 * 
 * A grid item for displaying songs in a thumbnail.
 * 
 * @param {Object} item The event item.
 * @param {Function} onClick The click event handler.
 * 
 * @returns {JSX.Element} SongThumbItem component.
 */
const SongThumbItem = ({ item, onClick, onDelete }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="song-thumb-item-container"
            title={`${item.artist} - ${item.title}`}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}>

            {/* DELETE BUTTON */}
            <ThumbDelete 
                item={item} 
                onDelete={onDelete} 
            />

            {/* IMAGE/ICON */}
            <div className="song-thumb-item-icon-container"
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                }}>
                <SongIcon
                    color={theme.highlightBackgroundColor}
                    width="50"
                    height="50"
                />
            </div>

        </div>

    );
};

export default SongThumbItem;
