import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

/**
 * Custom hook to pan to a search result
 */
const usePanToSearchResult = ({ googleMapRef }) => {

  // Global
  const {
    mapSearchResult
  } = useContext(Global);

  useEffect(() => {
      if (!googleMapRef.current || !mapSearchResult || !window.google) return;
  
      const { geometry } = mapSearchResult;
      if (!geometry || !geometry.location) {
        return;
      }
  
      const location = new window.google.maps.LatLng(
        geometry.location.lat(),
        geometry.location.lng()
      );
  
      googleMapRef.current.setCenter(location);
      googleMapRef.current.setZoom(12);
  
    }, [mapSearchResult, googleMapRef]);

};

export default usePanToSearchResult;
