import React from 'react';

const AddEventIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M700-100v-120H580v-40h120v-120h40v120h120v40H740v120h-40ZM224.62-200q-27.62 0-46.12-18.5Q160-237 160-264.62v-430.76q0-27.62 18.5-46.12Q197-760 224.62-760h70.76v-89.23h43.08V-760h206.16v-89.23h40V-760h70.76q27.62 0 46.12 18.5Q720-723 720-695.38v228.61q-10-.69-20-.69t-20 .69v-68.61H200v270.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h266.92q0 10 .69 20t2.54 20H224.62ZM200-575.39h480v-119.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v119.99Zm0 0V-720-575.39Z" />
  </svg>

);

export default AddEventIcon;
