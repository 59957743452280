import React from 'react';

// Utilities
import { hexToRgba } from '../../../../common/utilities/Colors';

// Styles
import './Pin.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const Pin = ({ icon: IconComponent, pin, selected = false, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="pin-container"
            onClick={onClick}
            style={{
                color: theme.foregroundColor,
            }}>

            <div className="pin-left">

                {/* ICON */}
                <IconComponent
                    color={theme.highlightBackgroundColor}
                    width="25"
                    height="25"
                />

            </div>

            <div className="pin-right">

                {/* TITLE */}
                <div className="pin-title">
                    {pin.title}
                </div>

                {/* LOCATION */}
                {pin.state && pin.country &&
                    <div className="pin-description"
                        style={{
                            color: theme.foregroundColorFaded,
                        }}>
                        {pin.state}, {pin.country}
                    </div>
                }

                {/* RADIUS */}
                {pin.radius &&
                    <div className="pin-"
                        style={{
                            color: theme.foregroundColorFaded,
                        }}>
                        Radius: {pin.radius} miles
                    </div>
                }

                {/* HOME 
                {pin.isHome &&
                    <>
                        <div className="pin-home"
                            style={{
                                backgroundColor: hexToRgba(theme.highlightBackgroundColor, 0.5),
                                borderColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor,
                            }}>
                            MY AREA
                        </div>
                        <div className="pin-home-description"
                            style={{
                                color: theme.foregroundColorFaded,
                            }}>
                            This is where you post and share.
                        </div>
                    </>
                }
                */}

            </div>

        </div>

    );
};

export default Pin;
