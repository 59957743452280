import React from 'react';

// Utilities
import { ItemSize, ItemType } from '../utilities/Enums';

// Styles
import './Item.css';

// Components
import PostGridItem from './post/views/grid/PostGridItem';
import EventGridItem from './event/views/grid/EventGridItem';
import NoteGridItem from './note/views/grid/NoteGridItem';
import PhotoGridItem from './photos/views/grid/PhotoGridItem';
import SongGridItem from './song/views/grid/SongGridItem';
import StreamGridItem from './stream/views/grid/StreamGridItem';
import VideoGridItem from './video/views/grid/VideoGridItem';

import PostFeedItem from './post/views/feed/PostFeedItem';

import PostThumbItem from './post/views/thumb/PostThumbItem';
import EventThumbItem from './event/views/thumb/EventThumbItem';
import NoteThumbItem from './note/views/thumb/NoteThumbItem';
import PhotoThumbItem from './photos/views/thumb/PhotoThumbItem';
import SongThumbItem from './song/views/thumb/SongThumbItem';
import VideoThumbItem from './video/views/thumb/VideoThumbItem';

const COMPONENTS_GRID = {
    [ItemType.POST]: PostGridItem,
    [ItemType.PHOTO]: PhotoGridItem,
    [ItemType.VIDEO]: VideoGridItem,
    [ItemType.EVENT]: EventGridItem,
    [ItemType.NOTE]: NoteGridItem,
    [ItemType.SONG]: SongGridItem,
    [ItemType.STREAM]: StreamGridItem,
};

const COMPONENTS_THUMB = {
    [ItemType.POST]: PostThumbItem,
    [ItemType.EVENT]: EventThumbItem,
    [ItemType.NOTE]: NoteThumbItem,
    [ItemType.PHOTO]: PhotoThumbItem,
    [ItemType.SONG]: SongThumbItem,
    [ItemType.VIDEO]: VideoThumbItem,
};

/**
 * Item
 * 
 * Item that represents a folder, file, event, note, or object.
 * 
 * @param {Object} item - The item to render.
 * @param {Array} pathKeys - The keys of the items in the path.
 * @param {Boolean} selected - Whether the item is selected.
 * @param {Function} onClick - Function to call when the item is clicked.
 * @param {Function} onDoubleClick - Function to call when the item is double-clicked.
 * @param {Function} onDragStart - Function to call when the item is dragged.
 * @param {Function} onDropItem - Function to call when an item is dropped on the item.
 * @param {String} size - The size of the item.
 * 
 * @returns {Component} The folder item.
 */
const Item = ({ item, pathKeys, selected = false, showAuthor = false, onClick, onDoubleClick, onDragStart, onDropItem, onDelete, size = ItemSize.SMALL, borderRadius = '17px' }) => {
    
    let Component;

    switch (size) {
        case ItemSize.THUMB:
            Component = COMPONENTS_THUMB[item.type];
            break;
        case ItemSize.LARGE:
            Component = PostFeedItem;
            break;
        default:
            Component = COMPONENTS_GRID[item.type];
    }

    if (!Component) return null; // Handle undefined item types gracefully

    return (
        <Component
            key={item.key}
            item={item}
            pathKeys={pathKeys}
            selected={selected}
            showAuthor={showAuthor}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onDragStart={onDragStart}
            onDropItem={item.type === ItemType.FOLDER ? onDropItem : undefined} // Only pass onDropItem for folders
            onDelete={onDelete}
            size={size}
            borderRadius={borderRadius}
        />
    );
};

export default Item;
