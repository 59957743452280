import React, { useEffect, useState } from 'react';

// Styles
import './EventEdit.css';

// Components
import Divider from '../../../components/divider/Divider';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import PostDates from '../../post/fields/dates/PostDates';

const EventEdit = ({
  isOpen,
  setOpen,
  onSave,
}) => {

  // Local State
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);

  // Initialize default start and end times when modal opens
  useEffect(() => {
    if (isOpen) {
      const now = new Date();
      // Round up to the next full hour
      const start = new Date(now);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      start.setHours(start.getHours() + 1); // Next hour
      const end = new Date(start);
      end.setHours(end.getHours() + 1); // One hour later

      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDateTime(start);
      setEndDateTime(end);
    }
  }, [isOpen]);

  // Handlers for PostDates events
  const handleStartDateChange = (newStartDate) => {
    setStartDateTime(newStartDate);
  };

  const handleStartTimeChange = (newStartTime) => {
    setStartDateTime(newStartTime);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDateTime(newEndDate);
  };

  const handleEndTimeChange = (newEndTime) => {
    setEndDateTime(newEndTime);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  const handleSaveClick = () => {
    if (!startDateTime || !endDateTime) {
      console.error('Start or end date/time is missing');
      return;
    }

    // Create the timespan object to send to the parent
    const timespan = {
      start: startDateTime,
      end: endDateTime,
    };

    setOpen(false);

    const data = {
      timespan,
    };

    onSave(data);
  };

  return (
    <Modal
      title="Event Dates and Times"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      height="auto"
      width={400}
    >
      <div className="event-edit-container">

        {/* DIVIDER */}
        <Divider
          margin="14px"
        />

        {/* DATES AND TIMES */}
        <PostDates
          defaultStart={defaultStart}
          defaultEnd={defaultEnd}
          onStartDateChange={handleStartDateChange}
          onStartTimeChange={handleStartTimeChange}
          onEndDateChange={handleEndDateChange}
          onEndTimeChange={handleEndTimeChange}
        />

      </div>

      {/* BUTTONS */}
      <ModalButtons>
        <ModalButton onClick={handleCancelClick} label="Cancel" />
        <ModalButton onClick={handleSaveClick} label="Continue" highlighted={true} />
      </ModalButtons>
    </Modal>
  );
};

export default EventEdit;