import React from 'react';

const AddVideoIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M419.23-480ZM203.85-200q-27.62 0-46.12-18.5-18.5-18.5-18.5-46.01v-200.11h40v200q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h430.77q10.76 0 17.69-6.92 6.92-6.93 6.92-17.7v-430.76q0-10.77-6.92-17.7-6.93-6.92-17.69-6.92h-200v-40h200.1q27.51 0 46.01 18.5 18.5 18.5 18.5 46.12v186.15l121.54-121.54v301.54L699.23-450.77v186.15q0 27.62-18.5 46.12-18.5 18.5-46.11 18.5H203.85Zm41.53-130.77h347.7L483.46-476.92 382.31-349.23l-64.23-77-72.7 95.46ZM219.23-560v-80h-80v-40h80v-80h40v80h80v40h-80v80h-40Z" />
  </svg>

);

export default AddVideoIcon;
