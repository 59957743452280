import { useMemo } from 'react';

/**
 * Custom hook to generate Google Maps style based on the current theme.
 * @param {Object} theme - The theme object containing color definitions.
 * @returns {Array} - The Google Maps style array.
 */
const useMapStyle = (theme) => {

  return useMemo(
    () => [
      { elementType: "geometry", stylers: [{ color: theme.backgroundColor }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#000" }] },
      { elementType: "labels.text.fill", stylers: [{ color: theme.foregroundColorFaded }] },
      {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [{ color: theme.foregroundColorFaded }],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
          { color: theme.foregroundColorFaded },
          { weight: 0.5 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
          { color: theme.foregroundColorFaded },
          { weight: 0.5 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: theme.foregroundColor }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: theme.backgroundColorFaded }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
    [theme] // ⚡ Recompute only when theme changes
  );
};

export default useMapStyle;
