import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { SectionType } from '../common/utilities/Enums';

// Styles
import './Mobile.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Calendar from '../common/sections/calendar/Calendar';
import FooterMenu from '../common/footermenu/FooterMenu';
import Home from '../common/sections/home/Home';
import Friends from '../common/sections/friends/Friends';
import Inbox from '../common/sections/inbox/Inbox';
import Settings from '../common/sections/settings/Settings';

const Mobile = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    currentSection,
  } = useContext(Global);

  const renderContent = () => {
    if (currentSection === SectionType.HOME) return <Home />;
    if (currentSection === SectionType.CALENDAR) return <Calendar />;
    if (currentSection === SectionType.INBOX) return <Inbox />;
    if (currentSection === SectionType.FRIENDS) return <Friends />;
    if (currentSection === SectionType.SETTINGS) return <Settings />;

    return null;
  };

  return (
    <>
      <div
        className="mobile-container"
        style={{
          backgroundColor: theme.backgroundColor,
        }}>
        <div className="mobile-feed">
          {renderContent()}
        </div>

        {/* FOOTER MENU */}
        <FooterMenu />

      </div>
    </>
  );
};

export default Mobile;
