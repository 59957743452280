import React from 'react';

const BookmarkFilledIcon = ({ color = "white", width = "16", height = "16" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M240-180v-555.38q0-27.62 18.5-46.12Q277-800 304.62-800h350.76q27.62 0 46.12 18.5Q720-763 720-735.38V-180L480-283.08 240-180Z" />
    </svg>

);

export default BookmarkFilledIcon;
