import React from 'react';

// Styles
import './Confirm.css';

// Components
import Modal from '../modal/Modal';
import ModalButton from '../modal/buttons/ModalButton';
import ModalButtons from '../modal/buttons/ModalButtons';
import ModalMessage from '../modal/message/ModalMessage';

const Confirm = ({ title, message, isOpen, setOpen, onConfirm }) => {

  return (
    <>
      {/* MODAL */}
      <Modal title={title}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        height={"auto"}
        width={400}>

        {/* CONFIRM MESSAGE */}
        <ModalMessage text={message} />

        {/* BUTTONS */}
        <ModalButtons>

          {/* CONFIRM BUTTON */}
          <ModalButton
            onClick={onConfirm}
            label="Confirm"
          />

        </ModalButtons>

      </Modal>
    </>
  );
};

export default Confirm;
