import React, { useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { hexToRgba } from '../../../../../../common/utilities/Colors.js';
import { ThemeMode } from '../../../../../../common/utilities/Enums.js';

// Styles
import './ContributorBadge.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import GiftIcon from '../../../../../../common/svg/GiftIcon.jsx';

// Components
import ProfileDot from '../../../../../../common/components/profiledot/ProfileDot';
import ProfileModal from '../../../../../../common/components/profilemodal/ProfileModal';

const ContributorBadge = ({ contribution }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Local State
    const [profileModalOpen, setProfileModalOpen] = useState(false);

    const handleProfileClick = (event) => {
        console.log('Profile Clicked');
        event.stopPropagation();
        setProfileModalOpen(true);
    };

    return (
        <>
        <div className="contributor-badge-container"
            style={{
                backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.3),
                top: isMobile ? '100px' : '50px',
            }}>

            {/* LEFT */}
            <div className="contributor-badge-left"
                onClick={(event) => handleProfileClick(event)}>

                {/* PROFILE DOT */}
                <ProfileDot
                    item={contribution.contributorProfile}
                    size={34}
                />

            </div>

            {/* CENTER */}
            <div className="contributor-badge-center"
                onClick={handleProfileClick}>

                {/* CENTER TOP */}
                <div className="contributor-badge-center-top"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    Contributed by
                </div>

                {/* CENTER BOTTOM */}
                <div className="contributor-badge-center-bottom"
                    style={{
                        color: theme.foregroundColor,
                    }}>
                    {contribution.contributorProfile.username}
                </div>

            </div>

            {/* RIGHT */}
            <div className="contributor-badge-right">
                <div className="contributor-badge-gift-button"
                    style={{
                        backgroundColor: theme.highlightBackgroundColor,
                    }}>
                    <GiftIcon
                        color={theme.highlightForegroundColor}
                        width="14"
                        height="14"
                    />
                    <div className="contributor-badge-gift-button-label" style={{
                        color: theme.highlightForegroundColor,
                    }}>
                        Gift
                    </div>
                </div>
            </div>
        </div>

{/* PROFILE */}
<ProfileModal 
  profile={contribution.contributorProfile} 
  isOpen={profileModalOpen} 
  setOpen={setProfileModalOpen} 
/>
        </>
    );
};

export default ContributorBadge;
