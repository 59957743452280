import React from 'react';

// Framer Motion
import { motion } from 'framer-motion';

// Styles
import './WizardStep.css';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 100 : -100,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 100 : -100,
    opacity: 0,
  }),
};

const WizardStep = ({ step, currentStep, direction, children }) => {
  if (currentStep !== step) return null;

  return (
    <motion.div
      key={`step${step}`}
      className="wizard-step-container"
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

export default WizardStep;