import { useEffect } from 'react';

/**
 * Custom hook to load google maps.
 */
const useLoadGoogleMaps = ({ initializeMap }) => {

  useEffect(() => {

    // Google Maps API key
    const GOOGLE_MAPS_API_KEY = 'AIzaSyCwW0sA6X_2we_489QM--RJOyHqxlkwtmI';

    const loadGoogleMapsScript = () => {
      if (window.google) return initializeMap();

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = initializeMap;
      document.body.appendChild(script);
    };

    loadGoogleMapsScript();

  }, [initializeMap]);

};

export default useLoadGoogleMaps;
