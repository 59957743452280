import React from 'react';

// Styles
import './PhotoThumbItem.css';

// Components
import ThumbDelete from '../../../common/thumbdelete/ThumbDelete';

/**
 * PhotoThumbItem
 * 
 * A grid item for displaying files.
 * 
 * @param {Object} item The file item.
 * @param {Function} onClick The click event handler.
 * 
 * @returns {JSX.Element} PhotoThumbItem component.
 */
const PhotoThumbItem = ({ item, onClick, onDelete }) => {

    return (

        <div className="photo-thumb-item-container"
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            title={item.title}>

            {/* DELETE BUTTON */}
            <ThumbDelete 
                item={item} 
                onDelete={onDelete} 
            />

            <div className="photo-thumb-item-image-wrapper">
                <img
                    src={item.url}
                    alt={item.title}
                    className="photo-thumb-item-image"
                />
            </div>

        </div>
    );
};

export default PhotoThumbItem;
