import React, { useEffect, useRef, useState } from 'react';

// Utilities
import { hexToRgba } from '../../../../utilities/Colors';

// Styles
import './StreamGridItem.css';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';

/**
 * StreamGridItem
 * 
 * A grid item for displaying streams.
 * 
 * @param {Object} item The stream item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click stream handler.
 * @param {Function} onDoubleClick The double click stream handler.
 * @param {Function} onDragStart The drag start stream handler.
 * 
 * @returns {JSX.Element} StreamGridItem component.
 */
const StreamGridItem = ({ item, selected = false, onClick }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        // Convert Firebase Timestamp to JavaScript Date object
        const startTime = item.dateCreated.toDate();

        // Calculate the initial elapsed time
        const updateElapsedTime = () => {
            const now = new Date();
            const elapsedSeconds = Math.floor((now - startTime) / 1000);
            setElapsedTime(elapsedSeconds);
        };

        // Update the elapsed time immediately
        updateElapsedTime();

        // Set up an interval to update the elapsed time every second
        intervalRef.current = setInterval(updateElapsedTime, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalRef.current);
    }, [item.dateCreated]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0');
        const seconds = (time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}>

            <div className="stream-grid-item-container"
                title={item.title}>

                {/* PREVIEW */}
                <div className="stream-grid-item-image-wrapper">
                    <img
                        src={item.profile.photo}
                        alt={item.title}
                        className="stream-grid-item-image"
                    />
                </div>

                <div className="stream-grid-item-footer"
                    style={{
                        backgroundColor: hexToRgba("black", 0.5),
                    }}>

                    <div className="stream-grid-item-title">
                        {item.profile.username}
                    </div>

                    <div className="stream-grid-item-timer"
                        style={{
                            fontFamily: "Inconsolata",
                        }}>
                        {formatTime(elapsedTime)}
                    </div>

                </div>

                <div className="stream-grid-item-status-dot"
                    style={{
                        backgroundColor: "green",
                    }}></div>

            </div>

        </GridItem>
    );
};

export default StreamGridItem;