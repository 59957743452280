import React from 'react';

const ShareIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M664.43-120q-39.81 0-67.51-27.82-27.69-27.82-27.69-67.56 0-6 5.31-30.31L286.62-416.62q-12.93 15-31.72 23.5-18.8 8.5-40.28 8.5-39.43 0-67.02-28.07Q120-440.77 120-480q0-39.23 27.6-67.31 27.59-28.07 67.02-28.07 21.48 0 40.28 8.5 18.79 8.5 31.72 23.5l287.92-170.16q-2.77-7.77-4.04-15.42-1.27-7.66-1.27-15.66 0-39.74 27.87-67.56Q624.98-840 664.8-840q39.82 0 67.51 27.87Q760-784.25 760-744.43q0 39.81-27.82 67.51-27.82 27.69-67.56 27.69-21.7 0-40-8.89Q606.31-667 593.38-682L305.46-511.08q2.77 7.77 4.04 15.43 1.27 7.65 1.27 15.65t-1.27 15.65q-1.27 7.66-4.04 15.43L593.38-278q12.93-15 31.24-23.88 18.3-8.89 40-8.89 39.74 0 67.56 27.87Q760-255.02 760-215.2q0 39.82-27.87 67.51Q704.25-120 664.43-120Z" />
    </svg>

);

export default ShareIcon;
