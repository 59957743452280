import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

// Firebase
import { uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebaseConfig';

const Uploader = forwardRef(
    (
        {
            accept = "*",
            selectionMode = "multiple",
            folderPath,
            uploadReady = false,
            setUploadReady,
            onSelectComplete,
            onUploadStart,
            onUploadComplete,
        },
        ref
    ) => {
        const fileInputRef = useRef(null);
        const selectedFilesRef = useRef([]);

        // Expose methods to the parent via ref
        useImperativeHandle(ref, () => ({
            click: () => fileInputRef.current?.click(),
            reset: () => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Reset the input value
                }
                selectedFilesRef.current = []; // Clear the stored files
            },
        }));

        const handleFileSelect = (event) => {
            const files = event.target.files;
            if (!files || files.length === 0) return;

            selectedFilesRef.current = Array.from(files);
            const localFilePaths = selectedFilesRef.current.map((file) =>
                URL.createObjectURL(file)
            );

            if (onSelectComplete) {
                onSelectComplete(localFilePaths);
            }

            if (uploadReady) {
                handleUpload();
            }
        };

        const handleUpload = useCallback(async () => {
            if (selectedFilesRef.current.length === 0) return;

            if (onUploadStart) {
                onUploadStart();
            }

            const uploadedUrls = [];
            try {
                for (const file of selectedFilesRef.current) {
                    const filePath = `${folderPath}/${Date.now()}_${file.name}`;
                    const storageRef = ref(storage, filePath);

                    await uploadBytes(storageRef, file);
                    const fileUrl = await getDownloadURL(storageRef);
                    uploadedUrls.push(fileUrl);
                }

                if (onUploadComplete) {
                    onUploadComplete(uploadedUrls);
                }

                if (setUploadReady) {
                    setUploadReady(false);
                }
            } catch (error) {
                console.error("Error uploading files:", error);
            }
        }, [onUploadStart, onUploadComplete, setUploadReady, folderPath, selectedFilesRef, ref]);

        useEffect(() => {
            if (uploadReady) {
                handleUpload();
            }
        }, [uploadReady, handleUpload]);

        return (
            <input
                ref={fileInputRef}
                type="file"
                accept={accept}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                multiple={selectionMode === "multiple"}
                aria-label="File Upload"
            />
        );
    }
);

export default Uploader;