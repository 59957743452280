import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { EngagementType } from '../../../../common/utilities/Enums';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load engagements for a specific item.
 */
const usePreloadItemEngagements = (item) => {

  // Global
  const {
    profile,
    setBookmarks,
    setComments,
    setCommentLikes,
    setCommentDislikes,
    setLikes,
    setShares,
  } = useContext(Global);

  useEffect(() => {

    if (!profile || !item || !item.key) {
      setComments([]);
      setLikes([]);
      return;
    }

    const load = () => {

      const handleUpdate = (engagements) => {
        // Filter engagements for comments, and sort by date
        const comments = engagements.filter(engagement => 
          (engagement.type === EngagementType.POST_COMMENT));
        const sorted = comments.sort((a, b) => a.dateCreated.toDate() - b.dateCreated.toDate());
        setComments(sorted);

        // Set comment likes and dislikes
        const commentLikes = engagements.filter(engagement => engagement.type === EngagementType.COMMENT_LIKE);
        setCommentLikes(commentLikes);
        const commentDislikes = engagements.filter(engagement => engagement.type === EngagementType.COMMENT_DISLIKE);
        setCommentDislikes(commentDislikes);

        // Set likes
        const likes = engagements.filter(engagement => engagement.type === EngagementType.POST_LIKE);
        setLikes(likes);

        // Set bookmarks
        const bookmarks = engagements.filter(engagement => engagement.type === EngagementType.POST_BOOKMARK);
        setBookmarks(bookmarks);

        // Set shares
        const shares = engagements.filter(engagement => engagement.type === EngagementType.POST_SHARE);
        setShares(shares);

      };

      const params = [
        { field: "itemKey", operator: "==", value: item.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.engagements,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };

  }, [item, setComments, setCommentLikes, setCommentDislikes, setLikes, profile, setBookmarks, setShares]);

};

export default usePreloadItemEngagements;
