import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { HomeToolbarKey, SectionType } from '../../utilities/Enums';

// Styles
import './FooterMenuAddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../../common/svg/PlusIcon';

// Components
import PostSuccessful from '../../../common/components/postsuccessful/PostSuccessful';
import PostWizard from '../../items/post/wizard/PostWizard';

const FooterMenuAddButton = ({ title = '' }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        setCurrentSection,
        setSelectedHomeToolbarKey,
    } = useContext(Global);

    // Local State
    const [addPostOpen, setAddPostOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [postSuccessfulOpen, setPostSuccessfulOpen] = useState(false);

    const handleAddPostClick = () => {
        setAddPostOpen(true);
    };

    const handleSaveComplete = (item) => {

        // Navigate to the home section
        setCurrentSection(SectionType.HOME);

        // Set the current home tab to FORYOU
        setSelectedHomeToolbarKey(HomeToolbarKey.FORYOU);

        setSelectedItem(item);

        setPostSuccessfulOpen(true);
    };

    return (
        <>
            <div className="home-menu-add-button-container"
                onClick={handleAddPostClick}
                style={{
                }}
                title={title}>
                <PlusIcon
                    color={theme.highlightForegroundColor}
                    width="28px"
                    height="28px"
                />
            </div>

            {/* POST WIZARD */}
            <PostWizard
                isOpen={addPostOpen}
                setOpen={setAddPostOpen}
                onSave={handleSaveComplete}
            />

            {/* POST SUCCESSFUL */}
            <PostSuccessful
                item={selectedItem}
                isOpen={postSuccessfulOpen}
                setOpen={setPostSuccessfulOpen}
            />
        </>
    );
};

export default FooterMenuAddButton;
