import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { HomeToolbarKey } from '../../../common/utilities/Enums';

// Styles
import './Home.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import EditIcon from '../../../common/svg/EditIcon';

// Components
import CommentsPanel from './comments/panel/CommentsPanel';
import CommentsPopup from './comments/popup/CommentsPopup';
import Divider from '../../../common/components/divider/Divider.js';
import HomeToolbar from './toolbar/HomeToolbar';
import ItemButtons from './item/buttons/ItemButtons';
import PostFeedItem from '../../../common/items/post/views/feed/PostFeedItem';
import Section from '../../components/section/Section';

// Preload Hooks
import usePreloadHomeItems from './hooks/PreloadHomeItems.js';
import usePreloadItemEngagements from './hooks/PreloadItemEngagements.js';

const Home = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    comments,
    currentItem,
    homeItems,
    overlayVisible,
    profile,
    selectedHomeToolbarKey,
    setCurrentItem,
    setHomeItems,
  } = useContext(Global);

  // Local State
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [displayItems, setDisplayItems] = useState([]);
  const [creatorMenuOpen, setCreatorMenuOpen] = useState(false);

  // Hooks
  usePreloadHomeItems(profile, setHomeItems);
  usePreloadItemEngagements(currentItem);

  // Hook to separate out past items from future items
  useEffect(() => {
    const now = new Date();

    switch (selectedHomeToolbarKey) {
      case HomeToolbarKey.UPCOMING:
        // First get only event items, then filter by date
        const upcomingItems = homeItems
          .filter(item => item.type === "EVENT") // Only events
          .filter(item => {
            const endDate = item.endDate.toDate();
            return endDate >= now;
          });
        setDisplayItems(upcomingItems);
        break;

      case HomeToolbarKey.RECENT:
        // First get only event items, then filter by date
        const recentItems = homeItems
          .filter(item => item.type === "EVENT") // Only events
          .filter(item => {
            const endDate = item.endDate.toDate();
            return endDate < now;
          });
        setDisplayItems(recentItems);
        break;

      case HomeToolbarKey.FORYOU:
        // Show upcoming events and non-event items
        const forYouItems = homeItems.filter(item => {
          if (item.type !== "EVENT") {
            return true; // Include all non-event items
          }
          // For events, only include upcoming ones
          const endDate = item.endDate.toDate();
          return endDate >= now;
        });
        setDisplayItems(forYouItems);
        break;

      default:
        // Fallback to FORYOU logic
        const defaultItems = homeItems.filter(item => {
          if (item.type !== "EVENT") {
            return true;
          }
          const endDate = item.endDate.toDate();
          return endDate >= now;
        });
        setDisplayItems(defaultItems);
    }
  }, [homeItems, selectedHomeToolbarKey]);

  const handleCommentButtonClick = (item) => {
    setCurrentItem(item);
    setCommentsVisible(!commentsVisible);
  };

  const handleCommentsClose = () => {
    setCommentsVisible(false);
  };

  const handleMenuClick = () => {
    setCreatorMenuOpen(!creatorMenuOpen);
  };

  return (

    <Section>

      {/* CONTAINER */}
      <div className="home-container">

        <div className="home-items"
          style={{
            width: isMobile ? '100%' : 'auto',
          }}>

          {/* TOOLBAR */}
          <HomeToolbar />

          {/* SCROLLING CONTENT */}
          <div className="home-scroll-container"
            style={{
              gap: isMobile ? '0px' : '20px',
            }}>

            {displayItems.map((item) => (

              <div key={item.key} className="home-item"
                style={{
                  borderRadius: isMobile ? '0px' : '17px',
                  height: isMobile ? 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 46px)' : '92vh',
                  width: isMobile ? '100%' : '520px',
                }}>

                <div key={item.key} className="home-item-wrapper"
                  style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderRadius: isMobile ? '0px' : '17px',
                    width: isMobile ? '100%' : '460px',
                  }}>
                  <PostFeedItem
                    key={item.key}
                    item={item}
                    creatorMenuOpen={creatorMenuOpen}
                    setCreatorMenuOpen={setCreatorMenuOpen}
                  />
                </div>

                {/* ITEM BUTTONS */}
                {(overlayVisible || !isMobile) &&
                  <div className="home-item-buttons"
                    style={{
                      marginTop: isMobile ? 'auto' : 'auto',
                      marginBottom: isMobile ? 'auto' : 'auto',
                      position: isMobile ? 'absolute' : 'relative',
                      right: isMobile ? '4px' : '0px',
                    }}>

                    <ItemButtons
                      item={item}
                      onCommentButtonClick={() => handleCommentButtonClick(item)}
                      onMenuButtonClick={handleMenuClick}
                    />

                  </div>
                }

              </div>

            ))}

          </div>

          {/* COMMENTS POPUP (MOBILE) */}
          {isMobile &&

            <CommentsPopup
              isOpen={commentsVisible}
              comments={comments}
              onClose={handleCommentsClose}
              setOpen={setCommentsVisible}
            />

          }

        </div>

        {/* COMMENTS PANEL */}
        {!isMobile &&

          <CommentsPanel
            commentsVisible={commentsVisible}
            comments={comments}
            onClose={handleCommentsClose}
          />

        }

      </div>

    </Section>

  );
};

export default Home;
