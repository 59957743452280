/**
 * Returns an object which summarizes profile information for display purposes.
 */
export function getDisplayProfile(profile) {
    return {
        key: profile.key,
        username: profile.username,
        photo: profile.photo || null,
        followerCount: profile.followerCount,
        followingCount: profile.followingCount,
    };
}

/**
 * Function to get mutual friends
 */
export function getMutualFriends(following, followers) {
    // If either list is empty or undefined, return empty array
    if (!following?.length || !followers?.length) {
        return [];
    }

    // Get the keys of profiles the user is following
    const followingKeys = following.map(f => f.followingKey);

    // Get the keys of profiles following the user
    const followerKeys = followers.map(f => f.followerKey);

    // Find mutual connections (keys present in both arrays)
    const mutualKeys = followingKeys.filter(key => followerKeys.includes(key));

    // Map mutual keys to their profile information from profileFollowing
    const mutualFriends = mutualKeys.map(key => {
        const followingRecord = following.find(f => f.followingKey === key);
        return {
            key: key,
            username: followingRecord.followingProfile.username,
            photo: followingRecord.followingProfile.photo
        };
    });

    return mutualFriends;
}

export function getUsersPhraseWithUsernames(usernames, defaultPhrase, profile) {

    // Self-referenced notifications
    if (profile && usernames.length === 1 &&
        usernames.includes(profile.username)) {
        return `You`;
    }
    
    // Handle different cases based on array length
    if (usernames.length === 0 && defaultPhrase) return defaultPhrase;
    if (usernames.length === 1) return `${usernames[0]}`;
    if (usernames.length === 2) return `${usernames[0]} and ${usernames[1]}`;
    if (usernames.length === 3) return `${usernames[0]}, ${usernames[1]} and ${usernames[2]}`;
    return `${usernames[0]}, ${usernames[1]} and ${usernames.length - 2} others`;
}

export function getUsersPhrase(users, defaultPhrase, profile) {

    // Extract usernames from the users array
    const usernames = users.map(user => {
        if (user.username) return user.username;
        if (user.followingProfile?.username) return user.followingProfile.username;
        return user.key;
    });
    
    return getUsersPhraseWithUsernames(usernames, defaultPhrase, profile);
}
