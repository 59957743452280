import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global.js';

// Utilities
import { getMutualFriends } from '../../../common/utilities/Profiles.js';

// Styles
import './FriendSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Images
import CheckOnIcon from '../../../common/svg/CheckOnIcon.jsx';
import LeftIcon from '../../../common/svg/LeftIcon.jsx';

// Components
import ProfileItem from '../profileitem/ProfileItem.js';

const FriendSelector = ({ title, post, handleChange, prefix, onBackClick }) => {
  
  // Theme
  const { theme } = useTheme();

  // Global
  const { 
    profileFollowing, 
    profileFollowers 
  } = useContext(Global);

  // Get mutual friends list
  const mutualFriends = getMutualFriends(profileFollowing, profileFollowers);

  // Use prefixed key from post (e.g., "privacySelectedFriends")
  const selectedFriends = post[`${prefix}SelectedFriends`];

  const handleProfileClick = (friend) => {
    const isSelected = selectedFriends.some(user => user.key === friend.key);

    if (!isSelected) {
      // Add friend to the selected list
      handleChange(`${prefix}SelectedFriends`, [...selectedFriends, friend]);
    } else {
      // Remove friend from the selected list
      handleChange(
        `${prefix}SelectedFriends`,
        selectedFriends.filter(user => user.key !== friend.key)
      );
    }
  };

  return (
    <div className="friend-selector-container">

      {/* TITLE */}
      <div
        onClick={onBackClick}
        className="friend-selector-list-title"
        style={{ color: theme.foregroundColor }}>
        <LeftIcon color={theme.foregroundColor} width="18" height="18" />
        {title}
      </div>

      {/* LIST */}
      <div className="friend-selector-list">
        {mutualFriends.map(friend => (
          <div className="friend-selector-item" key={friend.key}>
            {/* INFO */}
            <div className="friend-selector-item-name">
              <ProfileItem item={friend} onClick={() => handleProfileClick(friend)} />
            </div>

            {/* CHECK */}
            <div className="friend-selector-item-checkbox">
              {selectedFriends.some(user => user.key === friend.key) && (
                <CheckOnIcon
                  color={theme.highlightBackgroundColor}
                  width="20"
                  height="20"
                  checkColor={theme.highlightForegroundColor}
                />
              )}
            </div>
          </div>
        ))}

        {/* NO FRIENDS MESSAGE */}
        {mutualFriends.length === 0 && (
          <div
            style={{
              color: theme.foregroundColorFaded,
              padding: '10px',
            }}>
            No friends found
          </div>
        )}
      </div>
    </div>
  );
};

export default FriendSelector;