// Firebase
import { collections } from '../../../../firebaseConfig';
import { Timestamp } from 'firebase/firestore';

// Utilities
import { ItemType } from '../../../utilities/Enums';
import { getDisplayProfile } from '../../../utilities/Profiles.js';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

export const handleRemoveItems = async ({
  item,
  tempItems,
  combinedItems,
  selectedItems,
  setTempItems,
  setSelectedItems,
  setContributionsRemoved,
  profile,
}) => {

  if (item) {

    // Remove selected items from the item.items array
    const updatedItems = combinedItems.filter((item) => !selectedItems.some((selected) => selected.key === item.key));

    // Update the event in the database
    const data = {
      items: updatedItems,
    };

    // Save the event in the database
    try {
      await dataManager.update(collections.items, profile.key, item.key, data);
    } catch (error) {
      console.error(`Error saving event:`, error);
    }

    // Loop through the selected items and delete them from the database
    for (const selectedItem of selectedItems) {
      // Delete the item from the database
      await dataManager.delete(collections.items, profile.key, selectedItem.key);
    }

    // Clear selected items
    setSelectedItems([]);

  } else {

    // Remove selected items from the local items array
    const updatedItems = tempItems.filter((item) => !selectedItems.some((selected) => selected.key === item.key));
    setTempItems(updatedItems);

    // Clear selected items
    setSelectedItems([]);

  }
};

export const handleContribute = async ({
  handleUpload,
  hideProgress,
  item,
  onSave,
  setOpen,
  profile,
  showProgress,
}) => {
  showProgress("Contributing...");

  // Close the form
  setOpen(false);

  const updatedItems = await handleUpload();

  const data = {
    items: updatedItems,
  };

  // Update the event in the database
  try {

    await dataManager.update(collections.items, profile.key, item.key, data);

    // Notify the parent component (if applicable)
    if (onSave) {
      onSave(data);
    }

  } catch (error) {
    console.error(`Error saving event:`, error);
  }

  hideProgress();
};

/**
 * Method to handle the removal of an event.
 */
export const handleDelete = async ({
  item,
  setOpen,
  profile,
}) => {
  // Delete event copies referencing this event. Use a null item key
  // and pass in the referenceEventKey as a parameter
  const params = [
    { field: 'referenceEventKey', operator: '==', value: item.key },
  ];
  await dataManager.delete(collections.items, profile.key, null, params);

  // Delete the Event
  await dataManager.delete(collections.items, profile.key, item.key);

  setOpen(false);
};

export const handleSave = async ({
  item,
  profile,
  hideProgress,
  showProgress,
  title,
  setTitle,
  startDate,
  startTime,
  endDate,
  endTime,
  location,
  generatedKey,
  setOpen,
  onSave,
}) => {

  showProgress("Posting...");

  // Close the event form
  setOpen(false);

  // Validate title
  if (title.trim() === '') {
    setTitle('(No title)');
  }

  // Prepare event data
  const start = new Date(startDate);
  start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
  const end = new Date(endDate);
  end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

  const key = item ? item.key : generatedKey;

  const startTimestamp = Timestamp.fromDate(start);
  const endTimestamp = Timestamp.fromDate(end);

  // Profile display info

  const data = {
    key: key,
    profileKey: profile.key,
    profile: getDisplayProfile(profile),
    type: ItemType.EVENT,
    title: title,
    location: location,
    startDate: startTimestamp,
    endDate: endTimestamp,
  };

  // Save or update the event in the database
  try {
    if (item) {
      await dataManager.update(collections.items, profile.key, key, data);
    } else {
      await dataManager.add(collections.items, profile.key, key, data);
    }

    // Notify the parent component (if applicable)
    if (onSave) {
      onSave(data);
    }

  } catch (error) {
    console.error(`Error saving event:`, error);
  }

  hideProgress();
};