import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './PinsToolbar.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PinFilledIcon from '../../../svg/PinFilledIcon';

// Components
import Slider from '../../../components/slider/Slider';
import { Toolbar, Left, Center, Right } from '../../../components/toolbar/Toolbar';
import ToolbarIcon from '../../../components/toolbar/icon/ToolbarIcon';
import ToolbarTitle from '../../../components/toolbar/title/ToolbarTitle';
import PinsSearch from '../search/PinsSearch';

// Managers
import DataManager from '../../../managers/DataManager';
const dataManager = new DataManager();

const PinsToolbar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        profile,
        profilePins,
        selectedPin,
        setSelectedPin,
        showProgress,
    } = useContext(Global);

    // Local State
    const [value, setValue] = useState(.5);

    useEffect(() => {
        if (!selectedPin) return;
        setValue(selectedPin.radius);
    }, [selectedPin]);

    const handleSliderChange = (newValue) => {
        // Update the local state value for the selectedPin
        setSelectedPin({
            ...selectedPin,
            radius: Math.round(newValue),
        });

        setValue(newValue);
    };

    const handleSliderBlur = async (newValue) => {
        // Set the radius of the selected pin
        await dataManager.update(
            collections.pins,
            profile.key,
            selectedPin.key,
            { radius: Math.round(newValue) }
        );
    };

    const handleSetHomeClick = async () => {

        showProgress("Setting home pin...");

        // Iterate profile pins and set "isHome" to false for all pins
        for (const pin of profilePins) {
            await dataManager.update(
                collections.pins,
                profile.key,
                pin.key,
                { isHome: false }
            );
        }

        // Set the selected pin to the home pin
        await dataManager.update(
            collections.pins,
            profile.key,
            selectedPin.key,
            { isHome: true }
        );

        hideProgress();
    };

    const handleDeleteClick = async () => {
        await dataManager.delete(
            collections.pins,
            profile.key,
            selectedPin.key
        );
    };

    return (
        <Toolbar>

            <Left>

                {/* ICON */}
                <ToolbarIcon
                    icon={PinFilledIcon}
                />

                {/* TITLE */}
                <ToolbarTitle
                    title="My Pins"
                />

            </Left>
            <Center>


            </Center>
            <Right>

                <div className="pins-toolbar-slider-radius"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    Pin Radius:
                </div>

                {/* SLIDER */}
                <div className="pins-toolbar-slider-wrapper">
                    <Slider
                        value={value}
                        trackHeight="8px"
                        thumbSize="20px"
                        lowerBound={2}
                        upperBound={300}
                        onChange={handleSliderChange}
                        onBlur={handleSliderBlur}
                    />
                </div>

                <div className="pins-toolbar-slider-miles"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    {Math.round(value)} Miles
                </div>

                {/* SET HOME BUTTON */}
                {selectedPin &&
                    <div className="pins-toolbar-buttons">
                        <div className="pins-toolbar-button"
                            onClick={handleSetHomeClick}
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor,
                            }}>
                            Set as Home Pin
                        </div>

                        {/* DELETE BUTTON */}
                        <div className="pins-toolbar-button"
                            onClick={handleDeleteClick}
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor,
                            }}>
                            Delete Pin
                        </div>
                    </div>
                }

                <PinsSearch />

            </Right>

        </Toolbar>
    );
};

export default PinsToolbar;
