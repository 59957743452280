import React from 'react';

// Styles
import './ThumbDelete.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CloseIcon from '../../../svg/CloseIcon';

/**
 * ThumbDelete
 * 
 * A button for deleting items from an item thumbnail.
 * 
 * @param {Object} item The item to delete.
 * @param {Function} onDeleteClick The delete event handler.
 * 
 * @returns {JSX.Element} ThumbDelete component.
 */
const ThumbDelete = ({ item, onDelete }) => {

    // Theme
    const { theme } = useTheme();

    const handleDeleteClick = () => {
        if (onDelete) onDelete(item);
    };

    return (

        <div className="thumb-delete-container"
            onClick={handleDeleteClick}
            style={{
                backgroundColor: theme.highlightBackgroundColor,
                borderColor: theme.backgroundColorFaded
            }}>
            <CloseIcon
                color={theme.highlightForegroundColor}
                width="8"
                height="8"
            />
        </div>

    );
};

export default ThumbDelete;
