import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Utilities
import { PinType } from '../../common/utilities/Enums';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load pins for a specific profile.
 */
const usePreloadProfilePins = () => {

  // Global
  const {
    profile,
    setProfilePins,
    setProfilePosts
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected profile
    if (!profile || !profile.key) {
      setProfilePins([]);
      return;
    }

    const load = () => {
      const handleUpdate = (pins) => {
        setProfilePins(pins);

        // Load posts from the pins
        const posts = pins.filter(pin => pin.type === PinType.POST);
        setProfilePosts(posts);
      };

      const params = [
        { field: "profileKey", operator: "==", value: profile.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.pins,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribePins = load();

    return () => {
      if (unsubscribePins) {
        unsubscribePins();
      }
    };
  }, [profile, setProfilePins, setProfilePosts]);

};

export default usePreloadProfilePins;
