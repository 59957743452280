export function getLocationsPhrase(pins, defaultPhrase) {
    // Extract location names from the locations array
    const locations = pins.map(pin => {
        if (pin.title) return pin.title;
        if (pin.pin) return pin.pin;
        return pin.key;
    });

    // Handle different cases based on array length
    if (locations.length === 0 && defaultPhrase) return defaultPhrase;
    if (locations.length === 1) return `${locations[0]}`;
    if (locations.length === 2) return `${locations[0]} and ${locations[1]}`;
    if (locations.length === 3) return `${locations[0]}, ${locations[1]} and ${locations[2]}`;
    return `${locations[0]}, ${locations[1]} and ${locations.length - 2} other locations`;
}
