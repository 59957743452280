import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load engagements for a specific profile.
 */
const usePreloadIncomingEngagements = () => {

  // Global
  const {
    profile,
    setProfileIncomingEngagements,
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected profile
    if (!profile || !profile.key) {
      setProfileIncomingEngagements([]);
      return;
    }

    const load = () => {
      const handleUpdate = (engagements) => {
        setProfileIncomingEngagements(engagements);
      };

      const params = [
        { field: "targetKey", operator: "==", value: profile.key }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.engagements,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeEngagements = load();

    return () => {
      if (unsubscribeEngagements) {
        unsubscribeEngagements();
      }
    };
  }, [profile, setProfileIncomingEngagements]);

};

export default usePreloadIncomingEngagements;
