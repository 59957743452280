import { format } from 'date-fns';

/**
 * Formats a phone number, e.g. "(406) 580-1546".
 */
export function formatPhoneNumber(value) {
  const numbers = value.replace(/\D/g, '');
  const phoneNumber = numbers.substring(0, 10); // Limit to 10 digits
  const areaCode = phoneNumber.substring(0, 3);
  const middle = phoneNumber.substring(3, 6);
  const last = phoneNumber.substring(6, 10);

  let formattedValue = '';
  if (phoneNumber.length > 6) {
    formattedValue = `(${areaCode}) ${middle}-${last}`;
  } else if (phoneNumber.length > 3) {
    formattedValue = `(${areaCode}) ${middle}`;
  } else if (phoneNumber.length > 0) {
    formattedValue = `(${areaCode}`;
  }
  return formattedValue;
}

/**
 * Utility function to format event dates and times into a single display string with line breaks.
 * 
 * @param {Date} startDate - The start date of the event.
 * @param {Date} endDate - The end date of the event.
 * @param {boolean} [longForm=false] - Whether to use long-form day/month names.
 * @returns {Object} An object with a single formatted display string.
 */
export const formatEventDates = (startDate, endDate, longForm = false) => {
  // Ensure inputs are valid Firestore Timestamps and convert to JS Date
  if (!startDate || !endDate || typeof startDate.toDate !== 'function' || typeof endDate.toDate !== 'function') {
    console.error('Invalid Timestamp objects:', { startDate, endDate });
    return { display: null };
  }

  const start = startDate.toDate();
  const end = endDate.toDate();

  // Determine the date format based on the longForm parameter
  const dateFormat = longForm ? 'EEEE, MMMM d, yyyy' : 'EEE, MMM d, yyyy';
  const timeFormat = 'h:mm a';

  let formattedDisplay;

  try {
    // Same-day event: Date on one line, time range on the next
    if (format(start, 'yyyy-MM-dd') === format(end, 'yyyy-MM-dd')) {
      formattedDisplay = `${format(start, dateFormat)}\n${format(start, timeFormat)} - ${format(end, timeFormat)}`;
    }
    // Multi-day event: Start date+time on one line, end date+time on the next
    else {
      formattedDisplay = `${format(start, dateFormat)} at ${format(start, timeFormat)}\n${format(end, dateFormat)} at ${format(end, timeFormat)}`;
    }
  } catch (error) {
    console.error('Error formatting dates:', error);
    return { display: null };
  }

  // Return object with keys matching what the component expects
  return { display: formattedDisplay };
};

/**
 * Function to format currency as a decimal to 2 places, applied on blur.
 */
export function formatCurrency(value) {
  if (!value) return '';
  const numValue = parseFloat(value).toFixed(2);
  return isNaN(numValue) ? '' : numValue;
}

/**
 * Function to format number, removing unnecessary zeros and trailing decimal points.
 */
export function formatNumber(value) {
  const trimmedValue = value.replace(/(\.\d*?[1-9])0+$|\.0*$/, '$1');
  return trimmedValue ? Number(trimmedValue).toString() : '';
}

/**
 * Function to format date, e.g. "Wed, Jul 10, 2024"
 */
export function formatTimestamp(date, long = true) {
  if (date && date.toDate) {
    date = date.toDate();
  }
  return date.toLocaleDateString('en-US', {
    weekday: long ? 'long' : 'short',
    year: 'numeric',
    month: long ? 'long' : 'short',
    day: 'numeric'
  });
};

/**
 * Function to format a JavaScript Date object, e.g. "Wed, Jul 10, 2024"
 */
export function formatDate(date) {
  if (date && date.toDate) {
    date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
  }

  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}
/**
 * Function to format a JavaScript Date object
 */
export const formatDateLong = (date) => {
  // Check if the date is a Firebase Timestamp object
  if (date && date.toDate) {
    date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
  }

  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

/**
 * Function to format day and date, e.g. "Wednesday the 31st"
 */
export const formatDayAndDate = (date) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const formatWeekRange = (startDate) => {
  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6); // Add 6 days to get the end of the week

  const formattedStart = new Intl.DateTimeFormat('en-US', options).format(startDate);
  const formattedEnd = new Intl.DateTimeFormat('en-US', options).format(endDate);

  return `${formattedStart} - ${formattedEnd}`;
};

export const formatDaySpan = (startDate, endDate) => {
  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  endDate.setDate(startDate.getDate() + endDate.getDate());

  const formattedStart = new Intl.DateTimeFormat('en-US', options).format(startDate);
  const formattedEnd = new Intl.DateTimeFormat('en-US', options).format(endDate);

  return `${formattedStart} - ${formattedEnd}`;
};

export const formatDayAndTimeSpan = (startDate, endDate) => {
  const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };

  // Add the time information in a user-friendly format
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return 'midnight';
    }
    if (hours === 12 && minutes === 0) {
      return 'noon';
    }

    const period = hours >= 12 ? 'pm' : 'am';
    const adjustedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';

    return `at ${adjustedHours}${formattedMinutes}${period}`;
  };

  // Ensure the endDate is offset correctly based on the startDate
  endDate.setDate(startDate.getDate() + endDate.getDate());

  const formattedStartDate = new Intl.DateTimeFormat('en-US', dateOptions).format(startDate);
  const formattedStartTime = formatTime(startDate);

  const formattedEndDate = new Intl.DateTimeFormat('en-US', dateOptions).format(endDate);
  const formattedEndTime = formatTime(endDate);

  // Combine the formatted dates and times
  return `${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`;
};

export const formatDayAndTimeSpanShort = (startDate, endDate) => {
  // Format time in a user-friendly way
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return 'midnight';
    }
    if (hours === 12 && minutes === 0) {
      return 'noon';
    }

    const period = hours >= 12 ? 'pm' : 'am';
    const adjustedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';

    return `${adjustedHours}${formattedMinutes}${period}`;
  };

  // Format weekday in full form
  const formatDay = (date) => {
    return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
  };

  const startDay = formatDay(startDate);
  const startTime = formatTime(startDate);
  const endDay = formatDay(endDate);
  const endTime = formatTime(endDate);

  // Combine into the desired format
  return `${startDay} at ${startTime} - ${endDay} at ${endTime}`;
};

export const formatDateAndTimeShort = (d) => {
  // Format time in a user-friendly way
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return 'midnight';
    }
    if (hours === 12 && minutes === 0) {
      return 'noon';
    }

    const period = hours >= 12 ? 'pm' : 'am';
    const adjustedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';

    return `${adjustedHours}${formattedMinutes}${period}`;
  };

  // Format weekday in full form
  const formatDay = (date) => {
    return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }).format(date);
  };

  const day = formatDay(d);
  const time = formatTime(d);

  // Combine into the desired format
  return `${day} at ${time}`;
};

export const formatMonthAndYear = (date) => {
  const options = { year: 'numeric', month: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

/**
* Formats a file size to a human-readable string.
* @param {number} size - The file size in bytes.
* @returns {string} - The formatted file size.
*/
export const formatFileSize = (size) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${Math.round(size)} ${units[unitIndex]}`;
};

export const formatCount = (count) => {
  if (count < 1000) {
    return count.toString(); // No formatting needed for numbers less than 1000
  } else if (count < 1000000) {
    // Format for thousands (k)
    const formatted = (count / 1000).toFixed(1);
    // Remove .0 if it exists
    return formatted.endsWith('.0') ? `${Math.floor(count / 1000)}K` : `${formatted}K`;
  } else {
    // Format for millions (m)
    const formatted = (count / 1000000).toFixed(1);
    // Remove .0 if it exists
    return formatted.endsWith('.0') ? `${Math.floor(count / 1000000)}M` : `${formatted}M`;
  }
};

// Format duration from seconds to mm:ss
export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};