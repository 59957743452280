import React from 'react';

const StreamFilledIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M203.85-200q-27.62 0-46.12-18.5-18.5-18.5-18.5-46.12v-430.76q0-27.62 18.5-46.12 18.5-18.5 46.12-18.5h430.77q27.61 0 46.11 18.5t18.5 46.12v186.15l121.54-121.54v301.54L699.23-450.77v186.15q0 27.62-18.5 46.12-18.5 18.5-46.11 18.5H203.85Z" />
    </svg>

);

export default StreamFilledIcon;
