import React from 'react';

const AddPhotosIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M480-480ZM224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h260q8.5 0 14.25 5.76t5.75 14.27q0 8.51-5.75 14.24T484.62-760h-260q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v510.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h510.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-260q0-8.5 5.76-14.25t14.27-5.75q8.51 0 14.24 5.75t5.73 14.25v260q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm46.15-144.62h418.46L560-476.92 440-325.38l-80-96.16-89.23 116.92ZM680-680h-60q-8.5 0-14.25-5.76T600-700.03q0-8.51 5.75-14.24T620-720h60v-60q0-8.5 5.76-14.25t14.27-5.75q8.51 0 14.24 5.75T720-780v60h60q8.5 0 14.25 5.76t5.75 14.27q0 8.51-5.75 14.24T780-680h-60v60q0 8.5-5.76 14.25T699.97-600q-8.51 0-14.24-5.75T680-620v-60Z" />
  </svg>

);

export default AddPhotosIcon;
