import React, { useEffect, useState } from 'react';

// Styles
import './CommentScroller.css';

// Utilities
import { hexToRgba } from '../../../../../../common/utilities/Colors';
import { ThemeMode } from '../../../../../../common/utilities/Enums';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Components
import ProfileDot from '../../../../../../common/components/profiledot/ProfileDot';

const CommentScroller = ({ item, comments }) => {

  // Theme
  const { currentThemeMode } = useTheme();

  const [localComments, setLocalComments] = useState([]);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const sorted = comments.sort((a, b) => {
      return new Date(b.dateCreated.toDate()) - new Date(a.dateCreated.toDate());
    });
    setLocalComments(sorted.slice(0, 5)); // Limit to the latest 5 comments
  }, [item?.key, comments]);

  const handleAnimationEnd = () => {
    setAnimationKey(prev => prev + 1);
  };

  return (
    <div className="comment-scroller-container">
      <div
        className="comment-scroller"
        key={animationKey}
        onAnimationEnd={handleAnimationEnd}
      >
        {localComments.map((comment, index) => (
          <div
            key={index}
            className="comment-item"
            style={{
              animationDelay: `${index * 0.5}s`,
              backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5),
              color: currentThemeMode === ThemeMode.DARK ? "#FFFFFF" : "#000000"
            }}
          >
            {/* PROFILE DOT */}
            <div className="comment-profile-dot">
              <ProfileDot item={comment.profile} size={24} />
            </div>

            {/* COMMENT TEXT */}
            {comment.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentScroller;