import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for createPortal

// Global
import { Global } from '../../../Global';

// Utilities
import { hexToRgba } from '../../utilities/Colors';
import { ThemeMode } from '../../utilities/Enums';

// Styles
import './Modal.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CloseIcon from '../../svg/CloseIcon';

/**
 * Modal Component
 * 
 * This component renders a modal dialog.
 * 
 * @param {string} title - The title of the modal.
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onClose - The function to call when the modal is closed.
 * @param {JSX.Element} children - The children to render.
 * @param {string} width - The width of the modal.
 * @param {string} height - The height of the modal.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Modal = ({ title, isOpen, onClose, children, width, height, padding = '10px', showHeader = true, enableClose = true, transparent = false }) => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    profile,
    coreTheme
  } = useContext(Global);

  // Local State
  const [isAnimating, setIsAnimating] = useState(false);
  const [stackIndex, setStackIndex] = useState(null); // Track this modal's position in the stack

  // Static counter to track total open modals
  if (!Modal.instanceCount) Modal.instanceCount = 0;

  // Fade and layering
  useEffect(() => {
    if (isOpen && stackIndex === null) {
      Modal.instanceCount += 1; // Increment total count
      setStackIndex(Modal.instanceCount - 1); // Assign this modal's index (0-based)
      setIsAnimating(true);
    }

    return () => {
      if (isOpen && stackIndex !== null) {
        Modal.instanceCount = Math.max(Modal.instanceCount - 1, 0); // Decrement total count
        setStackIndex(null); // Reset for next open
      }
    };
  }, [isOpen, stackIndex]);

  useEffect(() => {
    if (!isOpen) {
      setIsAnimating(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    if (enableClose) {
      onClose();
      setIsAnimating(false);
    }
  };

  /**
   * Create a style object that includes the width and height if provided.
   */
  const modalStyle = {
    ...profile ?
      ({ backgroundColor: transparent ? hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.8) : profile ? theme.backgroundColor : coreTheme.backgroundColor }) :
      ({ backgroundColor: coreTheme && coreTheme.backgroundColor }),
    ...width && { width: width },
    ...height && { height: height },
    opacity: isAnimating ? 1 : 0,
    transition: 'opacity 300ms'
  };

  /**
   * Modal overlay styles to apply background color or image with opacity.
   */
  const overlayStyleApp = hexToRgba(
    currentThemeMode === ThemeMode.DARK ? "black" : "white",
    0.9 // 80% opacity
  );

  const overlayStyleCore = {
    backgroundImage: profile
      ? currentThemeMode === ThemeMode.DARK
        ? `url(${profile.wallpaperDark || 'none'})`
        : `url(${profile.wallpaperLight || 'none'})`
      : 'none', // Fallback if profile is null
    backgroundSize: 'cover', // Ensures the image covers the entire screen
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
    opacity: 0.8, // Semi-transparency for the image
  };

  // If modal is not open and not animating, return null to render nothing
  if (!isOpen && !isAnimating) return null;

  // Base z-index values
  const BASE_OVERLAY_Z_INDEX = 10;
  const BASE_CONTENT_Z_INDEX = 20;

  // Calculate z-index based on stackIndex
  const overlayZIndex = BASE_OVERLAY_Z_INDEX + (stackIndex !== null ? stackIndex * 10 : 0);
  const contentZIndex = BASE_CONTENT_Z_INDEX + (stackIndex !== null ? stackIndex * 10 : 0);

  // Define the modal content with overlay as a portal
  const modalContent = (
    <>
      {/* OVERLAY */}
      <div
        className="modal-overlay"
        style={{
          backgroundColor: profile ? overlayStyleApp : coreTheme.backgroundColor,
          ...overlayStyleCore,
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          zIndex: overlayZIndex,
        }}
        onMouseDown={handleClose}
      />

      {/* CONTENT */}
      <div
        className="modal-content"
        style={{
          ...modalStyle,
          position: 'fixed',
          top: '50%',
          left: '50%',
          padding: padding,
          transform: 'translate(-50%, -50%)',
          zIndex: contentZIndex,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >

        {/* HEADER */}
        {showHeader &&

          <div className="modal-header">

            {/* TITLE */}
            <div className="modal-title"
              style={{
                color: profile ? theme.foregroundColor : coreTheme.foregroundColor
              }}>
              {title}
            </div>

            {/* CLOSE BUTTON */}
            {enableClose &&
              <div className="modal-close-button" onClick={handleClose}>
                <CloseIcon
                  color={profile ?
                    theme.foregroundColor :
                    coreTheme.foregroundColor
                  }
                  width="16"
                  height="16"
                />
              </div>
            }

          </div>
        }

        {/* BODY */}
        <div className="modal-body">
          {children}
        </div>

      </div>

    </>
  );

  // Use createPortal to render modalContent outside of the root hierarchy
  return ReactDOM.createPortal(modalContent, document.getElementById('portal-root'));
};

export default Modal;