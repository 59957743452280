import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

/**
 * Custom hook to pan to a search result
 */
const useFocusSelectedPin = ({ googleMapRef, pinMarkersRef }) => {

  // Global
  const {
    selectedPin
  } = useContext(Global);

  useEffect(() => {
      if (!googleMapRef.current || !selectedPin) return;
  
      const matchingPin = pinMarkersRef.current.find(item => item.key === selectedPin.key);
      if (!matchingPin) {
        console.error(`No marker found for selected pin:`, selectedPin);
        return;
      }
  
      const { marker, circle } = matchingPin;
      if (!marker || !circle) {
        console.error(`Missing marker or circle for pin:`, selectedPin);
        return;
      }

        const position = new window.google.maps.LatLng(selectedPin.lat, selectedPin.lng);
        googleMapRef.current.setCenter(position);
        googleMapRef.current.fitBounds(circle.getBounds());
  
    }, [googleMapRef, selectedPin, pinMarkersRef]);

};

export default useFocusSelectedPin;
