import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Streaming
import { handleSave } from '../buttons/EventButtonsUtils';

// Styles
import './EventWizard.css';

// Components
import EventFormFields from '../fields/EventFormFields';
import Modal from '../../../components/modal/Modal';
import WizardButton from '../../../../common/wizard/WizardButton';

// Utilities
import { generateKey } from '../../../utilities/Keys';

const EventWizard = ({ item, isOpen, setOpen, date = new Date(), onSave }) => {

  // Global
  const {
    profile,
    hideProgress,
    showProgress,
  } = useContext(Global);

  // Event Properties
  const [title, setTitle] = useState('');
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [location, setLocation] = useState('');

  // Generate a unique key for a new event
  const generatedKey = generateKey();

  useEffect(() => {
    if (isOpen) {
      // Round up to the next full hour
      const start = new Date();
      start.setMinutes(0); // Reset minutes to 0
      start.setSeconds(0); // Reset seconds to 0
      start.setMilliseconds(0); // Reset milliseconds to 0
      start.setHours(start.getHours()); // Move to the next hour
      const end = new Date(start);
      end.setHours(end.getHours() + 1); // One hour later
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Add an Event"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      width={isMobile ? "100%" : "400px"}
      height={isMobile ? "100%" : "400px"}
    >
      <div className="event-wizard-container">
        <div
          key="step1"
          className="event-wizard-step-container"
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.3, ease: 'easeInOut' }}>

          <div className="event-wizard-step-main">

            <EventFormFields
              title={title}
              setTitle={setTitle}
              defaultStart={defaultStart}
              defaultEnd={defaultEnd}
              onStartDateChange={(d) => { setStartDate(d) }}
              onStartTimeChange={(t) => { setStartTime(t) }}
              onEndDateChange={(d) => { setEndDate(d) }}
              onEndTimeChange={(t) => { setEndTime(t) }}
              location={location}
              setLocation={setLocation}
            />

          </div>

          {/* STEP 1 BUTTONS */}
          <div className="event-wizard-step-buttons">
            <WizardButton label="Finish"
              enabled={title && startDate && startTime && endDate && endTime}
              onClick={() => handleSave({
                item,
                profile,
                hideProgress,
                showProgress,
                title,
                setTitle,
                startDate,
                startTime,
                endDate,
                endTime,
                location,
                generatedKey,
                setOpen,
                onSave,
              })} />
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default EventWizard;