import React, { useEffect, useState } from 'react';

// Styles
import './PostThumbItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import NoteIcon from '../../../../svg/NoteIcon';

/**
 * PostThumbItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Function} onClick The click event handler.
 * 
 * @returns {JSX.Element} PostThumbItem component.
 */
const PostThumbItem = ({ item, onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [backgroundUrl, setBackgroundUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {
        const items = item.items;
        if (items) {
            const photos = items.filter(item => item.type === "PHOTO");
            if (photos.length > 0) {
                setBackgroundUrl(photos[0].url);
            } else {
                // Video
                const videos = items.filter(item => item.type === "VIDEO");
                if (videos.length > 0) {
                    setVideoUrl(videos[0].url);
                }
            }
        }

    }, [item]);

    return (

        <div className="post-thumb-item-container"
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}>

            {/* IMAGE/ICON */}
            {backgroundUrl ? (
                <div className="post-thumb-item-image-wrapper">
                    <img 
                        alt="Post"
                        className="post-thumb-item-image"
                        src={backgroundUrl} />
                </div>
            ) : videoUrl ? (
                <div className="post-thumb-item-video-wrapper">
                    <video
                        className="post-thumb-item-video"
                        src={videoUrl}
                        controls={false}
                        autoPlay={false}
                    />
                </div>
            ) : (
                <div className="post-thumb-item-icon-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <NoteIcon
                        color={theme.highlightBackgroundColor}
                        width="75"
                        height="75"
                    />
                </div>
            )}

        </div>

    );
};

export default PostThumbItem;
