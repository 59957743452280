import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Utilities
import { defaultFont } from '../../../../../common/utilities/Defaults';

// Styles
import './PostOptions.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import Divider from '../../../../../common/components/divider/Divider';
import ModalSwitch from '../../../../../common/components/modal/switch/ModalSwitch';
import PostOptionsMoreButton from './morebutton/PostOptionsMoreButton';

const PostOptions = ({
    post,
    handleChange,
    onPrivacyClick,
    onContributeClick,
    onCommentsClick,
    privacyPhrase,
    contributePhrase,
    commentsPhrase,
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    return (
        <div className="post-options-container">

            <div className="post-options-title-wrapper">
                <div className="post-options-title-label"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    Describe this post...
                </div>
                <textarea
                    className="post-options-title-textarea"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: profile.fontFamily || defaultFont
                    }}
                    value={post.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                    rows={4}
                    maxLength={500}></textarea>
            </div>

            <Divider
                margin="14px"
            />

            {/* PRIVACY */}
            <PostOptionsMoreButton
                label="Who can view this post"
                summary={privacyPhrase}
                onClick={onPrivacyClick}
            />

            <Divider
                margin="14px"
            />

            {/* CONTRIBUTIONS */}
            <PostOptionsMoreButton
                label="Who can contribute media"
                summary={contributePhrase}
                onClick={onContributeClick}
            />

            <Divider
                margin="14px"
            />

            {/* COMMENTS */}
            <PostOptionsMoreButton
                label="Who can comment"
                summary={commentsPhrase}
                onClick={onCommentsClick}
            />

            {/* DIVIDER */}
            <Divider
                margin="14px"
            />

        </div>
    );
};

export default PostOptions;
