import React from 'react';

// Mobile Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { hexToRgba } from '../../../../../common/utilities/Colors';
import { ThemeMode } from '../../../../../common/utilities/Enums';

// Styles
import './CommentsPopup.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CloseIcon from '../../../../../common/svg/CloseIcon';

// Components
import Comments from '../Comments.js';

/**
 * CommentsPopup component
 * 
 * Renders a popup for displaying comments.
 */
const CommentsPopup = ({
  isOpen,
  comments,
  onClose,
  setOpen,
}) => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  return (
    <>
      <div
        className="comments-popup-mask"
        onClick={() => setOpen(false)}
        style={{
          backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? '#000000' : '#FFFFFF', 0.7),
          height: isOpen ? '100%' : '0px',
          width: isMobile ? '100%' : '460px',
        }}
      ></div>

      <div className="comments-popup-container"
        style={{
          backgroundColor: theme.backgroundColor,
          height: isOpen ? '80%' : '0px',
          marginRight: isMobile ? '0px' : '60px',
          width: isMobile ? '100%' : '460px',
        }}>

        {/* HEADER */}
        <div className="comments-popup-header">

          {/* HEADER TEXT*/}
          <div className="comments-popup-header-text"
            style={{
              color: theme.foregroundColor
            }}>
            {comments.length} {comments.length === 1 ? 'comment' : 'comments'}
          </div>

          {/* COMMENTS CLOSE*/}
          <div className="comments-popup-header-close"
            onClick={onClose}>
            <CloseIcon
              color={theme.foregroundColor}
              width="14"
              height="14"
            />
          </div>

        </div>

        {/* COMMENTS */}
        <div className="comments-popup-wrapper">
          <Comments />
        </div>

      </div>

    </>

  );
};

export default CommentsPopup;
