import React from 'react';

// Styles
import './VideoThumbItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import VideoIcon from '../../../../svg/VideoIcon';

// Components
import ThumbDelete from '../../../common/thumbdelete/ThumbDelete';

/**
 * VideoThumbItem
 * 
 * A thumb item for displaying videos.
 * 
 * @param {Object} item The video item.
 * @param {Function} onDelete The delete event handler.
 * 
 * @returns {JSX.Element} VideoThumbItem component.
 */
const VideoThumbItem = ({ item, onDelete }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="video-thumb-item-container"
            title={item.title}>

            {/* DELETE BUTTON */}
            <ThumbDelete 
                item={item} 
                onDelete={onDelete} 
            />

            {/* VIDEO OR ICON */}
            {item.url ? (
                <div className="video-thumb-item-video-container">
                    <video
                        className="video-thumb-item-video"
                        src={item.url}
                        controls={false}
                        autoPlay={false}
                    />
                </div>
            ) : (
                <div
                    className="video-thumb-item-icon-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <VideoIcon
                        color={theme.highlightBackgroundColor}
                        width="40"
                        height="40"
                    />
                </div>
            )}

        </div>

    );
};

export default VideoThumbItem;