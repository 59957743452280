import React from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ProfileModal.css';

// Components
import Modal from '../../../common/components/modal/Modal';
import Profile from '../../../common/sections/profile/Profile';

/**
 * ProfileModal Component
 * 
 * This component allows the user to edit a item.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileModal = ({ profile, isOpen, setOpen }) => {

    if (!isOpen) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title=""
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? "100%" : "95%"}
                height={isMobile ? "100%" : "95%"}>

                {/* PROFILE */}
                <Profile 
                    viewingProfile={profile} 
                />

            </Modal>
        </>
    );
};

export default ProfileModal;
