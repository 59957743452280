import React, { useEffect, useState } from 'react';

import { hexToRgba } from '../../../../utilities/Colors';
import { ItemSize, ThemeMode } from '../../../../utilities/Enums';

// Styles
import './PostGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../svg/CalendarIcon';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';

/**
 * PostGridItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} PostGridItem component.
 */
const PostGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Local State
    const [backgroundUrl, setBackgroundUrl] = useState(null);

    useEffect(() => {
        const items = item.items;
        if (items) {
            const photos = items.filter(item => item.type === "PHOTO");
            if (photos.length > 0) {
                setBackgroundUrl(photos[0].url);
            }
        }

    }, [item]);

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart) onDragStart(event, item);
            }}
            size={size}>

            <div className="post-grid-item-container"
                title={item.title}>

                
                {/* IMAGE/ICON */}
                {backgroundUrl ? (
                    <div className="post-grid-item-image-container">
                        <img 
                            alt="Post"
                            className="post-grid-item-image"
                            src={backgroundUrl} />
                    </div>
                ) : (
                    <div className="post-grid-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <CalendarIcon
                            color={theme.highlightBackgroundColor}
                            width="110"
                            height="110"
                        />
                    </div>
                )}

                <div className="post-grid-item-footer"
                    style={{
                        backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                    }}>

                    <div className="post-grid-item-title"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {item.title}
                    </div>

                    {item.location && item.location.length > 0 && (
                        <div className="post-grid-item-location"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {item.location}
                        </div>
                    )}

                </div>

            </div>

        </GridItem>
    );
};

export default PostGridItem;
