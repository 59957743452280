import React from 'react';

// Styles
import './CommentTopToggle.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

/**
 * CommentTopToggle component
 * 
 * Renders a comment replies toggle button for a comment.
 */
const CommentTopToggle = ({ repliesCount, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="comment-top-toggle-container">
            <div
                className="comment-top-toggle-button"
                style={{ color: theme.foregroundColorFaded, cursor: 'pointer' }}
                onClick={onClick}>
                <div className="comment-top-toggle-bar"
                    style={{ backgroundColor: theme.foregroundColorFaded }}
                ></div>
                View {repliesCount} {repliesCount === 1 ? 'reply' : 'replies'}
            </div>
        </div>

    );
};

export default CommentTopToggle;
