import React, { useEffect, useState } from 'react';

// Utilities
import { getUsersPhrase } from '../../../../../utilities/Profiles.js';
import { getLocationsPhrase } from '../../../../../utilities/Pins.js';

// Styles
import './PostOptionsPanel.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext.js';

// Images
import LeftIcon from '../../../../../svg/LeftIcon.jsx';
import RightIcon from '../../../../../svg/RightIcon.jsx';

// Components
import Divider from '../../../../../components/divider/Divider.js';
import ModalCheckbox from '../../../../../components/modal/checkbox/ModalCheckbox.js';

const PostOptionsPanel = ({
    post,
    handleChange,
    setStep,
    title,
    prefix,
    friendSelectorStep = 5,
    locationSelectorStep = 6,
}) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [hasManuallyChangedFriends, setHasManuallyChangedFriends] = useState(false);
    const [hasManuallyChangedLocations, setHasManuallyChangedLocations] = useState(false);

    // Sync Friends with SelectedFriends
    useEffect(() => {
        const selectedFriendsKey = `${prefix}SelectedFriends`;
        const friendsKey = `${prefix}Friends`;
        if (!hasManuallyChangedFriends && post[selectedFriendsKey].length > 0 && !post[friendsKey]) {
            handleChange(`${prefix}Everyone`, false);
            handleChange(`${prefix}Friends`, true);
            handleChange(`${prefix}OnlyYou`, false);
        }
    }, [post[`${prefix}SelectedFriends`], post[`${prefix}Friends`], handleChange, prefix, hasManuallyChangedFriends]);

    // Sync Locations with SelectedLocations
    useEffect(() => {
        const selectedLocationsKey = `${prefix}SelectedLocations`;
        const locationsKey = `${prefix}Locations`;
        if (!hasManuallyChangedLocations && post[selectedLocationsKey].length > 0 && !post[locationsKey]) {
            handleChange(`${prefix}Everywhere`, false);
            handleChange(`${prefix}Locations`, true);
        }
    }, [post[`${prefix}SelectedLocations`], post[`${prefix}Locations`], handleChange, prefix, hasManuallyChangedLocations]);

    const handleOptionChange = (option) => {
        setHasManuallyChangedFriends(true);
        handleChange(`${prefix}Everyone`, option === "everyone");
        handleChange(`${prefix}Friends`, option === "friends");
        handleChange(`${prefix}OnlyYou`, option === "onlyYou");
        if (option !== "friends") {
            handleChange(`${prefix}SelectedFriends`, []);
            handleChange(`${prefix}Everywhere`, true);
            handleChange(`${prefix}Locations`, false);
            handleChange(`${prefix}SelectedLocations`, []);
        }
    };

    const handleCheckboxChange = (option) => (newValue) => {
        if (newValue) {
            handleOptionChange(option);
        }
    };

    const handleEverywhereChange = () => {
        setHasManuallyChangedLocations(true); // Mark as manual change
        handleChange(`${prefix}Everywhere`, true);
        handleChange(`${prefix}Locations`, false);
        handleChange(`${prefix}SelectedLocations`, []); // Clear locations when switching to "Anywhere"
    };

    const handleLocationsChange = () => {
        setHasManuallyChangedLocations(true); // Mark as manual change
        handleChange(`${prefix}Everywhere`, false);
        handleChange(`${prefix}Locations`, true);
    };

    const handleFriendsSelect = () => {
        setHasManuallyChangedFriends(false); // Reset for sync on return
        setStep(friendSelectorStep);
    };

    const handleLocationSelect = () => {
        setHasManuallyChangedLocations(false); // Reset for sync on return
        setStep(locationSelectorStep);
    };

    return (
        <div className="post-options-panel-container">
            <div
                className="post-options-panel-title"
                onClick={() => setStep(1)}
                style={{ color: theme.foregroundColor }}>
                <LeftIcon color={theme.foregroundColor} width="18" height="18" />
                {title}
            </div>

            <div className="post-options-panel-sections">
                {/* EVERYONE */}
                <div
                    className="post-options-panel-row"
                    onClick={() => handleOptionChange("everyone")}>
                    <div className="post-options-panel-option-label" style={{ color: theme.foregroundColor }}>
                        Everyone (default)
                    </div>
                    <ModalCheckbox
                        value={post[`${prefix}Everyone`]}
                        onChange={handleCheckboxChange("everyone")}
                    />
                </div>

                {/* FRIENDS */}
                <div
                    className="post-options-panel-row"
                    onClick={() => handleOptionChange("friends")}>
                    <div className="post-options-panel-option-label" style={{ color: theme.foregroundColor }}>
                        Friends
                    </div>
                    <ModalCheckbox
                        value={post[`${prefix}Friends`]}
                        onChange={handleCheckboxChange("friends")}
                    />
                </div>

                <div
                    className="post-options-panel-option-summary-row"
                    onClick={handleFriendsSelect}
                    style={{ 
                        color: theme.foregroundColorFaded, 
                    }}>
                    {getUsersPhrase(post[`${prefix}SelectedFriends`], "All Friends")}
                    <RightIcon color={theme.foregroundColorFaded} width="14" height="14" />
                </div>

                {/* ONLY YOU */}
                <div
                    className="post-options-panel-row"
                    onClick={() => handleOptionChange("onlyYou")}>
                    <div className="post-options-panel-option-label" style={{ color: theme.foregroundColor }}>
                        Only you
                    </div>
                    <ModalCheckbox
                        value={post[`${prefix}OnlyYou`]}
                        onChange={handleCheckboxChange("onlyYou")}
                    />
                </div>

                <Divider margin="14px" />

                {post[`${prefix}Everyone`] && (
                    <>
                        <div className="post-options-panel-section-title" style={{ color: theme.foregroundColor }}>
                            Location restrictions
                        </div>

                        <div
                            className="post-options-panel-row"
                            onClick={handleEverywhereChange}>
                            <div className="post-options-panel-option-label" style={{ color: theme.foregroundColor }}>
                                Anywhere (default)
                            </div>
                            <ModalCheckbox
                                value={post[`${prefix}Everywhere`]}
                                onChange={() => handleChange(`${prefix}Everywhere`, !post[`${prefix}Everywhere`])}
                            />
                        </div>

                        <div
                            className="post-options-panel-row"
                            onClick={handleLocationsChange}>
                            <div className="post-options-panel-option-label" style={{ color: theme.foregroundColor }}>
                                Selected Locations
                            </div>
                            <ModalCheckbox
                                value={post[`${prefix}Locations`]}
                                onChange={() => handleChange(`${prefix}Locations`, !post[`${prefix}Locations`])}
                            />
                        </div>

                        <div
                            className="post-options-panel-option-summary-row"
                            onClick={handleLocationSelect}
                            style={{ 
                                color: theme.foregroundColorFaded,  
                            }}>
                            {getLocationsPhrase(post[`${prefix}SelectedLocations`], "All Locations")}
                            <RightIcon color={theme.foregroundColorFaded} width="14" height="14" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PostOptionsPanel;