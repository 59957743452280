import React from 'react';

const PauseIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M560-240v-480h140v480H560Zm-300 0v-480h140v480H260Z" />
  </svg>

);

export default PauseIcon;
