import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Utilities
import { ItemType } from '../../common/utilities/Enums';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load items for a specific profile.
 */
const usePreloadProfileItems = () => {

  // Global
  const {
    profile,
    setProfileItems,
    setProfileEvents
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected profile
    if (!profile || !profile.key) {
      setProfileItems([]);
      return;
    }

    const load = () => {
      const handleUpdate = (items) => {
        setProfileItems(items);

        // Load events from the items
        const events = items.filter(item => item.type === ItemType.EVENT);
        setProfileEvents(events);
      };

      const params = [
        /*{ field: "shared", operator: "==", value: false },*/
        { field: "profileKey", operator: "==", value: profile.key },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.items,
        profile.key,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    const unsubscribeItems = load();

    return () => {
      if (unsubscribeItems) {
        unsubscribeItems();
      }
    };
  }, [profile, setProfileItems, setProfileEvents]);

};

export default usePreloadProfileItems;
