import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { HomeToolbarKey, SectionType, ThemeMode } from '../../utilities/Enums';

// Styles
import './MenuBar.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarFilledIcon from '../../svg/CalendarFilledIcon';
import CalendarIcon from '../../svg/CalendarIcon';
import DarkModeIcon from '../../svg/DarkModeIcon';
import FriendsIcon from '../../svg/FriendsIcon';
import FriendsFilledIcon from '../../svg/FriendsFilledIcon';
import HomeIcon from '../../svg/HomeIcon';
import HomeFilledIcon from '../../svg/HomeFilledIcon';
import InboxFilledIcon from '../../svg/InboxFilledIcon';
import InboxIcon from '../../svg/InboxIcon';
import LogoutIcon from '../../svg/LogoutIcon';
import PinIcon from '../../svg/PinIcon';
import PinFilledIcon from '../../svg/PinFilledIcon';
import SearchIcon from '../../svg/SearchIcon';
import SettingsFilledIcon from '../../svg/SettingsFilledIcon';
import SettingsIcon from '../../svg/SettingsIcon';
import SongIcon from '../../svg/SongIcon';

// Components
import AddButton from '../addbutton/AddButton';
import MenuBarButton from './button/MenuBarButton';
import PostSuccessful from '../../../common/components/postsuccessful/PostSuccessful';
import PostWizard from '../../../common/items/post/wizard/PostWizard';
import ProfileDot from '../profiledot/ProfileDot';
import ProfileModal from '../../../common/components/profilemodal/ProfileModal.js';

const MenuBar = () => {

    // Theme
    const {
        currentThemeMode,
        setCurrentThemeMode,
    } = useTheme();

    // Global
    const {
        currentSection,
        profile,
        profileIncomingEngagements,
        setCurrentSection,
        setProfile,
        setSelectedHomeToolbarKey,
    } = useContext(Global);

    // Local State
    const [addPostOpen, setAddPostOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [postSuccessfulOpen, setPostSuccessfulOpen] = useState(false);

    const [profileModalOpen, setProfileModalOpen] = useState(false);

    /**
     * Handles click on the profile button.
     */
    const handleProfileClick = () => {
        setProfileModalOpen(true);
    };

    /**
     * Handles click on the pin button.
     */
    const handlePinClick = () => {
        setCurrentSection(SectionType.PINS);
    };

    /**
     * Handles click on the music manager button.
     */
    const handleMusicClick = () => {
        setCurrentSection(SectionType.MUSIC);
    };

    /**
     * Handles click on the folders button.
     */
    const handleHomeClick = () => {
        setCurrentSection(SectionType.HOME);
    };

    /**
     * Handles click on the inbox button.
     */
    const handleInboxClick = () => {
        setCurrentSection(SectionType.INBOX);
    };

    /**
     * Handles click on the friends button.
     */
    const handleFriendsClick = () => {
        console.log("Friends Clicked");
        setCurrentSection(SectionType.FRIENDS);
    };

    /**
     * Handles click on the calendar button, which opens the calendar.
     */
    const handleCalendarClick = () => {
        setCurrentSection(SectionType.CALENDAR);
    };

    /**
     * Handles click on the dark mode toggle button.
     */
    const handleDarkModeClick = () => {
        const newTheme = currentThemeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        setCurrentThemeMode(newTheme);
    };

    /**
     * Handles click on the settings button.
     */
    const handleSettingsClick = () => {
        setCurrentSection(SectionType.SETTINGS);
    };

    /**
     * Handles click on the log out button.
     */
    const handleLogoutClick = async () => {

        await signOut(auth);

        setProfile(null);
        return;
    };

    // Post
    const handleAddPostClick = () => {
        setAddPostOpen(true);
    };

    const handleSaveComplete = (item) => {

        // Navigate to the home section
        setCurrentSection(SectionType.HOME);

        // Set the current home tab to FORYOU
        setSelectedHomeToolbarKey(HomeToolbarKey.FORYOU);

        setSelectedItem(item);

        setPostSuccessfulOpen(true);
    };

    const getUnreadCount = () => {
        return profileIncomingEngagements
            .filter(engagement => !engagement.isRead && (engagement.targetKey === profile.key))
            .length;
    };

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* CONTAINER */}
            <div className="menu-bar-container">

                {/* TOP */}
                <div className="menu-bar-top"
                    style={
                        isMobile
                            ? {
                                overflowY: "scroll",
                                msOverflowStyle: "none", // IE and Edge
                                scrollbarWidth: "none"  // Firefox
                            }
                            : {}
                    }>

                    {/* PROFILE */}
                    <div className="menu-bar-dot-wrapper"
                        onClick={handleProfileClick}>
                        <ProfileDot
                            item={profile}
                            size={40}
                        />
                    </div>

                    {/* HOME */}
                    <MenuBarButton
                        title="Home"
                        icon={HomeIcon}
                        selectedIcon={HomeFilledIcon}
                        selected={currentSection === SectionType.HOME}
                        onClick={handleHomeClick}
                    />

                    {/* FRIENDS */}
                    <MenuBarButton
                        title="Friends"
                        icon={FriendsIcon}
                        selectedIcon={FriendsFilledIcon}
                        selected={currentSection === SectionType.FRIENDS}
                        onClick={handleFriendsClick}
                    />

                    {/* INBOX */}
                    <MenuBarButton
                        title="Inbox"
                        icon={InboxIcon}
                        selectedIcon={InboxFilledIcon}
                        selected={currentSection === SectionType.INBOX}
                        onClick={handleInboxClick}
                        count={getUnreadCount()}
                    />

                    {/* CALENDAR */}
                    <MenuBarButton
                        title="Calendar"
                        icon={CalendarIcon}
                        selectedIcon={CalendarFilledIcon}
                        selected={currentSection === SectionType.CALENDAR}
                        onClick={handleCalendarClick}
                    />

                    {/* ADD BUTTON */}
                    <div className="menu-bar-add-button">
                        <AddButton
                            title="Create Post"
                            onClick={handleAddPostClick}
                            size={40}
                        />
                    </div>

                </div>

                {/* BOTTOM */}
                <div className="menu-bar-bottom">

                    {/* SEARCH */}
                    <MenuBarButton
                        title="Search"
                        icon={SearchIcon}
                        onClick={() => console.log("Search")}
                    />

                    {/* MUSIC MANAGER */}
                    {profile && (profile.username === process.env.REACT_APP_MUSIC_MANAGER_USERNAME) && (
                        <MenuBarButton
                            title="Music Manager"
                            icon={SongIcon}
                            selectedIcon={SongIcon}
                            selected={currentSection === SectionType.MUSIC}
                            onClick={handleMusicClick}
                        />
                    )}

                    {/* PIN */}
                    <MenuBarButton
                        title="Pin"
                        icon={PinIcon}
                        selectedIcon={PinFilledIcon}
                        selected={currentSection === SectionType.PINS}
                        onClick={handlePinClick}
                    />

                    {/* DARK MODE */}
                    <MenuBarButton
                        title="Dark Mode"
                        icon={DarkModeIcon}
                        onClick={handleDarkModeClick}
                    />

                    {/* SETTINGS */}
                    <MenuBarButton
                        title="Settings"
                        icon={SettingsIcon}
                        selectedIcon={SettingsFilledIcon}
                        selected={currentSection === SectionType.SETTINGS}
                        onClick={handleSettingsClick}
                    />

                    {/* SIGN OUT */}
                    <MenuBarButton
                        title="Sign Out"
                        icon={LogoutIcon}
                        selectedIcon={LogoutIcon}
                        onClick={handleLogoutClick}
                    />

                </div>

            </div>

            {/* POST WIZARD */}
            <PostWizard
                isOpen={addPostOpen}
                setOpen={setAddPostOpen}
                onSave={handleSaveComplete}
            />

            {/* POST SUCCESSFUL */}
            <PostSuccessful
                item={selectedItem}
                isOpen={postSuccessfulOpen}
                setOpen={setPostSuccessfulOpen}
            />

            {/* PROFILE */}
            <ProfileModal
                profile={profile}
                isOpen={profileModalOpen}
                setOpen={setProfileModalOpen}
            />

        </>
    );
};

export default MenuBar;
