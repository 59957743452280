import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../Global.js';

// Firebase
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collections, db, storage } from '../../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, Timestamp } from 'firebase/firestore';

// Utilities
import { ItemType } from '../../utilities/Enums.js';
import { formatDuration } from '../../../common/utilities/Formatters';
import { generateKey } from '../../../common/utilities/Keys';
import { getDisplayProfile } from '../../utilities/Profiles.js';

// Styles
import './MusicManager.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Components
import MusicManagerToolbar from './toolbar/MusicManagerToolbar.js';
import Section from '../../components/section/Section.js';
import Modal from '../../../common/components/modal/Modal';

// Managers
import DataManager from '../../../common/managers/DataManager';
const dataManager = new DataManager();

const MusicManager = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const { profile } = useContext(Global);

  // Local State
  const [songs, setSongs] = useState([]);
  const [newSong, setNewSong] = useState({
    title: '',
    artist: '',
    coverFile: null,
    songFile: null,
    page: '',
  });
  const [editSong, setEditSong] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch songs on mount
  useEffect(() => {
    fetchSongs();
  }, []);

  // Fetch songs from Firestore
  const fetchSongs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, collections.songs));
      const songList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSongs(songList);
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  // Handle input changes for new song
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSong(prev => ({ ...prev, [name]: value }));
  };

  // Handle file changes for new song
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewSong(prev => ({ ...prev, [name]: files[0] }));
  };

  // Handle input changes for editing song
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditSong(prev => ({ ...prev, [name]: value }));
  };

  // Handle file changes for editing song
  const handleEditFileChange = (e) => {
    const { name, files } = e.target;
    setEditSong(prev => ({ ...prev, [name]: files[0] }));
  };

  // Add song to Firebase
  const addSong = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const coverRef = ref(storage, `covers/${newSong.coverFile.name}`);
      await uploadBytes(coverRef, newSong.coverFile);
      const coverUrl = await getDownloadURL(coverRef);

      const songRef = ref(storage, `songs/${newSong.songFile.name}`);
      await uploadBytes(songRef, newSong.songFile);
      const songUrl = await getDownloadURL(songRef);

      const audio = new Audio(songUrl);
      const duration = await new Promise((resolve) => {
        audio.addEventListener('loadedmetadata', () => resolve(audio.duration));
      });

      const key = generateKey();
      const now = Timestamp.now();

      const songData = {
        key: key,
        type: ItemType.SONG,
        profileKey: profile.key,
        profile: getDisplayProfile(profile),
        title: newSong.title,
        artist: newSong.artist,
        page: newSong.page,
        duration: duration,
        coverUrl: coverUrl,
        url: songUrl,
        dateCreated: now,
        dateModified: now,
      };

      await dataManager.add(collections.songs, profile.key, key, songData);
      setNewSong({ title: '', artist: '', coverFile: null, songFile: null, page: '' });
      setIsAddModalOpen(false);
      fetchSongs();
    } catch (error) {
      console.error('Error adding song:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update song in Firebase
  const updateSong = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let coverUrl = editSong.coverUrl;
      let songUrl = editSong.url;
      let duration = editSong.duration;

      if (editSong.coverFile) {
        await deleteObject(ref(storage, editSong.coverUrl));
        const coverRef = ref(storage, `covers/${editSong.coverFile.name}`);
        await uploadBytes(coverRef, editSong.coverFile);
        coverUrl = await getDownloadURL(coverRef);
      }

      if (editSong.songFile) {
        await deleteObject(ref(storage, editSong.url));
        const songRef = ref(storage, `songs/${editSong.songFile.name}`);
        await uploadBytes(songRef, editSong.songFile);
        songUrl = await getDownloadURL(songRef);
        
        const audio = new Audio(songUrl);
        duration = await new Promise((resolve) => {
          audio.addEventListener('loadedmetadata', () => resolve(audio.duration));
        });
      }

      const updatedSong = {
        ...editSong,
        coverUrl,
        url: songUrl,
        duration,
        dateModified: Timestamp.now(),
        coverFile: null,
        songFile: null,
      };

      await dataManager.update(collections.songs, profile.key, editSong.key, updatedSong);
      setEditSong(null);
      fetchSongs();
    } catch (error) {
      console.error('Error updating song:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete song
  const deleteSong = async (songId, coverUrl, songUrl) => {
    try {
      const coverRef = ref(storage, coverUrl);
      const songRef = ref(storage, songUrl);
      await Promise.all([
        deleteObject(coverRef),
        deleteObject(songRef)
      ]);

      await deleteDoc(doc(db, collections.songs, songId));
      fetchSongs();
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  return (
    <Section>
      <MusicManagerToolbar />

      <div className="music-manager-container">
        {/* Song List Header */}
        <div className="song-list-header">
          <button 
            onClick={() => setIsAddModalOpen(true)}
            className="add-song-button"
          >
            Add Song
          </button>
        </div>

        {/* Song List */}
        <div className="song-list">
          {songs.map(song => (
            <div key={song.id} className="song-item-container">
              <img src={song.coverUrl} alt={song.title} className="song-item-cover" />
              <div className="song-item-details">
                <h3>{song.title}</h3>
                <p>{song.artist}</p>
                <p>{formatDuration(song.duration)}</p>
              </div>
              <button 
                onClick={() => setEditSong({ ...song, coverFile: null, songFile: null })} 
                className="edit-button"
              >
                Edit
              </button>
              <button 
                onClick={() => deleteSong(song.id, song.coverUrl, song.url)} 
                className="delete-button"
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {/* Add Song Modal */}
        <Modal
          title="Add Song"
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          height={500}
          width={400}
        >
          <form onSubmit={addSong} className="song-form">
            <input 
              type="text" 
              name="title" 
              value={newSong.title} 
              onChange={handleInputChange} 
              placeholder="Song Title" 
              required 
            />
            <input 
              type="text" 
              name="artist" 
              value={newSong.artist} 
              onChange={handleInputChange} 
              placeholder="Artist" 
              required 
            />
            <input 
              type="text" 
              name="page" 
              value={newSong.page} 
              onChange={handleInputChange} 
              placeholder="Page" 
              required 
            />
            <input 
              type="file" 
              name="coverFile" 
              onChange={handleFileChange} 
              accept="image/*" 
              required 
            />
            <input 
              type="file" 
              name="songFile" 
              onChange={handleFileChange} 
              accept="audio/*" 
              required 
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Uploading...' : 'Add Song'}
            </button>
          </form>
        </Modal>

        {/* Edit Song Modal */}
        {editSong && (
          <Modal
            title="Edit Song"
            isOpen={!!editSong}
            onClose={() => setEditSong(null)}
            height={500}
            width={400}
          >
            <form onSubmit={updateSong} className="song-form">
              <input
                type="text"
                name="title"
                value={editSong.title}
                onChange={handleEditInputChange}
                placeholder="Song Title"
                required
              />
              <input
                type="text"
                name="artist"
                value={editSong.artist}
                onChange={handleEditInputChange}
                placeholder="Artist"
                required
              />
              <input
                type="text"
                name="page"
                value={editSong.page}
                onChange={handleEditInputChange}
                placeholder="Page"
                required
              />
              <input
                type="file"
                name="coverFile"
                onChange={handleEditFileChange}
                accept="image/*"
              />
              <input
                type="file"
                name="songFile"
                onChange={handleEditFileChange}
                accept="audio/*"
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </form>
          </Modal>
        )}
      </div>
    </Section>
  );
};

export default MusicManager;