import React from 'react';

const PinFilledIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M480-230q-118.69-97.08-179.35-190.85Q240-514.62 240-599.38q0-105.77 67.23-173.2Q374.46-840 480-840t172.77 67.42Q720-705.15 720-599.38q0 84.76-60.65 178.15Q598.69-327.85 480-230Zm.14-305.38q26.71 0 45.59-19.03 18.89-19.02 18.89-45.73 0-26.71-19.03-45.59-19.02-18.89-45.73-18.89-26.71 0-45.59 19.03-18.89 19.02-18.89 45.73 0 26.71 19.03 45.59 19.02 18.89 45.73 18.89ZM240-120v-40h480v40H240Z" />
  </svg>

);

export default PinFilledIcon;
