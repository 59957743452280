import { useEffect } from 'react';

// Utilities
import { getDisplayProfile } from '../../../../../common/utilities/Profiles.js';

/**
 * Custom hook to initialize post state based on whether we are adding or editing.
 * 
 * @param {Object} item - The existing post item. Null if creating a new post.
 * @param {Object} defaultPost - The default post object.
 * @param {Object} profile - The profile object containing user information.
 * @param {boolean} isOpen - Indicates if the modal is open.
 * @param {Function} setPost - Function to set the post state.
 */
const useInitializePost = ({ item, defaultPost, profile, isOpen, setPost }) => {

  useEffect(() => {

    if (isOpen) {

      if (item) {
        setPost({
          ...item
        });
      } else {
        setPost({
          ...defaultPost,
          profileKey: profile.key,
          profile: getDisplayProfile(profile),
        });
      }

    } else {

      // Use the default post when the modal is closed
      setPost(defaultPost);

    }
  }, [isOpen, setPost, defaultPost]);

};

export default useInitializePost;