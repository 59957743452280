import React from 'react';

const MuteOffIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M563.08-180.23v-42q83.07-29.08 133.84-100 50.77-70.92 50.77-158.77 0-87.85-50.77-158.77-50.77-70.92-133.84-100v-42q99.38 31.85 162 114.35 62.61 82.5 62.61 186.42 0 103.92-62.61 186.42-62.62 82.5-162 114.35ZM172.31-400v-160h136.92l143.08-143.08v446.16L309.23-400H172.31Zm390.77 52.31v-266.62q33.92 22 51.57 57.93 17.66 35.92 17.66 76.38 0 40.23-18.04 75.27t-51.19 57.04ZM412.31-606l-86 86h-114v80h114l86 86v-252Zm-100 126Z" />
  </svg>

);

export default MuteOffIcon;
