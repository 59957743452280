import React from 'react';

const DislikeIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M144.62-360q-25.08 0-44.85-19.77Q80-399.54 80-424.62v-49.23q0-5.59 1.23-11.99 1.23-6.39 3.23-11.85l109.27-259.02q8.19-18.37 27.65-30.83Q240.85-800 261.82-800h401.26v440L437.69-136.62q-8.84 8.85-19.73 10.97-10.88 2.11-20.65-3.27-9.77-5.39-14.16-15.7-4.38-10.3-1.69-21.61L422.62-360h-278Zm478.46-17.08V-760H261.54q-8.46 0-17.31 4.62-8.85 4.61-13.46 15.38L120-480v55.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h327.69L426-181.54l197.08-195.54ZM775.38-800q26.66 0 45.64 18.98T840-735.38v310.76q0 26.66-18.98 45.64T775.38-360h-112.3v-40h112.3q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-310.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92h-112.3v-40h112.3Zm-152.3 40v382.92V-760Z" />
    </svg>

);

export default DislikeIcon;
