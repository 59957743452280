import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Utilities
import { EngagementType } from '../../../common/utilities/Enums';
import { generateKey } from '../../../common/utilities/Keys';

// Styles
import './Profile.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import BookmarkIcon from '../../../common/svg/BookmarkIcon';
import Grid from '../../../common/components/grid/Grid';
import Item from '../../../common/items/Item';
import LikeIcon from '../../../common/svg/LikeIcon';
import LockIcon from '../../../common/svg/LockIcon';
import PublicIcon from '../../../common/svg/PublicIcon';

// Components
import ItemViewer from '../../items/itemviewer/ItemViewer.js';
import ProfileButtons from './buttons/ProfileButtons';
import ProfilePhoto from './photo/ProfilePhoto';
import ProfileStats from './stats/ProfileStats';
import ProfileTabs from './tabs/ProfileTabs';

// Preload Hooks
import usePreloadFullProfile from './hooks/PreloadFullProfile.js';
import usePreloadItems from './hooks/PreloadItems.js';

// Managers
import DataManager from '../../../common/managers/DataManager';
const dataManager = new DataManager();

const Profile = ({ viewingProfile }) => {

    // Themes
    const { theme } = useTheme();

    // Global
    const {
        profile,
        profileEngagements,
    } = useContext(Global);

    // Local State
    const [tabsData, setTabsData] = useState([]);
    const [privateItems, setPrivateItems] = useState([]);
    const [publicItems, setPublicItems] = useState([]);
    const [likes, setLikes] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [itemViewerOpen, setItemViewerOpen] = useState(false);

    const [fullProfile, setFullProfile] = useState(false);

    // Hooks
    usePreloadFullProfile(viewingProfile, setFullProfile);
    usePreloadItems(viewingProfile, setPrivateItems, setPublicItems);

    useEffect(() => {
        // Liked
        const foundLikes = profileEngagements.filter(engagement =>
            engagement.profileKey === viewingProfile.key &&
            engagement.type === EngagementType.POST_LIKE);
        const likeSummaries = foundLikes.map(like => like.itemSummary);
        setLikes(likeSummaries);

        const foundBookmarks = profileEngagements.filter(engagement =>
            engagement.profileKey === viewingProfile.key &&
            engagement.type === EngagementType.POST_BOOKMARK);
        const bookmarkSummaries = foundBookmarks.map(bookmark => bookmark.itemSummary);
        setBookmarks(bookmarkSummaries);
    }, [profileEngagements, viewingProfile.key]);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        setItemViewerOpen(true);
    };

    useEffect(() => {

        setTabsData([
            {
                title: 'Private',
                icon: LockIcon,
                content: <Grid
                    items={privateItems}
                    renderItem={(item) => (

                        <Item
                            key={item.key}
                            item={item}
                            onClick={() => handleItemClick(item)}
                        />

                    )}
                    loading={false}
                    minCols={1}
                    colWidth={170}
                />,
            },
            {
                title: 'Public',
                icon: PublicIcon,
                content: <Grid
                    items={publicItems}
                    renderItem={(item) => (

                        <Item
                            key={item.key}
                            item={item}
                            onClick={() => handleItemClick(item)}
                        />

                    )}
                    loading={false}
                    minCols={1}
                    colWidth={170}
                />,
            },
            {
                title: 'Bookmarks',
                icon: BookmarkIcon,
                content: <Grid
                    items={bookmarks}
                    renderItem={(item) => (

                        <Item
                            key={item.key}
                            item={item}
                            onClick={() => handleItemClick(item)}
                        />

                    )}
                    loading={false}
                    minCols={1}
                    colWidth={170}
                />,
            },
            {
                title: 'Likes',
                icon: LikeIcon,
                content: <Grid
                    items={likes}
                    renderItem={(item) => (

                        <Item
                            key={item.key}
                            item={item}
                            onClick={() => handleItemClick(item)}
                        />

                    )}
                    loading={false}
                    minCols={1}
                    colWidth={170}
                />,
            },
        ]);

    }, [publicItems, bookmarks, likes, privateItems]);

    /**
     * Handles click on the edit button.
     */
    const handleEditClick = () => {

    };

    /**
     * Handles click on the share button.
     */
    const handleShareClick = () => {

    };

    /**
     * Handles click on the add friend button.
     */
    const handleAddFriendClick = () => {

    };

    /**
     * Handles click on the follow button.
     */
    const handleFollowClick = async () => {
        const key = generateKey();

        const followerDisplayProfile = {
            key: profile.key,
            username: profile.username,
            photo: profile.photo,
        };

        const followingDisplayProfile = {
            key: viewingProfile.key,
            username: viewingProfile.username,
            photo: viewingProfile.photo,
        };

        const data = {
            key: key,
            followerKey: profile.key,
            followerProfile: followerDisplayProfile,
            followingKey: viewingProfile.key,
            followingProfile: followingDisplayProfile,
        };

        await dataManager.add(collections.follows, profile.key, key, data);
    };

    /**
     * Handles click on the unfollow button.
     */
    const handleUnfollowClick = async () => {
        const params = [
            { field: 'followerKey', operator: '==', value: profile.key },
            { field: 'followingKey', operator: '==', value: viewingProfile.key }
        ];

        await dataManager.delete(collections.follows, profile.key, null, params);
    };

    // Validate profile
    if (!viewingProfile) return null;

    return (

        <div className="profile-container">

            {/* PHOTO */}
            <div className="profile-photo-wrapper">
                <ProfilePhoto
                    item={viewingProfile}
                    size={150}
                />
            </div>

            {/* NAME */}
            <div className="profile-name-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                {viewingProfile.username}
            </div>

            {/* STATS */}
            <div className="profile-stats-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                <ProfileStats
                    viewingProfile={fullProfile}
                />
            </div>

            {/* BUTTONS */}
            <div className="profile-buttons-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>
                <ProfileButtons
                    viewingProfile={viewingProfile}
                    onEditClick={handleEditClick}
                    onShareClick={handleShareClick}
                    onAddFriendClick={handleAddFriendClick}
                    onFollowClick={handleFollowClick}
                    onUnfollowClick={handleUnfollowClick}
                />
            </div>

            {/* TABS */}
            <div className="profile-tabs-wrapper"
                style={{
                    color: theme.foregroundColor,
                }}>

                <ProfileTabs
                    tabsData={tabsData}
                />

            </div>

            {/* ITEM VIEWER */}
            <ItemViewer
                item={selectedItem}
                isOpen={itemViewerOpen}
                setOpen={setItemViewerOpen}
            />

        </div>

    );
};

export default Profile;
