import React, { useEffect, useState } from 'react';

import { hexToRgba } from '../../../../utilities/Colors';
import { ItemSize, ThemeMode } from '../../../../utilities/Enums';

// Styles
import './PhotoGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import ImageIcon from '../../../../svg/ImageIcon';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';

/**
 * PhotoGridItem
 * 
 * A grid item for displaying photos.
 * 
 * @param {Object} item The photo item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click photo handler.
 * @param {Function} onDoubleClick The double click photo handler.
 * @param {Function} onDragStart The drag start photo handler.
 * 
 * @returns {JSX.Element} PhotoGridItem component.
 */
const PhotoGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Local State
    const [backgroundUrl, setBackgroundUrl] = useState(null);

    useEffect(() => {
        const items = item.items;
        if (items) {
            const photos = items.filter(item => item.type === "PHOTO");
            if (photos.length > 0) {
                setBackgroundUrl(photos[0].url);
            }
        } else {
            if (item.url) {
                setBackgroundUrl(item.url);
            }
        }

    }, [item]);

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(photo) => {
                if (onClick) onClick(photo, item);
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(photo) => {
                if (onDragStart) onDragStart(photo, item);
            }}
            size={size}>

            <div className="photo-grid-item-container"
                title={item.title}>

                {/* IMAGE/ICON */}
                {backgroundUrl ? (
                    <div className="photo-grid-item-image-container">
                        <img 
                            alt={item.title}
                            className="photo-grid-item-image"
                            src={backgroundUrl} />
                    </div>
                ) : (
                    <div className="photo-grid-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <ImageIcon
                            color={theme.highlightBackgroundColor}
                            width="110"
                            height="110"
                        />
                    </div>
                )}

                {/* FOOTER */}
                {(item.items &&
                    ((item.title && item.title.length > 0) || (item.description && item.description.length > 0))) &&

                    <div className="photo-grid-item-footer"
                        style={{
                            backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.5)
                        }}>

                        <div className="photo-grid-item-title"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {item.title}
                        </div>

                    </div>

                }

            </div>

        </GridItem>
    );
};

export default PhotoGridItem;
