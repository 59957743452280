import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global.js';

// Styles
import './LocationSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Images
import CheckOnIcon from '../../svg/CheckOnIcon.jsx';
import ExplorePinIcon from '../../svg/ExplorePinIcon.jsx';

// Components
import Pin from '../../sections/pins/pin/Pin.js';

const LocationSelector = ({ title, post, handleChange, prefix }) => {
  // Theme
  const { theme } = useTheme();

  // Global
  const { profilePins } = useContext(Global);

  // Use prefixed key from post (e.g., "privacySelectedLocations")
  const selectedLocations = post[`${prefix}SelectedLocations`];

  const handlePinClick = (pin) => {
    const isSelected = selectedLocations.some(p => p.key === pin.key);

    if (!isSelected) {
      // Add pin to the selected list
      handleChange(`${prefix}SelectedLocations`, [...selectedLocations, pin]);
    } else {
      // Remove pin from the selected list
      handleChange(
        `${prefix}SelectedLocations`,
        selectedLocations.filter(p => p.key !== pin.key)
      );
    }
  };

  return (
    <div className="privacy-pins-container">
      {/* TITLE */}
      <div
        className="privacy-pins-list-title"
        style={{ color: theme.foregroundColor }}
      >
        {title}
      </div>

      {/* LIST */}
      <div className="privacy-pins-list">
        {profilePins.map(pin => (
          <div className="privacy-pins-item" key={pin.key}>
            {/* INFO */}
            <div className="privacy-pins-item-name">
              <Pin
                key={pin.key}
                icon={ExplorePinIcon}
                pin={pin}
                onClick={() => handlePinClick(pin)}
              />
            </div>

            {/* CHECK */}
            <div className="privacy-pins-item-checkbox">
              {selectedLocations.some(p => p.key === pin.key) && (
                <CheckOnIcon
                  color={theme.highlightBackgroundColor}
                  width="20"
                  height="20"
                  checkColor={theme.highlightForegroundColor}
                />
              )}
            </div>
          </div>
        ))}

        {/* NO PINS MESSAGE */}
        {profilePins.length === 0 && (
          <div
            style={{
              color: theme.foregroundColorFaded,
              padding: '10px',
            }}
          >
            No pinned locations found. You can add pins in Settings.
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSelector;