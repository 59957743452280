import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { ItemType } from '../utilities/Enums';
import { generateKey } from '../utilities/Keys';

// Styles
import './NoteForm.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../components/modal/Modal';

const NoteForm = ({ isOpen, setOpen, onAdded }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        profile,
        showProgress,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");

    // Event Handlers
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleTextChange = (e) => setText(e.target.value);

    /**
     * Method to handle posting to the selected channel.
     *
     * @param {string} e - Click event.
     */
    const handleSave = async () => {

        showProgress("Posting...");

        const now = Timestamp.now();

        const key = generateKey();

        // Create the new post
        const data = {
            key: key,
            profileKey: profile.key,
            type: ItemType.NOTE,
            title: title,
            text: text,
            dateCreated: now
        };

        onAdded(data);

        hideProgress();

        setOpen(false);

        // Reset form fields
        setTitle("");
        setText("");
    };

    return (
        <Modal
            title="Add a Text Slide"
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            width={isMobile ? "100%" : "95%"}
            height={isMobile ? "100%" : "95%"}>

            {/* CONTAINER */}
            <div className="note-form-container">

                {/* TITLE WRAPPER */}
                <div className="note-form-title-wrapper">

                    {/* CENTER SECTION */}
                    <div className="note-form-title-left">

                        {/* TITLE */}
                        <textarea
                            name="post"
                            className="note-form-title-textarea"
                            placeholder="Title..."
                            style={{
                                backgroundColor: theme.backgroundColor,
                                borderColor: theme.backgroundColor,
                                color: theme.foregroundColor,
                                fontFamily: profile?.fontFamily || "Urbanist",
                                maxHeight: '200px' // Set max height
                            }}
                            onChange={handleTitleChange}
                            value={title}
                            rows={1}
                        />

                    </div>

                    {/* SAVE BUTTON */}
                    <div className="note-form-title-right">

                        <div className="note-form-save-button-wrapper"
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor,
                            }}
                            onClick={handleSave}>
                            Save
                        </div>

                    </div>

                </div>

                {/* CONTENT */}
                <div className="note-form-content-wrapper">

                    <textarea
                        name="text"
                        className="note-form-content"
                        placeholder="Enter text..."
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: profile?.fontFamily || "Urbanist",
                        }}
                        onChange={handleTextChange}
                        value={text}
                        rows={1}
                    />

                </div>

            </div>

        </Modal>
    );
};

export default NoteForm;
