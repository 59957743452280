import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Styles
import './PostOptionsMoreButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import RightIcon from '../../../../../../common/svg/RightIcon';

const PostOptionsMoreButton = ({ label, summary = "", onClick }) => {

  // Theme
  const { theme } = useTheme();

  const {
  } = useContext(Global);

  return (
    <div className="post-options-more-button-container"
      onClick={onClick}>

      <div className="post-options-more-button-top">

        <div className="post-options-more-button-label"
          style={{
            color: theme.foregroundColor,
          }}>
          {label}
        </div>

        <RightIcon
          color={theme.foregroundColor}
          width="22"
          height="22"
        />
        
      </div>
      
      <div className="post-options-more-button-bottom"
        style={{
          color: theme.foregroundColorFaded,
        }}>
        {summary}
      </div>


    </div>
  );
};

export default PostOptionsMoreButton;