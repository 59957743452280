import React from 'react';

// Styles
import './CommentsPanel.css';

// Theme
import { useTheme } from '../../../../../ThemeContext.js';

// Images
import CloseIcon from '../../../../svg/CloseIcon.jsx';

// Components
import Comments from '../Comments.js';

/**
 * CommentsPanel component
 * 
 * Renders a popup for displaying comments.
 */
const CommentsPanel = ({ 
    commentsVisible,
    comments,
    onClose,
 }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="comments-panel-container"
          style={{
            backgroundColor: theme.backgroundColor,
            borderLeftColor: theme.backgroundColorFaded,
            height: '100%',
            width: commentsVisible ? '350px' : '0px',
          }}>

          {/* HEADER */}
          <div className="comments-panel-header">

            {/* HEADER TEXT*/}
            <div className="comments-panel-header-text"
              style={{
                color: theme.foregroundColor
              }}>
              {comments.length} {comments.length === 1 ? 'comment' : 'comments'}
            </div>

            {/* COMMENTS CLOSE*/}
            <div className="comments-panel-header-close"
              onClick={onClose}>
              <CloseIcon
                color={theme.foregroundColor}
                width="14"
                height="14"
              />
            </div>

          </div>

          {/* COMMENTS */}
          <div className="comments-panel-wrapper">
            <Comments />
          </div>

        </div>

    );
};

export default CommentsPanel;
