import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { HomeToolbarKey } from '../../../../common/utilities/Enums';

// Styles
import './HomeToolbar.css';

// Components
import HomeToolbarButton from './button/HomeToolbarButton';

const HomeToolbar = () => {

    // Global
    const {
        selectedHomeToolbarKey,
        setSelectedHomeToolbarKey
    } = useContext(Global);

    return (
        <div className="home-toolbar"
            style={{
                position: isMobile ? "absolute" : "relative",
                top: isMobile ? "10px" : "8px",
                width: isMobile ? "100%" : "auto",
            }}>

            <HomeToolbarButton key={HomeToolbarKey.FORYOU} title="For You" selected={selectedHomeToolbarKey === HomeToolbarKey.FORYOU} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.FORYOU)} />
            <HomeToolbarButton key={HomeToolbarKey.UPCOMING} title="Upcoming" selected={selectedHomeToolbarKey === HomeToolbarKey.UPCOMING} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.UPCOMING)} />
            <HomeToolbarButton key={HomeToolbarKey.RECENT} title="Recent" selected={selectedHomeToolbarKey === HomeToolbarKey.RECENT} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.RECENT)} />
            <HomeToolbarButton key={HomeToolbarKey.FOLLOWING} title="Following" selected={selectedHomeToolbarKey === HomeToolbarKey.FOLLOWING} onClick={() => setSelectedHomeToolbarKey(HomeToolbarKey.FOLLOWING)} />

        </div>
    );
};

export default HomeToolbar;
