import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Drag and drop
import { useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Utilities
import { SectionType } from '../../../../utilities/Enums';
import { formatDayAndDate } from '../../../../utilities/Formatters';

// Components
import CalendarCollectionHour from './CalendarCollectionHour';
import CalendarCollectionHourBlock from './CalendarCollectionHourBlock';
import CalendarCollectionEvent from './CalendarCollectionEvent';

// Styles
import './CalendarCollection.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Managers
import EventManager from '../../../../managers/EventManager';
import ObjectManager from '../../../../managers/ObjectManager';

const eventManager = new EventManager();
const objectManager = new ObjectManager();

const CalendarCollection = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        profileEvents,
        profileModels,
        profileObjects,
        profile,
        resetSelections,
        selectedCalendar,
        selectedCalendarTags,
        setActiveDate,
        setCalendarTitle,
        setCurrentObject,
        setCurrentSection,
        setProfileObjects,
        setSelectedModel,
        setSelectedObject,
    } = useContext(Global);

    // Local State
    const [appCollection, setAppCollection] = useState(null);
    const [todaysEvents, setTodaysEvents] = useState([]);
    const [displayEvents, setDisplayEvents] = useState([]);

    // References
    const mainRef = useRef(null);
    const headerRef = useRef(null);
    const firstColRef = useRef(null);

    // Constants
    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);
    const hourLabelWidth = 60;
    const headerHeight = 50;
    const columnWidth = 200;
    const rowHeight = 100;
    const segmentLength = 15; // Minutes per segment

    // Get the collection for the selected calendar
    useEffect(() => {
        if (!selectedCalendar || !selectedCalendar.collectionKey) return;
        const coll = profileModels.find(c => c.key === selectedCalendar.collectionKey);
        setAppCollection(coll);
    }, [selectedCalendar, profileModels]);

    // Get the objects for the model
    useEffect(() => {
        if (!profile || !appCollection) return;

        const handleUpdate = (items) => {
            setProfileObjects(items);
        };

        // Subscribe to real-time updates, optionally filtered by tags
        const unsubscribe = objectManager.listAndSubscribe(
            profile.key,
            appCollection.key,
            handleUpdate,
        );

        // Cleanup: Ensure that unsubscribe is a function before calling it
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };

    }, [profile, appCollection, setProfileObjects]);

    // Scroll to the current hour
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * rowHeight;
        mainRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (!selectedCalendar) return;

        if (todaysEvents && selectedCalendar) {
            setDisplayEvents(todaysEvents);
        }
    }, [todaysEvents, selectedCalendar, selectedCalendarTags]);

    useEffect(() => {
        if (!activeDate) {
            setActiveDate(new Date());
        } else {
            setCalendarTitle(formatDayAndDate(new Date(activeDate)));
        }
    }, [activeDate, setCalendarTitle, setActiveDate]);

    useEffect(() => {
        if (activeDate) {
            setTodaysEvents(eventManager.getEventsOnDate(profileEvents, activeDate));
        }
    }, [activeDate, profileEvents]);

    const handleObjectTitleClick = (object) => {
        resetSelections();
        const collection = profileModels.find(c => c.key === object.modelKey);
        setCurrentSection(SectionType.COLLECTIONS);
        setSelectedModel(collection);
        setCurrentObject(object);
        setSelectedObject(object);
    };

    const handleMainScroll = (e) => {
        const { scrollLeft, scrollTop } = e.target;
        if (headerRef.current) {
            headerRef.current.scrollLeft = scrollLeft;
        }
        if (firstColRef.current) {
            firstColRef.current.scrollTop = scrollTop;
        }
    };

    const renderColumnHeaderCell = (object, rowIndex, colIndex) => {
        const titleFieldKey = appCollection.titleFieldKey;
        const title = object[titleFieldKey];

        return (
            <div key={`${rowIndex}-${colIndex}`}
                onClick={() => handleObjectTitleClick(object)}
                className="calendar-collection-column-header-cell"
                style={{
                    width: `${columnWidth}px`,
                    height: `${headerHeight}px`,
                    flex: `0 0 ${columnWidth}px`,
                }}>
                {title}
            </div>
        );
    };

    const renderRowHeaderCell = (hour, rowIndex, colIndex) => {
        return (
            <div
                key={`${rowIndex}-${colIndex}`}
                className="calendar-collection-row-header-cell"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    height: `${rowHeight}px`,
                    width: `${hourLabelWidth}px`,
                }}>
                <CalendarCollectionHour key={rowIndex} hour={hour} />
            </div>
        );
    };

    const renderCell = (object, rowIndex, colIndex) => {
        return (
            <div className="calendar-collection-cell" key={`${rowIndex}-${colIndex}`}
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    flex: `0 0 ${columnWidth}px`,
                    height: `${rowHeight}px`,
                    width: `${columnWidth}px`,
                }}>

                <CalendarCollectionHourBlock
                    object={object}
                    index={rowIndex}
                    segmentLength={segmentLength}
                    setDisplayEvents={setDisplayEvents}
                />
            </div>
        );
    };

    const renderColumnHeaderCells = () => {
        const cells = [];
        for (let col = 0; col < profileObjects.length; col++) {
            cells.push(renderColumnHeaderCell(profileObjects[col], 0, col));
        }
        return (
            <div className="calendar-collection-column-header" ref={headerRef}>
                {cells}
            </div>
        );
    };

    const renderRowHeaderCells = () => {
        const cells = [];
        for (let row = 0; row < hours.length; row++) {
            cells.push(renderRowHeaderCell(hours[row], row, 0));
        }
        return (
            <div className="calendar-collection-first-column" ref={firstColRef}>
                {cells}
            </div>
        );
    };

    const renderMainCells = () => {
        const rows = [];
        for (let row = 0; row < hours.length; row++) {
            const rowCells = [];
            for (let col = 0; col < profileObjects.length; col++) {
                rowCells.push(renderCell(profileObjects[col], row, col));
            }
            rows.push(
                <div className="calendar-collection-row" key={`row-${row}`}>
                    {rowCells}
                </div>
            );
        }
        return rows;
    };

    if (!profileObjects || !appCollection) return null;

    return (

        <DndProvider backend={HTML5Backend}>

            <div className="calendar-collection-container">

                {/* TOP-LEFT CELL */}
                <div className="calendar-collection-corner-container"
                    style={{
                        height: `${headerHeight}px`,
                        width: `${hourLabelWidth}px`,
                    }}></div>

                {/* COLUMN HEADER */}
                <div className="calendar-collection-column-header-container"
                    style={{
                        borderLeftColor: theme.backgroundColorFaded,
                        borderBottomColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor,
                        height: `${headerHeight}px`,
                        left: `${hourLabelWidth}px`,
                    }}>
                    {renderColumnHeaderCells()}
                </div>

                {/* ROW HEADER */}
                <div className="calendar-collection-row-header-container"
                    style={{
                        width: `${hourLabelWidth}px`,
                        top: `${headerHeight}px`,
                    }}>
                    {renderRowHeaderCells()}
                </div>

                {/* MAIN AREA */}
                <div
                    className="calendar-collection-main-area"
                    style={{
                        top: `${headerHeight}px`,
                        left: `${hourLabelWidth}px`,
                    }}
                    ref={mainRef}
                    onScroll={handleMainScroll}>
                    {renderMainCells()}

                    <div className="calendar-collection-events-overlay">
                        {displayEvents.map((event) => {
                            return event.eventObjects.map((evtObj) => {
                                // 1) Determine which column this object belongs to
                                const objectIndex = profileObjects.findIndex(o => o.key === evtObj.key);
                                if (objectIndex === -1) return null;

                                // 2) Convert the event’s Firebase timestamps to JS Dates
                                const startDateObj = event.startDate.toDate();
                                const endDateObj = event.endDate.toDate();

                                // 3) Extract local start hour + minute
                                const startHour = startDateObj.getHours();
                                const startMinutes = startDateObj.getMinutes();

                                // 4) Calculate the vertical starting position
                                //    e.g. 2:30 AM => 2.5 * rowHeight
                                const topOffset = (startHour + (startMinutes / 60)) * rowHeight;

                                // 5) Calculate duration (in hours) and thus the event height
                                const endHour = endDateObj.getHours();
                                const endMinutes = endDateObj.getMinutes();
                                const totalStartMinutes = (startHour * 60) + startMinutes;
                                const totalEndMinutes = (endHour * 60) + endMinutes;
                                const diffMinutes = totalEndMinutes - totalStartMinutes;
                                const diffHours = diffMinutes / 60;  // e.g. 90 min => 1.5 hours
                                const eventHeight = diffHours * rowHeight;

                                // 6) Compute the horizontal offset for this column
                                const leftOffset = objectIndex * columnWidth;

                                return (

                                    <CalendarCollectionEvent
                                        key={event.key}
                                        event={event}
                                        sourceObject={evtObj}
                                        top={topOffset}
                                        left={leftOffset}
                                        width={columnWidth}
                                        height={eventHeight}
                                        rowHeight={rowHeight}
                                        segmentLength={segmentLength}
                                        useDrag={useDrag}
                                    />
                                    
                                );
                            });
                        })}
                    </div>
                </div>
            </div>

        </DndProvider>

    );
};

export default CalendarCollection;
