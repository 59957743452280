import React from 'react';

// Utilities
import { ItemSize, ItemType } from '../../../../../common/utilities/Enums';
import { hexToRgba } from '../../../../../common/utilities/Colors';

// Styles
import './PostWizardPreview.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import AddAudioIcon from '../../../../../common/svg/AddAudioIcon';
import AddButtonIcon from '../../../../../common/svg/AddButtonIcon';
import AddEventIcon from '../../../../../common/svg/AddEventIcon';
import AddNoteIcon from '../../../../../common/svg/AddNoteIcon';
import AddPhotosIcon from '../../../../../common/svg/AddPhotosIcon';
import AddVideoIcon from '../../../../../common/svg/AddVideoIcon';

// Components
import AddMediaButton from '../../../../../common/items/common/addmediabutton/AddMediaButton';
import Item from '../../../Item';

const PostWizardPreview = ({ item, handleChange, onItemTypeSelect }) => {

    // Theme
    const { theme } = useTheme();

    const handleDeleteClick = (deleteItem) => {
        // Filter out the item to delete
        const newItems = item.items.filter((attached) => attached.key !== deleteItem.key);
        handleChange("items", newItems);
    };

    return (

        <div className="post-wizard-preview-container">

            <div className="post-wizard-preview-item">

                <div className="post-wizard-preview-overflow">

                    <div className="post-wizard-preview">

                        <Item
                            key={item.key}
                            item={item}
                            size={ItemSize.LARGE}
                        />

                    </div>

                </div>

            </div>

            <div className="post-wizard-preview-top">

                <div className="post-wizard-preview-buttons">

                    <AddMediaButton
                        icon={AddEventIcon}
                        label="Event"
                        onClick={() => onItemTypeSelect(ItemType.EVENT)}
                        enabled={item.items.some((attached) => attached.type === ItemType.EVENT) ? false : true}
                    />

                    <AddMediaButton
                        icon={AddPhotosIcon}
                        label="Photos"
                        onClick={() => onItemTypeSelect(ItemType.PHOTO)}
                    />

                    <AddMediaButton
                        icon={AddVideoIcon}
                        label="Video"
                        onClick={() => onItemTypeSelect(ItemType.VIDEO)}
                    />

                    <AddMediaButton
                        icon={AddAudioIcon}
                        label="Song"
                        onClick={() => onItemTypeSelect(ItemType.SONG)}
                        enabled={item.items.some((attached) => attached.type === ItemType.SONG) ? false : true}
                    />

                    <AddMediaButton
                        icon={AddNoteIcon}
                        label="Text"
                        onClick={() => onItemTypeSelect(ItemType.NOTE)}
                    />

                    <AddMediaButton
                        icon={AddButtonIcon}
                        label="Link"
                        onClick={() => onItemTypeSelect(ItemType.BUTTON)}
                    />

                </div>

            </div>

            <div className="post-wizard-preview-media"
                style={{
                    backgroundColor: hexToRgba(theme.backgroundColorFaded, 0.5),
                }}>
                {item.items && item.items.length > 0 ? (
                    <>
                        {item.items.map((attached) => (
                            <div key={attached.key}
                                className="post-wizard-preview-media-item">
                                <Item
                                    key={attached.key}
                                    item={attached}
                                    size={ItemSize.THUMB}
                                    onDelete={handleDeleteClick}
                                />
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="post-wizard-preview-no-slides"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        No slides yet
                    </div>
                )}
            </div>

        </div>

    );
};

export default PostWizardPreview;
