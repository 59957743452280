export const Screen = {
    SPLASH: 'SPLASH',
    MAIN: 'MAIN',
    WELCOME: 'WELCOME',
    RUN: 'RUN',
    PUBLIC: 'PUBLIC',
};

export const PinType = {
    HOME: "HOME",
    EXPLORE: "EXPLORE"
}

export const HomeToolbarKey = {
    UPCOMING: "UPCOMING",
    RECENT: "RECENT",
    FOLLOWING: "FOLLOWING",
    FORYOU: "FORYOU"
}

export const EngagementType = {
    POST_BOOKMARK: 'POST_BOOKMARK',
    POST_COMMENT: 'POST_COMMENT',
    POST_LIKE: 'POST_LIKE',
    POST_REPOST: 'POST_REPOST',
    POST_SHARE: 'POST_SHARE',
    COMMENT_LIKE: 'COMMENT_LIKE',
    COMMENT_DISLIKE: 'COMMENT_DISLIKE',
    COMMENT_REPLY: 'COMMENT_REPLY',
    CONTRIBUTION_VIEW: 'CONTRIBUTION_VIEW',
    CONTRIBUTION_GIFT: 'CONTRIBUTION_GIFT',
    PROFILE_VIEW: 'PROFILE_VIEW',
};

export const NotificationType = {

};

export const SettingType = {
    PHOTO: 'PHOTO',
    THEME: 'THEME',
    WALLPAPER: 'WALLPAPER',
    FONT: 'FONT',
    SIGNOUT: 'SIGNOUT',
    DELETE: 'DELETE',
};

export const ItemSize = {
    SMALL: 'SMALL',
    LARGE: 'LARGE',
    THUMB: 'THUMB',
};

export const SectionType = {
    // Menu
    PINS: 'PINS',
    HOME: 'HOME',
    FRIENDS: 'FRIENDS',
    INBOX: 'INBOX',
    ROOMS: 'ROOMS',
    CALENDAR: 'CALENDAR',
    TRASH: 'TRASH',
    MUSIC: 'MUSIC',

    ACCOUNT: 'ACCOUNT',
    BOOKMARKS: 'BOOKMARKS',
    FAVORITES: 'FAVORITES',
    SETTINGS: 'SETTINGS',
    VIDEOCHAT: 'VIDEOCHAT',
};

export const ThemeMode = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
};

export const ItemType = {
    POST: 'POST',
    BUTTON: 'BUTTON',
    PEOPLE: 'PEOPLE',
    STREAM: 'STREAM',
    SONG: 'SONG',
    FOLDER: 'FOLDER',
    FILE: 'FILE',
    EVENT: 'EVENT',
    OBJECT: 'OBJECT',
    PAYWALL: 'PAYWALL',
    PHOTO: 'PHOTO',
    ROOM: 'ROOM',
    NOTE: 'NOTE',
    VIDEO: 'VIDEO',
};

export const CalendarType = {
    COLLECTION: 'COLLECTION',
    ALL: 'ALL',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
    DAY: 'DAY',
    SCHEDULE: 'SCHEDULE',
    NOTELINE: 'NOTELINE',
};

export const ChannelType = {
    TEXT: 'TEXT',
    FORUM: 'FORUM',
    VOICE: 'VOICE',
    VIDEO: 'VIDEO',
};

export const MemberThumbDirection = {
    LTR: 'LTR',
    RTL: 'RTL',
};

export const LoginMode = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP'
};

export const MenuType = {
    LEFT: 'LEFT',
    TOP: 'TOP',
};

export const PostMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const MenuSection = {
    CALENDARS: 'CALENDARS',
    CHANNELS: 'CHANNELS',
    COLLECTIONS: 'COLLECTIONS',
    FOLDERS: 'FOLDERS',
    NEWSFEEDS: 'NEWSFEEDS',
    SETTINGS: 'SETTINGS',
    SPACES: 'SPACES',
};

export const ObjectsView = {
    TILES: 'TILES',
    GRID: 'GRID',
    TABLE: 'TABLE',
};

export const Permission = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    MANAGE_CHANNELS: 'MANAGE_CHANNELS',
    MANAGE_CALENDARS: 'MANAGE_CALENDARS',
    MANAGE_MODELS: 'MANAGE_MODELS',
    MANAGE_FOLDERS: 'MANAGE_FOLDERS',
    MANAGE_NEWSFEEDS: 'MANAGE_NEWSFEEDS',
};