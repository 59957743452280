import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Utilities
import { formatDuration } from '../../../../common/utilities/Formatters';

// Styles
import './SongEdit.css';

// Theme
import { useTheme } from '../../../../ThemeContext.js';

import PlayIcon from '../../../../common/svg/PlayIcon';
import PauseIcon from '../../../../common/svg/PauseIcon';

// Components
import Modal from '../../../../common/components/modal/Modal';

// Managers
import DataManager from '../../../../common/managers/DataManager';
const dataManager = new DataManager();

const SongEdit = ({ isOpen, setOpen, onSave }) => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
  } = useContext(Global);

  const [songs, setSongs] = useState([]);
  const [playingSongId, setPlayingSongId] = useState(null);
  const audioRef = useRef(new Audio());

  /**
   * Effect to load all songs.
   */
  useEffect(() => {
    if (!isOpen) {
      // Pause and reset audio when modal closes
      audioRef.current.pause();
      setPlayingSongId(null);
      return;
    }

    const loadSongs = async () => {
      const records = await dataManager.list(collections.songs, profile.key);
      setSongs(records);
    }

    loadSongs();
  }, [isOpen]);

  /**
   * Cleanup effect for audio
   */
  useEffect(() => {
    return () => {
      audioRef.current.pause();
    };
  }, []);

  const handleSelect = (song) => {
    audioRef.current.pause();
    setPlayingSongId(null);
    onSave(song);
    setOpen(false);
  };

  const togglePlay = (song, e) => {
    e.stopPropagation(); // Prevent triggering handleSelect

    if (playingSongId === song.id) {
      // Pause current song
      audioRef.current.pause();
      setPlayingSongId(null);
    } else {
      // Play new song
      audioRef.current.pause();
      audioRef.current = new Audio(song.url);
      audioRef.current.play()
        .then(() => {
          setPlayingSongId(song.id);
          // Stop when song ends
          audioRef.current.onended = () => {
            setPlayingSongId(null);
          };
        })
        .catch(error => console.error('Error playing audio:', error));
    }
  };

  return (
    <>
      {/* MODAL */}
      <Modal 
        title="Add Song to Post"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        height={600}
        width={400}
      >
        <div className="song-edit-list">
          {songs.map(song => (
            
            <div 
              key={song.id}
              className="song-edit-item-container"
              style={{
                backgroundColor: theme.backgroundColorFaded,
              }}
              onClick={() => handleSelect(song)}>

              {/* COVER */}
              <img 
                src={song.coverUrl}
                alt={song.title}
                className="song-edit-item-cover"
              />

              {/* DETAILS */}
              <div className="song-edit-item-details">
                <div 
                  className="song-edit-item-title"
                  style={{
                    color: theme.foregroundColor,
                  }}
                >
                  {song.title}
                </div>
                <div 
                  className="song-edit-item-artist"
                  style={{
                    color: theme.foregroundColorFaded,
                  }}
                >
                  {song.artist}
                </div>
                <div 
                  className="song-edit-item-duration"
                  style={{
                    color: theme.foregroundColorFaded,
                  }}
                >
                  {formatDuration(song.duration)}
                </div>
              </div>

              {/* PLAY/PAUSE CONTROL */}
              <div 
                className="song-edit-play-control"
                onClick={(e) => togglePlay(song, e)}
              >
                {playingSongId === song.id ? (
                  <PauseIcon 
                    width={40}
                    height={40}
                    color={theme.foregroundColor}
                  />
                ) : (
                  <PlayIcon 
                    width={40}
                    height={40}
                    color={theme.foregroundColor}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default SongEdit;