import React, { useState, useRef, useEffect } from 'react';

// Style
import './Slider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const Slider = ({
    value,
    lowerBound = 0,
    upperBound = 1,
    trackHeight = "12px",
    thumbSize = "30px",
    onChange,
    onBlur
}) => {
    const { theme } = useTheme();
    const [isDragging, setIsDragging] = useState(false);
    const [tempValue, setTempValue] = useState(value ?? lowerBound);
    const sliderRef = useRef(null);

    useEffect(() => {
        setTempValue(value ?? lowerBound);
    }, [value, lowerBound]);

    useEffect(() => {
        if (tempValue > upperBound) setTempValue(upperBound);
        if (tempValue < lowerBound) setTempValue(lowerBound);
    }, [tempValue, upperBound, lowerBound]);

    useEffect(() => {
        const updateValue = (clientX) => {
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = clientX - rect.left;
            const percentage = Math.min(1, Math.max(0, offsetX / rect.width));
            const newValue = lowerBound + percentage * (upperBound - lowerBound);
            onChange(newValue);
            setTempValue(newValue);
        };

        const handleMouseMove = (event) => {
            if (isDragging) updateValue(event.clientX);
        };

        const handleTouchMove = (event) => {
            if (isDragging) updateValue(event.touches[0].clientX);
        };

        const handleMouseUp = (event) => {
            setIsDragging(false);
            updateValue(event.clientX);
            if (onBlur) onBlur(tempValue);
        };

        const handleTouchEnd = () => {
            setIsDragging(false);
            if (onBlur) onBlur(tempValue);
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchmove', handleTouchMove);
            window.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging, onBlur, onChange, tempValue, lowerBound, upperBound]);

    const handleThumbMouseDown = () => setIsDragging(true);
    const handleThumbTouchStart = () => setIsDragging(true);

    const handleTrackClick = (event) => updateValue(event.clientX);
    const handleTrackTouch = (event) => updateValue(event.touches[0].clientX);

    const updateValue = (clientX) => {
        const rect = sliderRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const percentage = Math.min(1, Math.max(0, offsetX / rect.width));
        const newValue = lowerBound + percentage * (upperBound - lowerBound);
        onChange(newValue);
        setTempValue(newValue);
    };

    const percentageValue = ((tempValue - lowerBound) / (upperBound - lowerBound)) * 100;

    return (
        <div className="slider-container" style={{ color: theme.foregroundColor }}>
            <div
                className="slider-track"
                ref={sliderRef}
                style={{
                    background: theme.backgroundColorFaded,
                    height: trackHeight,
                }}
                onClick={handleTrackClick}
                onTouchStart={handleTrackTouch}>
                <div
                    className="slider-thumb"
                    style={{
                        left: `${percentageValue}%`,
                        backgroundColor: theme.highlightBackgroundColor,
                        height: thumbSize,
                        width: thumbSize,
                    }}
                    onMouseDown={handleThumbMouseDown}
                    onTouchStart={handleThumbTouchStart}
                />
            </div>
        </div>
    );
};

export default Slider;
