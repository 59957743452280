import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Utilities
import { createCustomZoomControl, reverseGeocode } from './PinsMapUtils';
import { SectionType } from '../../../../common/utilities/Enums';
import { generateKey } from '../../../../common/utilities/Keys';

// Styles
import './PinsMap.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Hooks
import useMapStyle from './hooks/MapStyle';
import useFocusSelectedPin from './hooks/FocusSelectedPin';
import useLoadGoogleMaps from './hooks/LoadGoogleMaps';
import usePanToSearchResult from './hooks/PanToSearchResult';

// Managers
import DataManager from '../../../../common/managers/DataManager';
import useSyncMarkers from './hooks/SyncMarkers';

const dataManager = new DataManager();

const PinsMap = () => {
  const { theme } = useTheme();
  const {
    currentSection,
    profile,
    profilePins,
    setSelectedPin,
  } = useContext(Global);

  const mapStyle = useMapStyle(theme);

  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const pinMarkersRef = useRef([]);

  // Set selectedPin to home pin on initial load
  useEffect(() => {
    if (currentSection !== SectionType.PINS) return;
    if (!profilePins.length) return;

    const homePin = profilePins.find(pin => pin.isHome === true);
    if (homePin) {
      setSelectedPin(homePin);
    }
  }, [currentSection, profilePins, setSelectedPin]);

  // Update map styles when theme changes
  useEffect(() => {
    if (googleMapRef.current) {
      googleMapRef.current.setOptions({ styles: mapStyle });
    }
  }, [mapStyle]);

  const initializeMap = async () => {
    googleMapRef.current = new window.google.maps.Map(mapRef.current, {
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 9,
      styles: mapStyle,
      disableDefaultUI: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      rotateControl: false,
      scaleControl: false,
    });

    createCustomZoomControl(googleMapRef.current, theme);

    googleMapRef.current.addListener('click', async (event) => {
      const latLng = event.latLng;
      const key = generateKey();

      try {
        const locationData = await reverseGeocode(latLng);
        const data = {
          key: key,
          lat: latLng.lat(),
          lng: latLng.lng(),
          profileKey: profile.key,
          radius: 30,
          title: locationData.city || 'New Pin',
          state: locationData.state || '',
          country: locationData.country || '',
        };

        await dataManager.add(collections.pins, profile.key, key, data);
        setSelectedPin(data);
      } catch (error) {
        console.error("Error in click handler:", error);
      }
    });
  };

  // Load Google Maps API
  useLoadGoogleMaps({ initializeMap });

  // Pan to search result when mapSearchResult changes
  usePanToSearchResult({ googleMapRef });

  // Sync pinMarkersRef with profilePins
  useSyncMarkers({ profilePins, pinMarkersRef, googleMapRef });

  // Center and fit bounds when selectedPin changes
  useFocusSelectedPin({ googleMapRef, pinMarkersRef });

  return (
    <div>
      <div ref={mapRef} className="pins-map-container" />
    </div>
  );
};

export default PinsMap;