import React from 'react';

// Utilities
import { formatCount } from '../../../../common/utilities/Formatters';

// Styles
import './MenuBarButton.css';

// Themes
import { useTheme } from '../../../../ThemeContext';

/**
 * MenuBarButton Component
 * 
 * This component renders a button to the app bar.
 * 
 * @param {component} icon The icon component to render.
 * @param {string} title The title of the button.
 * @param {boolean} selected The selected state of the button.
 * @param {component} selectedIcon The icon component to render when selected.
 * @param {function} onClick The function to call when the button is clicked.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MenuBarButton = ({ icon: IconComponent, title, selected = false, selectedIcon: SelectedIconComponent, onClick, count = 0 }) => {
    
    // Theme
    const { theme } = useTheme();

    // Determine which icon to use based on the selected state
    const IconToUse = selected && SelectedIconComponent ? SelectedIconComponent : IconComponent;

    // Determine the icon color based on the selected state
    const iconColor = selected ? theme.highlightBackgroundColor : theme.foregroundColor;

    return (
        <>
            {/* CONTAINER */}
            <div className="menu-bar-button-container"
                style={{
                    borderLeftColor: selected ? theme.highlightBackgroundColor : 'transparent',
                }}
                title={title}>

                {/* CIRCLE */}
                <div className="menu-bar-button-circle"
                    style={{
                        //backgroundColor: theme.backgroundColorFaded,
                    }}
                    onClick={onClick}>

                    {/* ICON */}
                    <IconToUse
                        color={iconColor}
                        width="30"
                        height="30"
                    />

                </div>

                {/* COUNT */}
                {count > 0 &&
                    <div className="menu-bar-button-count"
                        style={{
                            backgroundColor: theme.highlightBackgroundColor,
                            borderColor: theme.backgroundColor,
                            color: theme.highlightForegroundColor
                        }}>
                        {formatCount(count)}
                    </div>
                }
            </div>
        </>
    );
};

export default MenuBarButton;