import React from 'react';

// Styles
import './Inbox.css';

// Components
import NotificationList from './list/NotificationList';
import InboxToolbar from './toolbar/InboxToolbar';
import Section from '../../components/section/Section';

const Inbox = () => {

  return (

    <Section>

      {/* TOOLBAR */}
      <InboxToolbar />

      {/* LIST */}
      <NotificationList />

    </Section>

  );
};

export default Inbox;