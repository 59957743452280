import React from 'react';

// Styles
import './ModalCheckbox.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOnIcon from '../../../svg/CheckOnIcon';
import CheckOffIcon from '../../../svg/CheckOffIcon';

/**
 * ModalCheckbox Component
 * 
 * This component renders a checkbox input field for a modal dialog.
 * 
 * @param {string} label The checkbox label.
 * @param {boolean} value The checkbox value.
 * @param {function} onChange The checkbox change handler.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalCheckbox = ({ label, value, onChange }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="modal-checkbox-container">
            <div
                className="modal-checkbox-input"
                onClick={onChange}>
                {value ? (
                    <CheckOnIcon
                        color={theme.highlightBackgroundColor}
                        width="20"
                        height="20"
                        checkColor={theme.highlightForegroundColor}
                    />
                ) : (
                    <CheckOffIcon
                        color={theme.foregroundColorFaded}
                        width="20"
                        height="20"
                    />
                )}
            </div>
            <div
                className="modal-checkbox-label"
                onClick={onChange}
                style={{ 
                  color: theme.foregroundColor 
                }}>
                {label}
            </div>
        </div>
  );
};

export default ModalCheckbox;

