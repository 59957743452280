import React from 'react';

// Utilities
import { ItemSize } from '../../../../utilities/Enums';

// Styles
import './NoteGridItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import GridItem from '../../../../components/grid/griditem/GridItem';
import GridItemTitle from '../../../../components/grid/griditem/title/GridItemTitle';

/**
 * NoteGridItem
 * 
 * A grid item for displaying notes.
 * 
 * @param {Object} item The note item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} NoteGridItem component.
 */
const NoteGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart, size = ItemSize.SMALL }) => {
    
    // Theme
    const { theme } = useTheme();

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => {
                if (onClick) onClick(event, item); 
            }}
            onDoubleClick={() => {
                if (onDoubleClick) onDoubleClick(item);
            }}
            onDragStart={(event) => {
                if (onDragStart)  onDragStart(event, item);
            }}
            size={size}>

            <div
                className="note-grid-item-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.backgroundColorFaded,
                }}
                title={item.title}>

                {/* TITLE */}
                <GridItemTitle
                    title={item.title}
                    selected={selected}
                />

            </div>

        </GridItem>
    );
};

export default NoteGridItem;