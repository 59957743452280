import React from 'react';

const AddNoteIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M320-184.62v-391q0-26.84 19-45.61T384.85-640h390.53q26.85 0 45.74 18.88Q840-602.23 840-575.38v278.46L663.08-120H384.62q-26.85 0-45.74-18.88Q320-157.77 320-184.62ZM121-696.15q-5.23-26.85 10.31-48.35 15.54-21.5 42.38-26.73L558.46-839q26.85-5.23 48.35 10.31 21.5 15.54 26.73 42.38l8.46 50.93h-40.46l-8.54-47.7q-1.54-8.46-9.23-13.46T566.85-800l-386.08 68.54q-10.77 1.54-16.15 10-5.39 8.46-3.85 19.23l63.85 360.61v77.47q-13.7-6.7-23.27-19-9.58-12.31-12.58-28.23L121-696.15Zm239 120.77v390.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92H640l160-160v-255.38q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H384.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7ZM580-380Zm-20 140h40v-120h120v-40H600v-120h-40v120H440v40h120v120Z" />
  </svg>

);

export default AddNoteIcon;
