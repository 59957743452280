import React from 'react';

const PinIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M480-281q98.23-86.15 149.12-167.08Q680-529 680-599.38q0-88.47-56-144.54Q568-800 480-800t-144 56.08q-56 56.07-56 144.54 0 70.38 50.88 151.3Q381.77-367.15 480-281Zm0 51q-118.69-97.08-179.35-190.85Q240-514.62 240-599.38q0-105.77 67.23-173.2Q374.46-840 480-840t172.77 67.42Q720-705.15 720-599.38q0 84.76-60.65 178.15Q598.69-327.85 480-230Zm0-305.38q26.85 0 45.73-18.89 18.89-18.88 18.89-45.73 0-26.85-18.89-45.73-18.88-18.89-45.73-18.89-26.85 0-45.73 18.89-18.89 18.88-18.89 45.73 0 26.85 18.89 45.73 18.88 18.89 45.73 18.89ZM240-120v-40h480v40H240Zm240-480Z" />
  </svg>

);

export default PinIcon;
