/**
 * Method to validate email format.
 * 
 * @param {string} email - Email to validate.
 * 
 * @returns {boolean} - Whether the email is valid.
 */
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation
  return emailRegex.test(email);
};