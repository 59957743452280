import React from 'react';

// Styles
import './EventThumbItem.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../svg/CalendarIcon';

// Components
import ThumbDelete from '../../../common/thumbdelete/ThumbDelete';

/**
 * EventThumbItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Function} onClick The click event handler.
 * 
 * @returns {JSX.Element} EventThumbItem component.
 */
const EventThumbItem = ({ item, onClick, onDelete }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="event-thumb-item-container"
            onClick={(event) => {
                if (onClick) onClick(event, item);
            }}
            title={item.title}>

            {/* DELETE BUTTON */}
            <ThumbDelete 
                item={item} 
                onDelete={onDelete} 
            />

            {/* ICON */}
            <div className="event-thumb-item-icon-container"
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                }}>
                <CalendarIcon
                    color={theme.highlightBackgroundColor}
                    width="50"
                    height="50"
                />
            </div>

        </div>

    );
};

export default EventThumbItem;
