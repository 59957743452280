import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './PinsSearch.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import SearchIcon from '../../../svg/SearchIcon';

const PinsSearch = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const { 
    mapSearchTerm, 
    profile, 
    setMapSearchResult,
    setMapSearchTerm 
} = useContext(Global);

  const handleSearch = () => {
    if (!mapSearchTerm || !window.google) {
      console.log('No search term or Google Maps API not loaded');
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: mapSearchTerm }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const firstResult = results[0];
        console.log('First geocoding result:', firstResult);
        setMapSearchResult(firstResult);
      } else {
        console.error('Geocoding failed:', status);
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div
      className="pins-search-container"
      style={{
        backgroundColor: theme.backgroundColorFaded,
      }}
    >
      <SearchIcon color={theme.foregroundColor} width="20" height="20" />

      <input
        className="pins-search-input"
        type="text"
        placeholder="Search for a location..."
        value={mapSearchTerm}
        onChange={(e) => setMapSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        style={{
          backgroundColor: theme.backgroundColorFaded,
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily || 'Urbanist',
        }}
      />
    </div>
  );
};

export default PinsSearch;