import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Utilities
import { hexToRgba } from '../../../../../utilities/Colors';
import { ThemeMode } from '../../../../../utilities/Enums';

// Styles
import './PostFeedItemFooter.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import SongIcon from '../../../../../svg/SongIcon';

// Components
import PinFilledIcon from '../../../../../svg/PinFilledIcon';
import ContributeButton from '../contributebutton/ContributeButton';
import EventDisplay from './eventdisplay/EventDisplay';
import CommentScroller from '../floatingcomments/CommentScroller';

const PostFeedItemFooter = ({
    item,
    songUrl,
    songTitle,
    songArtist,
    onProfileClick,
    onContributeClick
}) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Global
    const {
        comments,
    } = useContext(Global);

    const getContributorsText = (item) => {

        console.log("item in getContributorsText:", item);

        if (!item.items || item.items.length === 0) return null;

        console.log("filtering")

        // Filter out contributions that aren't from additional contributors
        const additionalContributors = item.items.filter(contribution =>
            contribution.contributorKey &&
            contribution.contributorProfile &&
            contribution.contributorKey !== item.profileKey
        );

        const contributorCount = additionalContributors.length;

        if (contributorCount === 0) return null;

        if (contributorCount === 1) {
            return `and ${additionalContributors[0].contributorProfile.username}`;
        }

        return `and ${contributorCount} others`;
    };

    return (
        <div className="post-feed-item-footer-container">

            {/* COMMENTS */}
            {comments.length > 0 &&
                <div className="post-feed-item-footer-floating-comments">
                    <CommentScroller
                        item={item}
                        comments={comments}
                    />
                </div>
            }

            {/* INFO */}
            <div className="post-feed-item-footer-info"
                style={{
                    backgroundColor: hexToRgba(currentThemeMode === ThemeMode.DARK ? "#000000" : "#FFFFFF", 0.4),
                }}>

                {/* TOP ROW */}
                <div className="post-feed-item-footer-top-row">

                    {/* LEFT */}
                    <div className="post-feed-item-footer-top-left">

                        {/* USERNAME */}
                        {item.profile &&
                            <div className="post-feed-item-footer-username"
                                onClick={onProfileClick}
                                style={{
                                    color: theme.foregroundColor,
                                }}>
                                {item.profile.username}
                                
                            </div>
                        }

                        {getContributorsText(item) && (
                            <div
                                className="post-feed-item-footer-contributors"
                                style={{
                                    color: theme.foregroundColorFaded,
                                }}
                            >
                                {getContributorsText(item)}
                            </div>
                        )}

                        {/* LOCATION */}
                        {item.pin && (
                            <div className="post-feed-item-footer-location"
                                style={{
                                    color: theme.highlightBackgroundColor
                                }}>
                                <PinFilledIcon
                                    color={theme.highlightBackgroundColor}
                                    width="13"
                                    height="13"
                                />
                                {item.pin.title}, {item.pin.state} {item.pin.country}
                            </div>
                        )}
                    </div>

                    {/* RIGHT */}
                    <div className="post-feed-item-footer-top-right">

                        {/* CONTRIBUTE BUTTON */}
                        <ContributeButton
                            onClick={onContributeClick}
                        />
                    </div>

                </div>

                {item.title && item.title.length > 0 && (
                    <div className="post-feed-item-footer-title"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {item.title}
                    </div>
                )}

                {/* EVENT DISPLAY */}
                <EventDisplay
                    item={item}
                />

                {/* SONG MARQUEE */}
                {songUrl && (
                    <div className="post-feed-item-song-marquee">
                        <div className="post-feed-item-marquee-content"
                            style={{
                                color: theme.foregroundColor,
                            }}>
                            <SongIcon
                                color={theme.highlightBackgroundColor}
                                width="14"
                                height="14"
                            />
                            &nbsp;
                            {songTitle} / {songArtist}
                        </div>
                    </div>
                )}

            </div>

        </div>
    );
};

export default PostFeedItemFooter;
