// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections, } from '../../../../firebaseConfig';

// Utilities
import { ItemType } from '../../../utilities/Enums';
import { generateKey } from '../../../utilities/Keys';

// Manager
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

export const addStream = async (profile, item) => {
  const profileKey = profile.key;
  const key = generateKey();
  const now = Timestamp.now();

  const streamData = {
    key: key,
    profileKey: profileKey,
    title: `${profile.username}'s Stream`,
    eventKey: item.key,
    profile: profile,
    type: ItemType.STREAM,
    dateCreated: now,
    dateModified: now,
  };

  await dataManager.add(
    collections.items,
    profileKey,
    key,
    streamData
  );

};

export const deleteStream = async (profile) => {
  if (!profile) return;
  
  // Delete any streams for the current user
  const params = [
    { field: 'type', operator: '==', value: ItemType.STREAM },
    { field: 'profileKey', operator: '==', value: profile.key },
  ];
  await dataManager.delete(collections.items, profile.key, null, params);
};