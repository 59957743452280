import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Firebase
import { collections } from '../../../../../firebaseConfig';

// Utilities
import { EngagementType } from '../../../../../common/utilities/Enums';
import { formatCount } from '../../../../../common/utilities/Formatters';
import { generateKey } from '../../../../../common/utilities/Keys';
import { getDisplayProfile } from '../../../../../common/utilities/Profiles.js';

// Styles
import './ItemButtons.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import BookmarkFilledIcon from '../../../../../common/svg/BookmarkFilledIcon';
import CommentFilledIcon from '../../../../../common/svg/CommentFilledIcon';
import DotsIcon from '../../../../../common/svg/DotsIcon';
import LikeFilledIcon from '../../../../../common/svg/LikeFilledIcon';
import MuteOffIcon from '../../../../../common/svg/MuteOffIcon';
import MuteOnIcon from '../../../../../common/svg/MuteOnIcon';
import ShareFilledIcon from '../../../../../common/svg/ShareFilledIcon';

// Components
import ItemButton from '../button/ItemButton.js';
import ProfileDot from '../../../../../common/components/profiledot/ProfileDot';
import ProfileModal from '../../../../components/profilemodal/ProfileModal.js';

// Managers
import DataManager from '../../../../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * ItemButtons
 * 
 * @returns {JSX.Element} ItemFeedButtons component.
 */
const ItemButtons = ({ item, onCommentButtonClick, onMenuButtonClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        muted,
        profile,
        profileEngagements,
        setMuted,
    } = useContext(Global);

    // Local State
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const [liked, setLiked] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);

    useEffect(() => {
        // Liked
        const isLiked = profileEngagements.some(engagement =>
            engagement.itemKey === item.key &&
            engagement.type === EngagementType.POST_LIKE);
        setLiked(isLiked);

        const isBookmarked = profileEngagements.some(engagement =>
            engagement.itemKey === item.key &&
            engagement.type === EngagementType.POST_BOOKMARK);
        setBookmarked(isBookmarked);
    }, [profileEngagements, item.key]);

    const handleProfileClick = () => {
        setProfileModalOpen(true);
    };

    const handleLikeClick = async () => {
        if (!liked) {
            addEngagement(EngagementType.POST_LIKE);
        } else {
            deleteEngagement(EngagementType.POST_LIKE);
        }
    };

    const handleBookmarkClick = async () => {
        if (!bookmarked) {
            addEngagement(EngagementType.POST_BOOKMARK);
        } else {
            deleteEngagement(EngagementType.POST_BOOKMARK);
        }
    };

    const addEngagement = async (type) => {
        const key = generateKey();

        const data = {
            key: key,
            type: type,
            itemKey: item.key,
            itemSummary: item,
            profileKey: profile.key,
            profile: getDisplayProfile(profile),
            targetKey: item.profileKey,
        };

        console.log('Adding engagement', data);

        await dataManager.add(
            collections.engagements,
            profile.key,
            key,
            data
        );
    };

    const deleteEngagement = async (type) => {
        const params = [
            { field: 'type', operator: '==', value: type },
            { field: 'profileKey', operator: '==', value: profile.key },
            { field: 'itemKey', operator: '==', value: item.key },
        ];

        await dataManager.delete(
            collections.engagements,
            profile.key,
            null,
            params
        );
    };

    const handleMuteClick = () => {
        setMuted(!muted);
    };

    const handleCommentClick = () => {
        onCommentButtonClick(item);
    };

    return (
        <>
            <div className="item-buttons-container">

                <ProfileDot
                    item={item.profile}
                    size={40}
                    onClick={handleProfileClick}
                />

                <ItemButton
                    icon={LikeFilledIcon}
                    color={liked ? theme.highlightBackgroundColor : theme.foregroundColor}
                    size={24}
                    onClick={handleLikeClick}
                    label={formatCount(item.likeCount)}
                />

                <ItemButton
                    icon={CommentFilledIcon}
                    size={21}
                    onClick={handleCommentClick}
                    label={formatCount(item.commentCount)}
                    enabled={item.allowComments}
                />

                <ItemButton
                    icon={BookmarkFilledIcon}
                    color={bookmarked ? theme.highlightBackgroundColor : theme.foregroundColor}
                    size={24}
                    onClick={handleBookmarkClick}
                    label={formatCount(item.bookmarkCount)}
                />

                <ItemButton
                    icon={ShareFilledIcon}
                    size={21}
                    onClick={() => console.log('Share clicked')}
                    label={formatCount(item.shareCount)}
                />

                {item && (item.profileKey === profile.key) && (
                    <ItemButton
                        icon={DotsIcon}
                        size={16}
                        onClick={onMenuButtonClick}
                        enabled={true}
                    />
                )}

                <ItemButton
                    icon={muted ? MuteOnIcon : MuteOffIcon}
                    size={24}
                    onClick={handleMuteClick}
                />

            </div>

            {/* PROFILE */}
            <ProfileModal
                profile={item.profile}
                isOpen={profileModalOpen}
                setOpen={setProfileModalOpen}

            />
        </>
    );
};

export default ItemButtons;
