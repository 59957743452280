import React from 'react';

// Styles
import './EventFormFields.css';

// Components
import Divider from '../../../components/divider/Divider';
import EventDates from './dates/EventDates';
import EventLocation from './location/EventLocation';
import EventTitle from './title/EventTitle';

const EventFormFields = ({
  title,
  setTitle,
  defaultStart,
  defaultEnd,
  location,
  setLocation,
  onStartDateChange,
  onStartTimeChange,
  onEndDateChange,
  onEndTimeChange,
}) => {

  return (
    <div className="event-form-fields-container">

      {/* EVENT TITLE */}
      <EventTitle
        title={title}
        setTitle={setTitle}
      />

      {/* DIVIDER */}
      <Divider
        margin="14px"
      />

      {/* DATES AND TIMES */}
      <EventDates
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
        onStartDateChange={onStartDateChange}
        onStartTimeChange={onStartTimeChange}
        onEndDateChange={onEndDateChange}
        onEndTimeChange={onEndTimeChange}
      />

      {/* DIVIDER */}
      <Divider
        margin="14px"
      />

      {/* EVENT LOCATION */}
      <EventLocation
        location={location}
        setLocation={setLocation}
      />

      {/* DIVIDER */}
      <Divider
          margin="14px"
      />

    </div>
  );
};

export default EventFormFields;
