import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { SectionType, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import MenuBar from '../common/components/menubar/MenuBar.js';
import Calendar from '../common/sections/calendar/Calendar';
import Friends from '../common/sections/friends/Friends';
import Home from '../common/sections/home/Home';
import MusicManager from '../common/sections/musicmanager/MusicManager';
import Pins from '../common/sections/pins/Pins';
import Inbox from '../common/sections/inbox/Inbox';
import Public from '../public/Public';
import Settings from '../common/sections/settings/Settings';

// Dynamic Styles
import { DesktopStyles } from './DesktopStyles';

// Images
const gradient = 'https://appdeck.ai/wallpapers/gradient.jpg';

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    profile,
    currentSection,
  } = useContext(Global);

  const renderContent = () => {
    if (!profile) return <Public />;
    if (currentSection === SectionType.HOME) return <Home />;
    if (currentSection === SectionType.FRIENDS) return <Friends />;
    if (currentSection === SectionType.PINS) return <Pins />;
    if (currentSection === SectionType.CALENDAR) return <Calendar />;
    if (currentSection === SectionType.MUSIC) return <MusicManager />;
    if (currentSection === SectionType.INBOX) return <Inbox />;
    if (currentSection === SectionType.SETTINGS) return <Settings />;
    
    return null;
  };

  useEffect(() => {
    if (!profile) return;
    loadFont(profile.fontFamily || defaultFont);
  }, [profile]);

  // Verify profile
  //if (!profile) return null;

  return (
    <>
      <DesktopStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: profile
            ? currentThemeMode === ThemeMode.DARK
              ? `url(${profile.wallpaperDark || 'none'})`
              : `url(${profile.wallpaperLight || 'none'})`
            : `url(${gradient})`, // Fallback if profile is null
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>

        <div className="desktop-main">

          {/* PROFILE BAR */}
          {profile &&
            <div className="desktop-left">
              <MenuBar />
            </div>
          }

          <div className="desktop-right"
            style={{
              width: profile ? 'calc(100% - 63px)' : '100%',
            }}>
            <div className="desktop-middle">
              {/* CONTENT */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
