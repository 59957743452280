import React from 'react';

const ExplorePinIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840h12.31q6.15 0 12.31.46v40.23q-6.16-.46-12-.96-5.85-.5-12.62-.5-104.85 0-174.35 71.42-69.5 71.43-69.5 181.2 0 74.07 65.54 175.57T480-172.92q111.23-96.62 176.38-196.2 65.16-99.57 67.47-173.65v-1.85h40v1.85q-2.31 87.69-73.35 194.04-71.04 106.35-210.5 229.5Zm.14-371.54q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88Zm-.14-64.61ZM720-640h40v-120h120v-40H760v-120h-40v120H600v40h120v120Z" />
  </svg>

);

export default ExplorePinIcon;
