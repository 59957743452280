import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Utilities
import { clearPinMarkers, placeMarkerAndCircle } from '../PinsMapUtils';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * Custom hook to pan to a search result
 */
const useSyncMarkers = ({ profilePins, pinMarkersRef, googleMapRef }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedPin,
    setSelectedPin,
  } = useContext(Global);

  useEffect(() => {
    if (!googleMapRef.current || !window.google) {
      return;
    }

    clearPinMarkers(pinMarkersRef);

    profilePins.forEach((pin) => {
      if (
        !pin ||
        typeof pin.lat !== 'number' ||
        isNaN(pin.lat) ||
        typeof pin.lng !== 'number' ||
        isNaN(pin.lng)
      ) {
        return;
      }

      const latLng = new window.google.maps.LatLng(pin.lat, pin.lng);
      const pinToUse = pin.key === selectedPin?.key ? { ...pin, radius: selectedPin.radius } : pin;
      const { marker, circle } = placeMarkerAndCircle(
        pinToUse,
        latLng,
        googleMapRef,
        theme,
        setSelectedPin
      );
      pinMarkersRef.current.push({ marker, circle, key: pin.key });
    });
  }, [profilePins, selectedPin?.radius, googleMapRef, pinMarkersRef, selectedPin?.key, setSelectedPin, theme]);

};

export default useSyncMarkers;
